import { EnumToolStripCompType } from '@kmsoft/upf-core'
import * as ToolStrip from '../../../../toolstrip'
import { ObjectToolStripItem } from '../../../../object-tool-strip'

/**
 * 获取工具栏定义
 * @param suppressOperation
 * @returns
 */
export const getToolStrip = (suppressOperation: boolean) => {
  const toolStripItems: Array<ObjectToolStripItem> = [
    {
      ...ToolStrip.toolStripItemStartEdit
    },
    // ToolStrip.toolStripItemSave,
    {
      name: ToolStrip.EnumToolStripItemKeys.TOOL_STRIP_ITEM_RESTORE,
      title: '取消编辑',
      icon: 'minus-circle',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    ToolStrip.toolStripItemRefresh
    // ...ToolStrip.ToolStripHelper.getDocGeneralObjectOperationToolStripItems()
  ]

  return toolStripItems
}
