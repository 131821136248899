import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { IFolder } from '../../folder-manage/folder-tree/interface'
import { KPublicWorkspaceEmitsType, KPublicWorkspacePropType, PublicWorkspaceRootTreeNode } from './interface'
import { KFolderManageViewModel } from '../../../pages/folder-manage'
import { ref } from 'vue'

/** KPublicWorkspace */
export default class KPublicWorkspaceViewModel extends BaseViewModel<KPublicWorkspaceEmitsType, KPublicWorkspacePropType> {
  refFolderManage = ref<KFolderManageViewModel>()
  constructor(options: ViewModelOptions<KPublicWorkspacePropType>) {
    super(options)
  }

  viewDidMount() {}
  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: []
    }
    const res = await Api.post('folder', 'Folder', 'getPublicWorkspace', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new PublicWorkspaceRootTreeNode(node)]
    } else {
      KNotification.error({
        title: '获取公共工作区数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }

  refresh() {
    this.refFolderManage.value?.refresh()
  }
}
