import {
  Api,
  EnumLifecycleState,
  EnumRequestCode,
  EnumWorkingState,
  PartViewMemoryCache,
  loadingHandle
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumTreeViewNodeRefreshType,
  IKTreeNode,
  KDataGridViewModel,
  KDialog,
  KNotification,
  KTreeNode,
  KTreeViewViewModel,
  MemoryCacheFactory,
  ViewModelOptions,
  utils
} from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KIssueReportPartStru } from '../../../../../../../ebf-change-manage/src/controls/issue-report-affected-views/issue-report-part-stru'
import { EnumWorkState } from '../../../../../../../ebf-part/src/controls/part-edit-panel/interface'
import { KCollectRelatedPartEmitsType, KCollectRelatedPartPropType } from './interface'

/** KCollectRelatedPart */
export default class KCollectRelatedPartViewModel extends BaseViewModel<
  KCollectRelatedPartEmitsType,
  KCollectRelatedPartPropType
> {
  /** 树组件 */
  refPartTree = ref<KTreeViewViewModel>()
  /** 网格组件 */
  refObjGrid = ref<KDataGridViewModel>()
  /** 网格数据 */
  gridData = ref<any[]>([])
  /** 置灰的树节点 */
  disableNodes = ref<any[]>([])

  /**
   * 所有树节点集合缓存起来
   */
  allNodes = ref<Record<string, any>>()

  showTxt = ref<boolean>(true)

  constructor(options: ViewModelOptions<KCollectRelatedPartPropType>) {
    super(options)
  }

  viewDidMount() {
    this.loadTreeData()
  }

  /**
   * 加载零部件向父递归查询树
   */
  async loadTreeData() {
    let data = [] as any
    const result = (await this.expandForGrid()) as any
    // 刷新图标视图
    const iconView = result!.map(
      (resultData: {
        userObject: any
        hasChildren: any
        pid: any
        name: any
        number: any
        id: any
        displayPath: any
        currentLevel: any
        index: any
        versionInfo: any
      }) => {
        const part = resultData.userObject.bomLink.targetPart
        return {
          id: resultData.id,
          hasChildren: resultData.hasChildren,
          parentId: resultData.pid,
          name: resultData.name,
          number: part.number,
          partId: part.id,
          partRdmExtensionType: part.rdmExtensionType,
          displayPath: resultData.displayPath,
          currentLevel: resultData.currentLevel,
          sumQuantity: resultData.userObject.sumQuantity,
          userObject: resultData.userObject,
          index: utils.uuid(),
          versionInfo: `${part.version}.${part.iteration}`
        }
      }
    ) as any
    if (iconView && iconView.length > 0) {
      for (let index = 0; index < iconView.length; index++) {
        data.push(iconView[index])
      }
    }
    // 缓存所有节点对象, 后续需要通过节点ID收缩树节点对象
    this.allNodes.value = data
    this.fillIcon(data)
    data = this.toTree(data)
    this.refPartTree.value?.setValue(data)
    if (data != null && data.length > 0) {
      this.refPartTree.value?.setSelectedNode(data[0].id)
    }
  }

  fillIcon(arr: Array<IKTreeNode>) {
    arr.forEach((node: any) => {
      node.iconType = node.hasChildren ? 'setting' : 'layout-item'
    })
  }

  /**
   * 将父子结构一维数组转换成children[]形式
   * @param arr 源数据集合
   * @returns 目标数据集合(默认只有一个对象)
   */
  toTree(arr: Array<IKTreeNode>): Array<IKTreeNode> {
    const tree = [] as Array<IKTreeNode>
    const codeMap = new Map()
    const map = new Map()

    arr.forEach((node: any) => {
      codeMap.set(node.id, { ...node })
      map.set(node.id + '_' + node.parentId, { ...node, children: [] })
    })

    map.forEach((node, _, map) => {
      const parentNode = codeMap.get(node.parentId)
      const parentId = node.parentId + '_' + parentNode?.parentId
      const parent = map.get(parentId)

      if (parent) {
        parent.children.push(node)
        parent.leaf = false
      } else {
        tree.push(node)
      }
    })

    return tree
  }

  /**
   * 网格展开
   */
  async expandForGrid() {
    const partId = this.props.objParam.id
    const rdmExtensionType = this.props.objParam.rdmExtensionType
    // 获取对象
    const part = (await this.getPart(partId)) as any
    const param = {
      data: [
        {
          root: {
            id: partId,
            clazz: rdmExtensionType
          },
          expandType: 'FORWARD_EXPANSION',
          queryRule: { type: 'LATEST_WITHOUT_WORKING_COPY' },
          view: part.partView?.name,
          maxLevel: 99
        }
      ]
    }
    loadingHandle.show()
    const result = await Api.post('part', 'Part', 'expandForGrid', param)
    loadingHandle.remove()
    return new Promise((resolve, reject) => {
      if (result && result.code == EnumRequestCode.SUCCESS) {
        resolve(result.data)
      } else {
        KNotification.error({
          title: '获取失败',
          content: result.message,
          details: result.detail
        })
        reject(void 0)
      }
    })
  }

  /**
   * 获取零部件
   */
  async getPart(id: string) {
    const result = (await Api.post('part', 'Part', 'get', { data: [id] })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取零部件失败',
        details: result.detail
      })
      return
    }
  }
  /**
   * 加载网格
   */
  async loadGridData() {
    // 不再发起查询, 直接从左侧树上找, 找当前选择节点的所有子节点
    const xList = [] as any
    this.getRowDataFromNodeChildren(this.refPartTree.value?.getSelectedNode(), xList, 99, 0)

    this.gridData.value = xList
    await this.gridDataFilter(this.props.templateKey)
    this.refObjGrid.value?.clear()
    this.refObjGrid.value?.setDataSource(this.gridData.value)

    // 将树节点置灰
    setTimeout(() => {
      if (this.disableNodes.value.length > 0) {
        this.disableNodes.value.forEach((item: any) => {
          const node = this.allNodes.value?.find((node: any) => node.partId == item.id)
          if (node) {
            const nodeItem = this.refPartTree.value?.getNode(node.id) as KTreeNode
            nodeItem.disabled = true
            this.refPartTree.value?.refreshNode(nodeItem, EnumTreeViewNodeRefreshType.SELF)
          }
        })
      }
    }, 30)
  }

  /**
   * 设置默认选中行
   */
  setDefaultSelectRows() {
    if (this.props.defaultSelectRows.length > 0) {
      const selectRowIds = this.gridData.value
        .filter(row => this.props.defaultSelectRows!.includes(row.branch.id))
        .map(row => row.index)
      this.refObjGrid.value?.setSelectedRow(selectRowIds, true)
    }
  }

  getRowDataFromNode(xNode: any) {
    const part = xNode.userObject.bomLink.targetPart
    return {
      ...part,
      index: xNode.index,
      displayPath: xNode.displayPath,
      currentLevel: xNode.currentLevel,
      sumQuantity: xNode.sumQuantity,
      versionInfo: xNode.versionInfo,
      targetViewId: part.partView.id
    }
  }

  /**
   * 从树上获取数据
   */
  getRowDataFromNodeChildren(xNode: any, xList: any[], totalLevel: number, curLevel: number) {
    if (xNode === undefined || xNode === null) {
      return
    }
    if (curLevel > totalLevel + 1) {
      return
    }
    xList.push(this.getRowDataFromNode(xNode))
    const children = xNode.children || []
    if (children !== null && children.length > 0) {
      for (let i = 0; i < children.length; i++) {
        this.getRowDataFromNodeChildren(children[i], xList, totalLevel, curLevel + 1)
      }
    }
  }

  /**
   * 网格数据过滤
   */
  async gridDataFilter(templateKey: string) {
    //获取工作流配置项，根据当前流程模板过滤显示的零部件
    let data = []
    const result = (await Api.post('sys', 'ConfigSysQueryService', 'listGridConfigByCode', {
      data: ['SYS_WF_COLLECT_RELATED']
    })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data && result.data.length > 0) {
        const config = result.data.filter((config: any) => config.templateKey == templateKey)
        if (config && config.length > 0) {
          const allowLifecycleState = config[0].allowLifecycleState?.split(';')
          data = this.gridData.value.filter(row => {
            const rdmExtensionType = row.rdmExtensionType
            const lifecycleStateCode = row.lifecycleStateCode
            const workingState = row.workingState
            if (
              rdmExtensionType == 'Standardparts' ||
              rdmExtensionType == 'Electroniccomponents' ||
              rdmExtensionType == 'Material'
            ) {
              return false
            }
            if (!allowLifecycleState.includes(lifecycleStateCode)) {
              return false
            }
            if (workingState != EnumWorkState.CHECKED_IN) {
              return false
            }
            return true
          })
          this.showTxt.value = false
        } else {
          data = this.gridData.value.filter(row => {
            const rdmExtensionType = row.rdmExtensionType
            const lifecycleStateCode = row.lifecycleStateCode
            const workingState = row.workingState
            if (
              rdmExtensionType == 'Standardparts' ||
              rdmExtensionType == 'Electroniccomponents' ||
              rdmExtensionType == 'Material'
            ) {
              return false
            }
            if (lifecycleStateCode != 'InWork') {
              return false
            }
            if (workingState != EnumWorkState.CHECKED_IN) {
              return false
            }
            return true
          })
          this.showTxt.value = true
        }
      }
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取配置项失败',
        details: result.detail
      })
      return
    }
    const allNodes = utils.deepClone(this.gridData.value)
    // 去重
    const gridDataMap = data.reduce((acc, current) => {
      // 版本
      current.versionInfo = `${current.version}.${current.iteration}`
      // 状态
      current.lifecycleStateDesc = current.lifecycleStateCode ? EnumLifecycleState[current.lifecycleStateCode] : '--'
      // 检入标记
      current.workingStateDesc = current.workingState
        ? EnumWorkingState[current.workingState as keyof typeof EnumWorkingState]
        : '--'
      acc[current.id] = current
      return acc
    }, {})
    this.gridData.value = Object.values(gridDataMap)
    const ids = this.gridData.value.map(row => row.id)
    this.disableNodes.value = allNodes.filter((node: any) => !ids.includes(node.id))
  }

  /**
   * 查询零部件下级结构
   * @param row 数据行
   */
  public viewPartStructure(row: any) {
    KDialog.show({
      title: '零部件树结构',
      size: { width: 600, height: 600 },
      showApply: false,
      showConfirm: false,
      cancelText: '关闭',
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportPartStru,
      props: {
        objParam: {
          id: row.id,
          rdmExtensionType: row.rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      }
    })
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.targetViewId && partView.length > 0) {
      const view = partView.filter(item => item.id == row.targetViewId)[0]?.description
      return '(' + view + ')'
    }
    return ''
  }

  getSelectedRows() {
    return this.refObjGrid.value?.getSelectedRows()
  }

  onCancel() {
    this.emit('cancel')
  }

  onConfirm() {
    this.emit('confirm')
  }
}
