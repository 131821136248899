import { EnumAttributeType, EnumControlElementType, EnumDataType } from '@kmsoft/upf-core'
import { BOOLEAN_CANDIDATE } from '@kmsoft/ebf-view-engine'

/**控件 */
export enum EnumControl {
  /**表格 */
  GRID = 'KDataGrid',
  /**输入框 */
  INPUT = 'KInput',
  /**多文本 */
  TEXT_AREA = 'KTextarea',
  /**日期框 */
  DATE_TIME = 'KDateTime',
  /**数字框 */
  NUMBER = 'KInputNumber',
  /**复选框 */
  CHECKBOX = 'KCheckbox',
  /**下拉框 */
  SELECT = 'KSelect',
  /**对象下拉 */
  CLASS_SELECT = 'KSelectWrapper',
  /**自定义 */
  CUSTOM = 'custom',
  /**网格列配置控件 */
  COLUMNS_DESIGNER = 'KColumnsDesigner',
  /** 筛选网格 */
  FILTER_GRID = 'KFilterGrid',
  /**对象选择器 */
  OBJECT_SELECT = 'KReferenceEditor',
  /**弹窗对象选择器 */
  GRID_OBJ_SELECT = 'KGridObjectSelect',
  /**单选按钮 */
  RADIO_GROUP = 'KClassRadio',
  /**人员选择器 */
  CREATE_DISPLAY_EDITOR = 'KCreatorEditor',
  /** 自定义控件 */
  CUSTOM_CONTROL_GRID = 'KCustomControlGrid',
  /**文件上传 */
  FILED_UPLOAD_EDITOR = 'KObjectFileUpload',
  /**文件夹选择器 */
  FOLDER_SELECTOR = 'KFolderSelector',
  /**生命周期编辑器 */
  LIFECYCLE_EDITOR = 'KLifecycleStateEditor',
  /**视图选择器 */
  VIEW_SELECTOR = 'KObjectViewSelector',
  /**分类属性选择器 */
  CATEGORY_SELECTOR = 'KCategorySelector',
  /**文件上传组件(代理) */
  FILED_UPLOAD_AGENT_EDITOR = 'KCreatePanelDocSelector',
  /**编码器 */
  ENCODERS_EDITOR = 'KCodeGenerator',
  /**主文件浏览控件 */
  FILE_BROWSER = 'KFileEditor',
  /**对象类显示器 */
  OBJECT_DISPLAY = 'KObjectDisplay',
  /**人员显示器 */
  USER_EDITOR = 'KUserSelector',
  /**基线主要成员选择器 */
  BASE_PRIMARY_OBJ_SELECTOR = 'KBaselinePrimaryObject',
  /**富文本 */
  RICH_TEXT_EDITOR = 'KCkeditor',
  /** 变更活动改前改后对象 */
  CHANGE_ACTIVITY__GRID = 'KChangeActivityObjGrid',
  /** 图片编辑器 */
  IMG_EDITOR = 'KImageView'
}
/** 编辑类型 */
export enum EnumClsEditorType {
  /**未知 */
  UNKNOWN = -1,
  /** 单行编辑框 */
  SINGLE_LINE_EDITOR = 1,
  /** 多行编辑框 */
  MULTI_LINE_EDITOR = 2,
  /** 日期编辑框 */
  DATE_EDITOR = 3,
  /** 图片编辑框 */
  IMG_EDITOR = 4,
  /** 下拉列表框, label = value */
  SELECT_EDITOR = 5,
  /** 可编辑下拉列表框 */
  SELECT_EDITABLE_EDITOR = 6,
  /** 带显示值下拉列表框  */
  SELECT_VALUED_EDITOR = 7,
  /** 列表视图 */
  LIST_EDITOR = 8,
  /** 单选列表视图 */
  LIST_SINGLE_EDITOR = 9,
  /** 编码器 */
  ENCODERS_EDITOR = 11,
  /** 复选框 */
  CHECKBOX_EDITOR = 13,
  /** 自定义控件 */
  CUSTOM_EDITOR = 17,
  /** 对象选择器 */
  OBJ_SELECTOR = 23,
  /** 弹窗对象选择器 */
  DIALOG_OBJ_SELECTOR = 24,
  /**人员选择 */
  CREATE_DISPLAY_EDITOR = 25,
  /**文件夹选择器 */
  FOLDER_SELECTOR = 26,
  /**生命周期编辑器 */
  LIFECYCLE_EDITOR = 27,
  /**文件上传 */
  FILED_UPLOAD_EDITOR = 28,
  /**视图选择器 */
  VIEW_SELECTOR = 29,
  /**分类属性选择器 */
  CATEGORY_SELECTOR = 30,
  /**文件选择器(代理) */
  FILED_UPLOAD_AGENT_EDITOR = 31,
  /**对象类显示器 */
  OBJECT_DISPLAY = 32,
  /**数组编辑框 */
  NUMBER_EDITOR = 33,
  /**人员显示器 */
  USER_EDITOR = 34,
  /**基线主要成员选择器 */
  BASE_PRIMARY_OBJ_SELECTOR = 35,
  /**富文本编辑器 */
  RICH_TEXT_EDITOR = 36,
  /**主文件浏览 */
  FILE_BROWSER = 37
}
/** 标签位置 */
export enum EnumLabelPosition {
  /** 居左 */
  LEFT = 'left',
  /** 居上 */
  TOP = 'top',
  /** 不可见 */
  HIDDEN = 'hidden'
}
/** 默认标签位置候选值 */
export const DEFAULT_LABEL_POSITION_CANDIDATE = [
  { displayValue: '居左', actualValue: EnumLabelPosition.LEFT },
  { displayValue: '居上', actualValue: EnumLabelPosition.TOP },
  { displayValue: '不显示', actualValue: EnumLabelPosition.HIDDEN }
]
/**控件候选项 */
export const controlOptions = [
  {
    displayValue: '单行文本',
    actualValue: EnumControl.INPUT
  },
  {
    displayValue: '多文本',
    actualValue: EnumControl.TEXT_AREA
  },
  {
    displayValue: '日期框',
    actualValue: EnumControl.DATE_TIME
  },
  {
    displayValue: '数字框',
    actualValue: EnumControl.NUMBER
  },
  {
    displayValue: '复选框',
    actualValue: EnumControl.CHECKBOX
  },
  {
    displayValue: '下拉框',
    actualValue: EnumControl.CLASS_SELECT
  },
  {
    displayValue: '对象选择器',
    actualValue: EnumControl.OBJECT_SELECT
  },
  {
    displayValue: '单选按钮',
    actualValue: EnumControl.RADIO_GROUP
  },
  {
    displayValue: '人员选择器',
    actualValue: EnumControl.USER_EDITOR
  },
  {
    displayValue: '人员显示器',
    actualValue: EnumControl.CREATE_DISPLAY_EDITOR
  },
  {
    displayValue: '对象类显示器',
    actualValue: EnumControl.OBJECT_DISPLAY
  },
  {
    displayValue: '文件上传组件',
    actualValue: EnumControl.FILED_UPLOAD_EDITOR
  },
  {
    displayValue: '文件夹选择器',
    actualValue: EnumControl.FOLDER_SELECTOR
  },
  {
    displayValue: '生命周期选择器',
    actualValue: EnumControl.LIFECYCLE_EDITOR
  },
  {
    displayValue: '视图选择器',
    actualValue: EnumControl.VIEW_SELECTOR
  },
  {
    displayValue: '分类属性选择器',
    actualValue: EnumControl.CATEGORY_SELECTOR
  },
  {
    displayValue: '文件上传组件(代理)',
    actualValue: EnumControl.FILED_UPLOAD_AGENT_EDITOR
  },
  {
    displayValue: '编码器',
    actualValue: EnumControl.ENCODERS_EDITOR
  },
  {
    displayValue: '主文件浏览组件',
    actualValue: EnumControl.FILE_BROWSER
  },
  {
    displayValue: '基线主要对象选择器',
    actualValue: EnumControl.BASE_PRIMARY_OBJ_SELECTOR
  },
  {
    displayValue: '富文本编辑器',
    actualValue: EnumControl.RICH_TEXT_EDITOR
  },
  {
    displayValue: '图片编辑器',
    actualValue: EnumControl.IMG_EDITOR
  }
]

/**控件通用属性 */
export const DEFAULT_CURRENCY_PROPS = [
  {
    name: 'title',
    title: '标题',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'name',
    title: '名称(唯一标识)',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'control',
    title: '组件类型',
    control: EnumControlElementType.COMBOBOX,
    options: controlOptions,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'labelPosition',
    title: '标签位置',
    control: 'KSelect',
    attributeType: EnumAttributeType.PROPS,
    options: DEFAULT_LABEL_POSITION_CANDIDATE,
    defaultValue: 'left'
  },
  {
    name: 'dataSource',
    title: '数据源',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'field',
    title: '字段',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'nullable',
    title: '是否可为空',
    control: EnumControlElementType.CHECKBOX,
    attributeType: EnumAttributeType.BASE,
    defaultValue: 0,
    disabled: false
  },
  {
    name: 'visible',
    title: '显示',
    control: EnumControlElementType.CHECKBOX,
    attributeType: EnumAttributeType.BASE,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'readonly',
    title: '是否只读',
    control: EnumControlElementType.CHECKBOX,
    defaultValue: 0,
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'disabled',
    title: '是否禁用',
    control: EnumControlElementType.CHECKBOX,
    defaultValue: 0,
    attributeType: EnumAttributeType.BASE,
    disabled: false
  }
]
/**控件通用布局属性*/
export const DEFAULT_CURRENCY_LAYOUT_PROPS = [
  {
    name: 'rowSpan',
    title: '行跨度',
    control: EnumControlElementType.INPUT,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'colSpan',
    title: '列跨度',
    control: EnumControlElementType.INPUT,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'entireRow',
    title: '占整行',
    control: EnumControlElementType.COMBOBOX,
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    disabled: false
  },
  {
    name: 'fill',
    title: '占满空间',
    control: EnumControlElementType.COMBOBOX,
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    disabled: false
  }
]
/**控件通用事件 */
export const DEFAULT_CURRENCY_EVENTS = [
  { name: 'beforeValueChange', title: '值改变前', describe: '值改变前 的说明信息...' },
  { name: 'valueChanged', title: '值改变后', describe: '值改变后 的说明信息...' }
]

/** 数据类型 */
export enum EnumClsDataType {
  /** 整数 */
  INTEGER = 10,
  /** 浮点型 */
  FLOAT = 20,
  /** 长整型 */
  LONG_INTEGER = 30,
  /** 日期 */
  DATE = 40,
  /** 字符 */
  CHARACTER = 50,
  /** 图片 */
  // IMG = 60,
  /** 大文本 */
  TEXT = 70,
  /** 对象/引用属性 */
  OBJ = 80,
  /** 分类 */
  // CLASSIFY = 90,
  /** 枚举 */
  ENUMS = 100,
  USER = 110
}

/** 数据类型，对应的options */
export const allDataTypeConfig = {
  [EnumClsDataType.INTEGER]: {
    label: '整数',
    value: EnumClsDataType.INTEGER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.FLOAT]: {
    label: '数值/高精度数/浮点型',
    value: EnumClsDataType.FLOAT,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.LONG_INTEGER]: {
    label: '长整型',
    value: EnumClsDataType.LONG_INTEGER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  [EnumClsDataType.DATE]: {
    label: '日期',
    value: EnumClsDataType.DATE,
    dateDisplayOptions: [],
    defaultEditorType: EnumClsEditorType.DATE_EDITOR
  },
  [EnumClsDataType.CHARACTER]: {
    label: '字符',
    value: EnumClsDataType.CHARACTER,
    defaultEditorType: EnumClsEditorType.SINGLE_LINE_EDITOR
  },
  // [EnumClsDataType.IMG]: { label: '图片', value: EnumClsDataType.IMG, defaultEditorType: EnumClsEditorType.IMG_EDITOR },
  // [EnumClsDataType.TEXT]: { label: '文本', value: EnumClsDataType.TEXT, defaultEditorType: EnumClsEditorType.MULTI_LINE_EDITOR },
  [EnumClsDataType.OBJ]: {
    label: '对象',
    value: EnumClsDataType.OBJ,
    defaultEditorType: EnumClsEditorType.OBJ_SELECTOR
  },
  // [EnumClsDataType.CLASSIFY]: {
  //   label: '分类',
  //   value: EnumClsDataType.CLASSIFY,
  //   defaultEditorType: EnumClsEditorType.CLASSIFY_EDITOR
  // },
  [EnumClsDataType.ENUMS]: {
    label: '枚举',
    value: EnumClsDataType.ENUMS,
    defaultEditorType: EnumClsEditorType.SELECT_VALUED_EDITOR
  },
  [EnumClsDataType.TEXT]: {
    label: '大文本',
    value: EnumClsDataType.TEXT,
    defaultEditorType: EnumClsEditorType.MULTI_LINE_EDITOR
  },
  [EnumClsDataType.USER]: {
    label: '人员',
    value: EnumClsDataType.USER,
    defaultEditorType: EnumClsEditorType.USER_EDITOR
  }
}
