import {
  Agent,
  Api,
  CommonClientSrv,
  EBF_IOC_KEY,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  FileClientSrv,
  KObjectPanelPropType,
  LoginClientSrv,
  ObjectClientSrv
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel, SearchOption } from '@kmsoft/ebf-common/src/client-agent'
import {
  AppContext,
  EnumDialogResult,
  EnumDialogState,
  IIdentityStorage,
  KDialog,
  KDialogClosingEvent,
  UPF_IOC_KEYS,
  VNodeProps
} from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import { KCADWorkspace, KPersonalWorkspace, KPersonalWorkspaceViewModel } from '@kmsoft/ebf-folder'
import { DocClientSrv } from '../../../../../client-srv'
import { KDocEditPanel, KDocStructureManage } from '../../../../../controls'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'
import { EnumWorkState } from '../../../../../controls/doc-edit-panel/interface'
import { EnumSoft } from '../../EnumSoft'
import { DdbClientSrv, EnumDdbOperation } from '../../ddb'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { DpMsgHandler } from './DpMsgHandler'

export class CadencePCBHandler {
  public static readonly srcAppID: EnumExApp = EnumExApp.CADENCE_PCB

  private static initConditions() {
    const user = LoginClientSrv.getUserIdentity()
    const conditions = [
      {
        conditionName: 'master.documentType',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: [EnumDocType.CADENCE_PCB]
      },
      {
        conditionName: 'latestVersion',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditionName: 'latestIteration',
        operator: EnumQueryConditionOperator.EQUAL,
        conditionValues: ['true']
      },
      {
        conditions: [
          {
            conditionName: 'workingState',
            conditionValues: ['CHECKED_IN'],
            operator: EnumQueryConditionOperator.EQUAL
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['CHECKED_OUT'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditions: [
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [''],
                    operator: EnumQueryConditionOperator.IS_NULL
                  },
                  {
                    conditionName: 'checkOutUserName',
                    conditionValues: [user?.name || ''],
                    operator: EnumQueryConditionOperator.NOT_EQUAL
                  }
                ],
                joiner: EnumQueryConditionJoiner.Or
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          },
          {
            conditions: [
              {
                conditionName: 'workingState',
                conditionValues: ['INWORK'],
                operator: EnumQueryConditionOperator.EQUAL
              },
              {
                conditionName: 'checkOutUserName',
                conditionValues: [user?.name || ''],
                operator: EnumQueryConditionOperator.EQUAL
              }
            ],
            joiner: EnumQueryConditionJoiner.And
          }
        ],
        joiner: EnumQueryConditionJoiner.Or
      }
    ]
    return conditions
  }

  /**登录操作处理 */
  public static async login(request: AgentRequestModel) {
    const mask = document.getElementById('maskLayer') as HTMLElement
    if (mask) {
      mask.style.display = 'none'
    }

    // 注册登录请求到DpMsgHandler
    DpMsgHandler.registerLoginRequest('CadencePCB', request, async (token: string) => {
      const srcAppID = request.Request.split('.')[0]
      await DpMsgClientSrv.Token(token)
      await CadencePCBHandler.SendMsgLoginResult(parseInt(srcAppID), 'true')
      const result = DpMsgXml.createXmlResult(request.Parameters, true, { cDocSymbol: '1', ErrInfo: '' })
      await AgentManager.pushResponse<any>(request.Id, result)
    })
  }

  /**退出处理 */
  public static async exit(request: AgentRequestModel) {
    console.log('exit', request)
    /** 同步返回结果*/
    AppContext.current
      .getIocContainer()
      .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
      .clear()
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 工作区
   */
  public static async openWorkspace(request: AgentRequestModel) {
    const param = request.Parameters
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    //根据检出路径查询文档
    KDialog.show({
      title: '工作区',
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      props: {
        agent: true
      },
      showApply: false,
      content: KCADWorkspace,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KPersonalWorkspaceViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        //TODO do something
        await AgentManager.pushResponse<any>(request.Id, result)
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 编辑
   */
  public static async edit(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    const user = LoginClientSrv.getUserIdentity()
    if (!user) {
      return
    }
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'As_Path', IOTypeEnum.IN) as string
    let currentDocId = undefined
    if (fileFullPath) {
      fileFullPath.replaceAll('/', '\\')
      const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
        data: [{ fileFullPath: fileFullPath.toUpperCase() }]
      })
      if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
        currentDocId = res.data.id
      }
    }
    const conditions = CadencePCBHandler.initConditions()
    if (currentDocId) {
      conditions.push({
        conditionName: 'id',
        operator: EnumQueryConditionOperator.NOT_EQUAL,
        conditionValues: [currentDocId]
      })
    }
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: conditions
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]
        //检出并下载
        const doc = await DocClientSrv.getDoc(targetParam?.id)
        // 判断文件状态
        const workingState = doc.workingState
        let docRes = undefined
        if (workingState == EnumWorkState.CHECKED_IN) {
          doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
          docRes = await DocClientSrv.checkOutDoc(doc)
        }
        if (workingState == EnumWorkState.CHECKED_OUT) {
          KDialog.warning({ title: '提示', content: '文档已被他人检出，不能编辑' })
          return
        }

        if (workingState == EnumWorkState.INWORK) {
          const checkoutPath = doc.checkoutPath
          docRes = { data: [doc] }
          if (!checkoutPath) {
            KDialog.warning({ title: '提示', content: '文档已检出，但未找到检出路径' })
            event.cancel = true
            return
          }
          const isExists = await Agent.File.Exists(checkoutPath)
          if (!isExists) {
            // 下载文件
            // 获取全路径的文件名和文件夹路径
            const path = checkoutPath.substring(0, checkoutPath.lastIndexOf('\\'))
            const fileName = checkoutPath.substring(checkoutPath.lastIndexOf('\\') + 1)
            await FileClientSrv.downloadFilesByAgentPost(
              [
                {
                  id: docRes.data[0].primary.id,
                  fileName: fileName,
                  modelCode: 'Document'
                }
              ],
              path
            )
          }
        }
        if (docRes) {
          //3）给插件发消息打开目录下的文件
          await CadencePCBHandler.openFile(CadencePCBHandler.srcAppID, docRes.data[0].checkoutPath)
        } else {
          event.cancel = true
        }
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 下载
   */
  public static async download(request: AgentRequestModel) {
    const result = DpMsgXml.createXmlResult(request.Parameters, '', { ErrInfo: '' })
    console.log('download', request)
    //1）打开对象选择
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      state: EnumDialogState.Maximum,
      closable: false,
      maximizeBox: false,
      minimizeBox: false,
      rootClassName: 'agent',
      objectClassManageQueryParam: {
        showObjClsCodes: ['Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: CadencePCBHandler.initConditions()
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Document']
      },
      onClosing: async event => {
        if (!event.viewInstance) {
          return
        }
        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }
        const objParams = event.viewInstance.getSelectedRows()
        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const targetParam = objParams[0]

        //2）下载文档
        const fullFilePath = await DocClientSrv.docDownload(targetParam!.id)
      },
      onClosed: async () => {
        await AgentManager.pushResponse<any>(request.Id, result)
      }
    })
  }

  /**
   * 检入
   */
  public static async checkin(request: AgentRequestModel) {
    console.log('checkin', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\').toUpperCase()
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', { data: [{ fileFullPath: checkoutPath }] })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      //给插件发消息关闭目录下的文件
      await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
      const checkInResult = await DocClientSrv.checkInCadenceDoc(data, false)
      if (checkInResult) {
        await new Promise(resolve => {
          KDialog.info({
            title: '提示',
            content: '检入成功',
            onOk: () => resolve(null)
          })
        })
      } else {
        await new Promise(resolve => {
          KDialog.info({
            title: '提示',
            content: '检入失败',
            onOk: () => resolve(null)
          })
        })
      }
    } else {
      await new Promise(resolve => {
        KDialog.warning({
          title: '提示',
          content: '文档不存在或已检入',
          onOk: () => resolve(null)
        })
      })
    }
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 取消检出
   */
  public static async undoCheckout(request: AgentRequestModel) {
    console.log('undoCheckout', request)
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const fileFullPath = DpMsgXml.getValueFromParamId(parameters!, 'cDocSymbol', IOTypeEnum.IN) as string
    const checkoutPath = fileFullPath?.replaceAll('/', '\\').toUpperCase()
    //根据检出路径查询文档
    const res = await Api.post('doc', 'Document', 'getDocByCheckoutPath', {
      data: [{ fileFullPath: checkoutPath.toUpperCase() }]
    })
    if (res && res.code == EnumRequestCode.SUCCESS && res.data) {
      const data = res.data
      await DocClientSrv.docUnCheckOut(data!.id)
      //给插件发消息关闭目录下的文件
      await DdbClientSrv.CloseFile(EnumSoft.CADENCE.toString(), checkoutPath)
    } else {
      await new Promise(resolve => {
        KDialog.warning({
          title: '提示',
          content: '文档不存在或已检入',
          onOk: () => resolve(null)
        })
      })
    }

    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /**
   * 元器件同步
   */
  public static async updateLib(request: AgentRequestModel) {
    console.log('updateLib', request)
    /** 同步返回结果*/
    const param = request.Parameters
    const parameters = DpMsgXml.parseXml(param)
    const result = DpMsgXml.createXmlResult(request.Parameters, '', {
      cDocSymbol: 1,
      ErrInfo: ''
    })
    const response = await Api.post('doc', 'Document', 'listAllEDALibraryFiles', {
      data: []
    })
    if (response && response.code == EnumRequestCode.SUCCESS && response.data) {
      const downloadList: any[] = response.data
      const dialog = new Agent.FolderBrowserDialog()
      dialog.state = EnumDialogState.Maximum
      if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
        // alert(dialog.FileName)
        const selectedFilePath = dialog.SelectedPath
        const showDialog = KDialog.info({ content: '正在下载文件，请稍后...', title: '提示', showOk: false })
        await FileClientSrv.downloadFilesByAgentPost(
          downloadList.map(downloadData => ({
            id: downloadData.fileId,
            fileName: downloadData.fileName,
            modelCode: downloadData.modelCode
          })),
          selectedFilePath
        )
        showDialog.destroy()
        await new Promise(resolve => {
          KDialog.info({ title: '提示', content: '下载完成', onOk: () => resolve(null) })
        })
        // 调用代理打开文件目录
        // 打开目录
        // 打开文件夹并选中
        const process = await Agent.Process.create()
        const startinfo = await process.StartInfo()
        //await startinfo.FileName(fullFilePath)
        await startinfo.Arguments('/select,' + selectedFilePath + '\\' + downloadList[0].fileName)
        await startinfo.UseShellExecute(true)
        await startinfo.FileName('explorer')
        await process.Start()
      }
    } else {
      KDialog.warning('获取元器件列表失败')
    }
    await AgentManager.pushResponse<any>(request.Id, result)
  }

  /** 发送消息,登录结果*/
  public static async SendMsgLoginResult(appID: EnumExApp, isLogined: string) {
    const res = isLogined == 'true' ? 'OK' : 'CANCEL'
    // const paramters: ActualParameter[] = [
    //   { FormalParameterID: 'Res', IOType: 'In', DataType: 'string', IsArray: '0', Value: res },
    //   { FormalParameterID: 'SoftCaption', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'saas' },
    //   { FormalParameterID: 'UserName', IOType: 'In', DataType: 'string', IsArray: '0', Value: 'One的账号' }
    // ]
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_ISLOGIN',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'Res',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'OK'
          },
          {
            FormalParameterID: 'SoftCaption',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: 'saas'
          },
          {
            FormalParameterID: 'UserName',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: '系统管理员'
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const result = DpMsgXml.objectToXML(application, 'Application')
    await DpMsgClientSrv.Send(appID, 'ID_PDM_APP_ISLOGIN', result, 3)
  }

  /**
   * 打开文件
   */
  public static async openFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_OPENFILE',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_OPENFILE', res, 3)
  }

  /**
   * 结构
   */
  public static async loadPart(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_LOADPART',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: `<file name = "${fileFulPath}"></file>`
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_LOADPART', res, 3)
  }

  /**
   * 关闭文件
   */
  public static async closeFile(enumExApp: EnumExApp, fileFulPath: string) {
    const application: Application = {
      IntefaceName: 'ID_PDM_APP_CLOSEFILEDEL',
      InterfaceFunName: '',
      ActualParameters: {
        ActualParameter: [
          {
            FormalParameterID: 'cDocList',
            IOType: 'In',
            DataType: 'String',
            IsArray: 'False',
            Value: fileFulPath
          },
          {
            FormalParameterID: 'ErrInfo',
            IOType: 'Out',
            DataType: 'String',
            IsArray: 'False',
            Value: ''
          }
        ]
      },
      ReturnParameter: {
        FormalParameterID: 'Result',
        DataType: 'String',
        Value: ''
      }
    }
    const res = DpMsgXml.objectToXML(application, 'Application')
    DpMsgClientSrv.Send(enumExApp, 'ID_PDM_APP_CLOSEFILEDEL', res, 2)
  }
}
