import { connect, withInstall } from '@kmsoft/upf-core'
import KPartSimulationReportFormView from './KPartSimulationReportForm.vue'
import KPartSimulationReportFormViewModel from './KPartSimulationReportFormViewModel'

const KPartSimulationReportForm = connect(KPartSimulationReportFormView, KPartSimulationReportFormViewModel)

export default withInstall(KPartSimulationReportForm)
export { KPartSimulationReportForm, KPartSimulationReportFormView, KPartSimulationReportFormViewModel }

// 模板生成文件
// export * from './{{folderName}}'
