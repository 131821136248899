import { BaseViewModel, ViewModelOptions, KDataGridViewModel } from '@kmsoft/upf-core'
import { KImportItemEmitsType, KImportItemPropType } from './interface'
import { ref, watch } from 'vue'

/** KImportItem */
export default class KImportItemViewModel extends BaseViewModel<KImportItemEmitsType, KImportItemPropType> {
  /** 网格引用 */
  refDataGrid = ref<KDataGridViewModel>()
  inputValue = ref<string>('')
  dataSource = ref<Array<Record<string, any>>>([])
  originalDataSource = ref<Array<Record<string, any>>>([])

  constructor(options: ViewModelOptions<KImportItemPropType>) {
    super(options)
    watch(
      () => this.inputValue.value,
      newValue => {
        if (!newValue) {
          this.queryData()
        }
      }
    )

    this.dataSource.value = options.props.dataSource
    this.originalDataSource.value = options.props.dataSource
  }

  viewDidMount() {}

  queryData() {
    this.dataSource.value = this.originalDataSource.value.filter(item =>
      item.name?.toLowerCase().includes(this.inputValue.value?.toLowerCase())
    )
  }

  getValue() {
    return this.refDataGrid.value?.getSelectedRows()
  }
}
