import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { nextTick, ref, watch } from 'vue'
import { Doc } from '../../beans/Doc'
import { DocClientSrv, EnumOperatorType } from '../../client-srv'
import { KSelectDocEmitsType, KSelectDocPropType } from './interface'
import { DocNode, DocStructure, DocStructureTreeNode, KDocStructureTreeViewModel } from '../doc-structure-tree'
import { Agent, Api, EnumRequestCode } from '@kmsoft/ebf-common'
import lodash from 'lodash'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { EnumWorkState } from '../doc-edit-panel/interface'

/** KSelectDoc */
export default class KSelectDocViewModel extends BaseViewModel<KSelectDocEmitsType, KSelectDocPropType> {
  // 树控件引用
  refTreeView = ref<KDocStructureTreeViewModel>()
  // 是否在加载
  isLoading = ref(false)
  // 代理是否开启
  isAgent = ref(false)
  // 代理选择路径值
  selectedFilePath = ref<string>('')
  // 是否显示选择路径（代理）
  isShowPath = ref(false)
  // 是否显示多选
  isMultipleSelection = ref(true)
  // 文件属性勾选状态
  isCheckedFileProperty = ref(false)
  // 创建零部件勾选状态
  isCreatePart = ref(false)
  // 是否可以选择路径
  isDisabled = ref(false)
  // 是否显示文件属性选项
  isShowProperty = ref(false)
  // 是否显示创建零部件选项
  isShowCreatePart = ref(false)
  // 是否可以选择零部件结构
  isDisabledCreatePart = ref(true)
  // 是否显示删除本地文件
  isShowDeleteLocalFile = ref(false)
  // 是否删除本地文件勾选状态
  isDeleteLocalFile = ref(false)

  rootId = ref<string>('')

  constructor(options: ViewModelOptions<KSelectDocPropType>) {
    super(options)

    watch(
      () => this.isCheckedFileProperty.value,
      newVal => {
        if (!newVal) {
          this.isCreatePart.value = false
        }
      },
      {
        immediate: true
      }
    )

    if (
      options.props.type == EnumOperatorType.Download ||
      options.props.type == EnumOperatorType.Browser ||
      options.props.type == EnumOperatorType.Editor
    )
      this.isMultipleSelection.value = false

    if (options.props.type == EnumOperatorType.Checkin) {
      this.isDisabled.value = true
      this.isShowDeleteLocalFile.value = true
      this.selectedFilePath.value = options.props.doc?.checkoutPath ?? ''
      // 显示文件属性选项
      if (options.props.doc?.documentType == EnumDocType.CADENCE || options.props.doc?.documentType == EnumDocType.CADENCE_PCB) {
        this.isShowProperty.value = false
        this.isShowCreatePart.value = false
      } else {
        this.isShowProperty.value = true
        this.isShowCreatePart.value = true
      }
    }

    if (options.props.type == EnumOperatorType.Checkout) {
      this.selectedFilePath.value = options.props.doc?.checkoutPath ?? ''
    }

    if (options.props.type == EnumOperatorType.Download) {
      this.selectedFilePath.value = options.props.doc?.checkoutPath ?? ''
    }

    this.getAgent(options.props.type!)
  }

  async getAgent(type: EnumOperatorType) {
    this.isAgent.value = await Agent.AgentManager.initialize()

    if (this.isAgent.value && type != EnumOperatorType.UnCheckout) {
      this.isShowPath.value = true
    } else {
      this.isShowPath.value = false
    }
    if (type == EnumOperatorType.Browser) {
      this.isShowPath.value = false
    }
  }

  convertToDoc(nodes: Array<DocNode>): Doc[] {
    if (!nodes || nodes.length <= 0) return []
    const root = this.refTreeView.value!.treeView!.getRootNodes() as Array<DocStructureTreeNode>
    const docs = nodes.map(a => {
      return {
        ...lodash.omit(a, 'file'),
        ...lodash.omit(a.file, 'id'),
        fileId: a.file.id,
        fileName: a.file.originalFileName,
        isRoot: root[0].docObjParam.id == a.id
      } as Doc
    })
    // 根据id去重
    const uniqueDocs = Array.from(new Map(docs.map(doc => [doc.id, doc])).values())
    return uniqueDocs
  }

  convertToDocNode(datas: Array<any>): DocNode[] {
    if (!datas || datas.length <= 0) return []
    return datas.map(a => {
      return {
        modelCode: a.rdmExtensionType,
        id: a.id,
        masterId: a.master.id,
        name: a.master.name,
        code: a.master.number,
        selected: a.selected,
        version: a.version,
        iteration: a.iteration,
        documentType: a.documentType,
        file: {
          id: a.primary[0].id,
          originalFileName: a.primary[0].name
        },
        checkout: a.workingCopy,
        checkoutPath: a.checkoutPath,
        lifecycleStateCode: a.lifecycleStateCode,
        workingState: a.workingState,
        kiaguid: a.kiaguid
      } as DocNode
    })
  }

  async getDocList(): Promise<{ downLoadList: Doc[]; operatorList: Doc[]; unOperatorList: Doc[] } | undefined> {
    // 页面所有节点
    let allResult = []
    // 最终操作节点（含未展开节点）
    let operatorResult: DocNode[] = []
    // 最终未操作节点（含未展开节点）
    let unOpetatorResult = []

    // 最终下载节点（含未展开节点）
    let downloadResult = []

    const root = this.refTreeView.value!.treeView!.getRootNodes() as Array<DocStructureTreeNode>
    allResult.push(root[0])
    const childNodes = this.refTreeView.value!.treeView!.getChildNodes(root[0].key) as Array<DocStructureTreeNode>
    if (childNodes) allResult = allResult.concat(childNodes)

    downloadResult = allResult.map(_ => _.docObjParam)

    // 界面选择节点
    const selectNodes = this.refTreeView.value!.treeView!.getCheckedNodes() as Array<DocStructureTreeNode>

    // 操作节点必须包含根节点
    if (!selectNodes.find(a => a.docObjParam.id == root[0].docObjParam.id)) {
      operatorResult.push(root[0].docObjParam)
    }
    operatorResult = operatorResult.concat(selectNodes.map(a => a.docObjParam))

    // 页面未操作节点
    unOpetatorResult = allResult
      .map(_ => _.docObjParam)
      .filter(a => operatorResult.every(b => a.id != b.id && a.modelCode == b.modelCode))

    //获取未展开节点
    if (root) {
      const unExpandNodes = this.refTreeView
        .value!.getAllUnExpandNodes(root[0])
        .map(a => ({ ...lodash.pick(a.docObjParam, 'id'), selected: a.checked }))

      if (unExpandNodes && unExpandNodes.length > 0) {
        const params = {
          rootId: root[0].docObjParam.id,
          unExpendNodeList: unExpandNodes
        }
        // 后端返回所有子数据
        // todo 递归获取所有子节点
        const result = (await Api.post('doc', 'Document', 'listUnExpendChildren', {
          data: [params]
        })) as any
        // 对返回数据二次处理与页面选中数据合并
        if (result && result.code == EnumRequestCode.SUCCESS) {
          downloadResult = downloadResult.concat(this.convertToDocNode(result.data))
          operatorResult = operatorResult.concat(this.convertToDocNode(result.data.filter((a: any) => a.selected == true)))
          unOpetatorResult = unOpetatorResult.concat(this.convertToDocNode(result.data.filter((a: any) => a.selected == false)))
        }
      }
      switch (this.props.type) {
        case EnumOperatorType.Checkout:
          {
            operatorResult = operatorResult.filter(
              a => a.workingState == EnumWorkState.CHECKED_IN && a.lifecycleStateCode == 'InWork' && a.kiaguid?.includes('MODIFY')
            )
          }
          break
        case EnumOperatorType.Checkin:
        case EnumOperatorType.UnCheckout:
          {
            operatorResult = operatorResult.filter(
              a => a.workingState == EnumWorkState.INWORK && a.lifecycleStateCode == 'InWork' && a.kiaguid?.includes('MODIFY')
            )
          }
          break
        default:
          break
      }
      // 获取操作的节点集合
      const operatorList = this.convertToDoc(operatorResult as Array<DocNode>)
      // 未操作的节点集合
      const unOperatorList = this.convertToDoc(unOpetatorResult as Array<DocNode>)
      // 下载所有节点
      const downloadList = this.convertToDoc(downloadResult as Array<DocNode>)

      return { downLoadList: downloadList, operatorList: operatorList, unOperatorList: unOperatorList }
    }
    return undefined
  }

  // 递归获取所有节点
  getAll(node: DocStructure, list: DocStructure[]): Array<DocStructure> {
    if (!node) return []
    list.push(node)
    if (node.children) {
      for (const child of node.children) {
        this.getAll(child as DocStructureTreeNode, list)
      }
    }
    return list
  }

  async selectPath() {
    const dialog = new Agent.FolderBrowserDialog()
    if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
      // alert(dialog.FileName)
      this.selectedFilePath.value = dialog.SelectedPath
    }
    return undefined
  }

  // 获取页面选项
  getOptions(): Record<string, any> {
    return {
      selectedFilePath: this.selectedFilePath.value,
      isCheckedFileProperty: this.isCheckedFileProperty.value,
      isCreatePart: this.isCreatePart.value,
      isCascade: this.refTreeView.value!.getNotCheckStrictly(),
      isShowPath: this.isShowPath.value,
      isDeleteLocalFile: this.isDeleteLocalFile.value
    }
  }

  download() {
    DocClientSrv.download(this.props.doc!)
  }

  viewDidMount() {
    const root = this.refTreeView.value!.treeView!.getRootNodes() as Array<DocStructureTreeNode>
    //this.rootId.value=root[0].docObjParam?.id
  }

  treeReady() {
    const root = this.refTreeView.value!.treeView!.getRootNodes() as Array<DocStructureTreeNode>
    nextTick(() => {
      if (this.props.type == EnumOperatorType.Checkin || this.props.type == EnumOperatorType.UnCheckout) {
        if (root[0].docObjParam.checkout == true) {
          this.refTreeView.value!.treeView!.setCheckedKeys([root[0].key])
          root[0].checked = true
        }
      } else if (this.props.type == EnumOperatorType.Checkout) {
        this.refTreeView.value!.treeView!.setCheckedKeys([root[0].key])
        root[0].checked = true
      } else {
        this.refTreeView.value!.treeView!.setCheckedKeys([root[0].key])
        root[0].checked = true
      }
    })
  }

  disablePredicate(node: DocStructureTreeNode) {
    if (this.props.type == EnumOperatorType.Checkin || this.props.type == EnumOperatorType.UnCheckout) {
      return node.docObjParam.checkout == false
    }
    if (this.props.type == EnumOperatorType.Checkout) {
      return node.docObjParam.checkout == false
    }

    return false
  }
}
