import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocFilePropertyPropOptions = {
  ...BaseViewPropOptions,
  /** 是否级联 **/
  isCascade: VuePropTypes.bool().def(false),
  /** 是否禁用级联 **/
  cascadeDisabled: VuePropTypes.bool().def(false),
  /** 是否显示属性提取 */
  propertiesVisible: VuePropTypes.bool().def(true),
  /** 是否禁用属性提取 **/
  propertiesDisabled: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KDocFilePropertyPropType = ViewPropsTypeExtract<typeof KDocFilePropertyPropOptions>

/** 事件 */
export const KDocFilePropertyEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocFilePropertyEmitsType = ViewEmitsTypeExtract<typeof KDocFilePropertyEventEmits>
