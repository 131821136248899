
import { defineView } from '@kmsoft/upf-core'
import { KDocFormProcessPropOptions, KDocFormProcessEventEmits } from './interface'
import KDocFormProcessViewModel from './KDocFormProcessViewModel'

export default defineView({
  name: 'KDocFormProcess',
  props: KDocFormProcessPropOptions,
  emits: KDocFormProcessEventEmits,
  viewModel: KDocFormProcessViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
