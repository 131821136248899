import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

interface OptionType {
  value: string
  label: string
}

/** 参数 **/
export const KChangeStatisticsPropOptions = {
  ...BaseViewPropOptions,
  targetTitle: VuePropTypes.string().def(''),
  echartsType: VuePropTypes.string().def('line'),
  sourceData: VuePropTypes.createType<Record<string, []>>().def({ xData: [], yData: [] }),
  xData: VuePropTypes.array<string | number>().def([]),
  yData: VuePropTypes.array<string | number>().def([]),
  echartsOption: VuePropTypes.createType<object>().def({}),
  tooltip: VuePropTypes.createType<object>().def(),
  grid: VuePropTypes.createType<object>().def(),
  title: VuePropTypes.createType<object>().def(),
  xAxis: VuePropTypes.createType<object>().def(),
  yAxis: VuePropTypes.createType<object>().def(),
  series: VuePropTypes.createType<Array<object>>().def()
}

/** 参数类型 **/
export type KChangeStatisticsPropType = ViewPropsTypeExtract<typeof KChangeStatisticsPropOptions>

/** 事件 */
export const KChangeStatisticsEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KChangeStatisticsEmitsType = ViewEmitsTypeExtract<typeof KChangeStatisticsEventEmits>
