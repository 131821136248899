
import { defineComponent } from 'vue'
import { AppContext, IIdentityStorage, UPF_IOC_KEYS, IAuthenticationInterceptor } from '@kmsoft/upf-core'
import { EBF_IOC_KEY, sysSrv } from '@kmsoft/ebf-common'
export default defineComponent({
  computed: {
    userName() {
      const userCode = AppContext.current.getIdentity()?.name
      return userCode?.split(' ')[0]
    }
  },
  methods: {
    async logoutClickHandler() {
      const authentication = this.getAuthentication()
      if (authentication) {
        authentication
          .beforeSignOut()
          .then(() => {
            //校验是否为单点登录
            if (sysSrv.isSSOLogin()) {
              //获取统一认证地址
              const baseUrl = AppContext.current.getEnvironment().getProperty('url.agentLoginAuthorize') as string
              const origin =
                window.location.origin == 'http://plm.kmsoft.com.cn' ? 'http://110.41.125.166' : window.location.origin
              const newUrl = baseUrl + `&redirect_uri=${window.location.origin}/pdm/ebf/portal`
              const url = `${origin}/basic/mgmt/api/v1/logout?redirect_url=${encodeURIComponent(newUrl)}`
              localStorage.removeItem('KMPDM-refreshToken')
              window.location.replace(url)
              AppContext.current
                .getIocContainer()
                .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
                .clear()
            } else {
              AppContext.current
                .getIocContainer()
                .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
                .clear()
              AppContext.current.getApplication().router.redirectLoginPage()
            }
          })
          .then(() => {
            authentication.signedOut()
          })
      } else {
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .clear()
        AppContext.current.getApplication().router.redirectLoginPage()
      }
    },
    getAuthentication() {
      try {
        const authentication = AppContext.current
          .getIocContainer()
          .getBean<IAuthenticationInterceptor>(UPF_IOC_KEYS.AUTHENTICATION_INTERCEPTOR)
        return authentication
      } catch (error) {
        return null
      }
    }
  }
})
