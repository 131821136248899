import { connect, withInstall } from '@kmsoft/upf-core'
import KrequirementKanbanView from './KrequirementKanban.vue'
import KrequirementKanbanViewModel from './KrequirementKanbanViewModel'

const KrequirementKanban = connect(KrequirementKanbanView, KrequirementKanbanViewModel)

export default withInstall(KrequirementKanban)
export { KrequirementKanban, KrequirementKanbanView, KrequirementKanbanViewModel }

// 模板生成文件
// export * from './{{folderName}}'
