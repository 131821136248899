
import { EnumDataGridAutoSizeColumnsMode, EnumDataGridRowModelType, defineView } from '@kmsoft/upf-core'
import { KPartSimulationReportFormPropOptions, KPartSimulationReportFormEventEmits } from './interface'
import KPartSimulationReportFormViewModel from './KPartSimulationReportFormViewModel'

export default defineView({
  name: 'KPartSimulationReportForm',
  props: KPartSimulationReportFormPropOptions,
  emits: KPartSimulationReportFormEventEmits,
  viewModel: KPartSimulationReportFormViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
