import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocStructureListPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  refreshTip: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KDocStructureListPropType = ViewPropsTypeExtract<typeof KDocStructureListPropOptions>

/** 事件 */
export const KDocStructureListEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocStructureListEmitsType = ViewEmitsTypeExtract<typeof KDocStructureListEventEmits>
