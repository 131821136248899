import {
  Api,
  CommonClientSrv,
  EnumLifecycleState,
  EnumRequestCode,
  EnumWorkingState,
  IFrameEventManager
} from '@kmsoft/ebf-common'
import {
  EnumDialogResult,
  IKTreeNode,
  KDataGridRowData,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  AppContext
} from '@kmsoft/upf-core'
import lodash from 'lodash'
import { createVNode } from 'vue'
import { BaselineClientSrv } from '../../../../ebf-baseline/src/client-srv'
import { ChangeManageClientSrv } from '../../../../ebf-change-manage/src/client-srv'
import { RequirementChangeClientSrv } from '../../../../ebf-requirement/src/client-srv'
import { KDocRelationObj } from '../../../../ebf-doc/src'
import { DocClientSrv } from '../../../../ebf-doc/src/client-srv'
import { PartClientSrv } from '../../../../ebf-part/src'
import { WorkflowClientSrv } from '../../../../ebf-workflow/src'
import KFolderTree from '../../pages/folder-manage/folder-tree'
import { IFolder } from '../../pages/folder-manage/folder-tree/interface'
import { CADWorkspaceRootTreeNode } from '../../pages/workspace/cad-workspace/interface'
import { EnumPMSMessageType } from '../../../../ebf-project-manage/src/components/enums'

export class FolderClientSrv {
  /**
   * 创建变更活动
   */
  public static openCreateChangeActivityPanel(isUpdateBefore: boolean, rows: Array<KDataGridRowData>, container: any) {
    const data = (lodash.cloneDeep(rows) as any[]).map(item => {
      item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
      if (!item.rdmExtensionType) {
        item.rdmExtensionType = item?.targetExtensionType
      }
      item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
      return item
    })
    if (isUpdateBefore) {
      ChangeManageClientSrv.createChangeActivity('基于修改前创建变更活动', data, null, container, data[0].targetFolderId)
    } else {
      ChangeManageClientSrv.createChangeActivity('基于修改后创建变更活动', null, data, container, data[0].targetFolderId)
    }
  }

  /**
   * 添加到变更活动
   */
  public static addToChangeActivity(isUpdateBefore: boolean, rows: Array<KDataGridRowData>, container: any) {
    const partData = lodash.cloneDeep(rows) as any

    ChangeManageClientSrv.addToChangeActivity(isUpdateBefore, partData, container)
  }

  /**
   * 添加到CAD工作区
   * @param event 按钮事件
   */
  public static async addToCADWorkspace(rows: Array<KDataGridRowData>, container: any) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    KDialog.show({
      title: '添加至CAD工作区',
      content: KFolderTree,
      props: {
        loadData: async () => {
          const param = {
            data: []
          }
          const res = await Api.post('folder', 'Folder', 'getCADWorkspace', param)
          if (res && res.code == EnumRequestCode.SUCCESS) {
            const node = {
              id: res.data?.id,
              name: res.data?.name,
              leafFlag: res.data.leafFlag,
              nameEn: res.data.nameEn,
              type: res.data.type,
              disabled: true
            } as IFolder
            return [new CADWorkspaceRootTreeNode(node)]
          } else {
            KNotification.error({
              title: '获取CAD工作区数据异常',
              content: res.message!
            })
            return Promise.resolve([])
          }
        }
      },
      size: { width: 490, height: 478 },
      getContainer: container,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const node = await formViewModel.getSelectNode()
        if (!node) {
          KNotification.warn({
            message: '系统提示',
            description: '请选择一个文件夹'
          })
          event.cancel = true
          return
        }
        const objList = rows.map(row => {
          if (row.targetBranchId != null && row.targetBranchId != '') {
            return {
              id: row.targetBranchId,
              clazz: row.targetClass + 'Branch'
            }
          } else {
            return {
              id: row.targetId,
              clazz: row.targetClass
            }
          }
        })
        const params = {
          data: [
            {
              folderId: node.id,
              objList: objList
            }
          ]
        }
        const res = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (res && res.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '成功加入CAD工作区'
          })
          return Promise.resolve([])
        } else {
          KNotification.error({
            title: '加入工作区失败',
            content: res.message!,
            details: res.detail
          })
          return Promise.resolve([])
        }
      }
    })
  }

  public static async saveAs(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        const partResult = await PartClientSrv.saveAsPart(rows[0].targetId, rows[0].targetFolderId)
        return partResult
      case 'Document':
        const docResult = await DocClientSrv.docSaveAs(rows[0].targetId, rows[0].targetFolderId)
        return docResult
      default:
        break
    }
  }

  /**
   * 发布
   */
  public static async publish(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        const result = await PartClientSrv.releasePart(rows)
        return result
      case 'Document':
        // eslint-disable-next-line no-case-declarations
        return DocClientSrv.release(rows)
      default:
        break
    }
  }

  /**
   * 批量删除对象
   */
  public static async batchDelete(selectNode: any, rows: Array<KDataGridRowData>, isBranch: boolean = false) {
    return new Promise(async (resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        reject(void 0)
        return
      }

      // 删除校验
      const deleteRows = rows.map((row: any) => {
        return {
          folderedLinkId: row.id,
          folderId: row.targetFolderId,
          objId: row.targetId,
          className: row.targetClass,
          branchId: row.targetBranchId
        }
      })
      const param = { data: [selectNode.id, [...deleteRows]] }
      const showDialog = KDialog.info({ content: '正在校验删除对象，请稍后...', title: '提示', showOk: false })
      const check = await Api.post(
        'folder',
        'Folder',
        isBranch ? 'batchDeleteBranchVerification' : 'batchDeleteVerification',
        param
      )
      showDialog.destroy()
      // 接口调用失败
      if (!(check && check.code == EnumRequestCode.SUCCESS)) {
        KNotification.error({
          title: '系统错误',
          content: check.message
        })
        reject(void 0)
        return
      }

      // 删除校验失败
      if (!check.data.deletable) {
        KNotification.error({
          title: '删除校验失败',
          content: check.data.message
        })
        reject(void 0)
        return
      }
      // 删除
      KModal.delete({
        title: '删除提示',
        icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
        content: `您正在进行删除操作${check.data.message ? '(' + check.data.message + ')' : ''}，请确认是否删除`,
        onOk: async () => {
          const result = await Api.post('folder', 'Folder', isBranch ? 'deleteFolderObjAndBranch' : 'deleteFolderObj', param)
          if (!(result && result.code == EnumRequestCode.SUCCESS)) {
            KNotification.error({
              title: '对象删除失败',
              content: result.message!
            })
            reject(void 0)
            return
          }
          KNotification.success('对象删除成功')
          resolve(result)
          return
        }
      })
    })
  }

  /**
   * 废弃
   */
  public static async batchObsolete(rows: Array<KDataGridRowData>) {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        reject(void 0)
        return
      }

      // 删除
      KModal.confirm({
        title: '废弃提示',
        icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
        content: `您正在进行废弃操作，请确认是否废弃`,
        onOk: async () => {
          const param = rows.map(row => {
            return {
              id: row.targetId,
              className: row.targetClass
            }
          })
          const res = await Api.post('folder', 'Folder', 'batchObsolete', { data: [param] })
          if (res && res.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '对象已废弃'
            })
            resolve(res)
            return
          } else {
            KNotification.error({
              title: '对象废弃操作异常',
              content: res.message!
            })
            reject(void 0)
            return
          }
        }
      })
    })
  }
  /**
   * 结构管理
   */
  public static async structManage(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    const mode = AppContext.current.getEnvironment().getProperty<string>('app.projectType')
    // 项目管理打开对象详情
    if (mode == 'pms') {
      const iFrameEventManager = IFrameEventManager.register({
        appId: 'SAAS',
        get targetWindow() {
          return window.parent.parent
        }
      })
      const param = {
        type: EnumPMSMessageType.TOOL_STRIP_ITEM_STRUC_MANAGE,
        props: {
          objParams: rows
        }
      }
      iFrameEventManager.dispatchEvent('PMS', param)
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        PartClientSrv.structManage(rows[0].targetId)
        break
      case 'Document':
        DocClientSrv.structManage(rows[0].targetId)
        break
      default:
        break
    }
  }

  /**
   * 检出
   */
  public static async checkOut(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        const partResult = await PartClientSrv.checkoutPart(rows[0].targetId)
        return partResult
      case 'Document': {
        const docResult: any = await new Promise((resolve, reject) => {
          KDialog.confirm({
            title: '确认检出吗？',
            onOk: async () => {
              try {
                // 获取对象id
                const result = await DocClientSrv.docCheckOut(rows[0].targetId)
                resolve(result.data)
              } catch (error) {
                reject(error)
              }
            }
          })
        })
        return docResult
      }
      default:
        break
    }
  }

  /**
   * 撤销检出
   */
  public static async unCheckOut(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part': {
        const partResult = await PartClientSrv.undoCheckoutPart(rows[0].targetId)
        return partResult
      }
      case 'Document': {
        const docResult: any = await new Promise((resolve, reject) => {
          KDialog.confirm({
            title: '确认撤销检出吗？',
            onOk: async () => {
              try {
                // 获取对象id
                const result = await DocClientSrv.docUnCheckOut(rows[0].targetId)
                resolve(result.data)
              } catch (error) {
                reject(error)
              }
            }
          })
        })
        return docResult
      }
      default:
        break
    }
  }

  /**
   * 检入
   */
  public static async checkIn(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        const partResult = await PartClientSrv.checkInPart(rows[0].targetId)
        return partResult
      case 'Document': {
        const docResult: any = await new Promise((resolve, reject) => {
          KDialog.confirm({
            title: '确认检入吗？',
            onOk: async () => {
              try {
                // 获取对象id
                const result = await DocClientSrv.docCheckIn(rows[0].targetId)
                resolve(result.data)
              } catch (error) {
                reject(error)
              }
            }
          })
        })
        return docResult
      }
      default:
        break
    }
  }

  /**
   * 修订
   */
  public static async revise(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part': {
        const partResult = await PartClientSrv.revisePart(rows[0].targetId, rows[0]?.targetFolderId)
        return partResult
      }
      case 'Document': {
        const docResult = await DocClientSrv.docRevise(rows[0].targetId, rows[0]?.targetFolderId)
        return docResult
      }
      default:
        break
    }
  }

  /**
   * 浏览
   */
  public static async browse(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        PartClientSrv.doBrowse(rows[0].targetId)
        break
      case 'Document':
        DocClientSrv.browseDoc({ id: rows[0].targetId, modelCode: rows[0].targetExtensionType })
        break
      default:
        break
    }
  }

  /**
   * 下载文件
   */
  public static async downloadDoc(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        DocClientSrv.docDownloadBatch(rows.filter(_ => _.targetExtensionType == 'Document').map(_ => _.targetId))
        break
      default:
        break
    }
  }

  /**
   * 编辑文件
   */
  public static async editFile(rows: Array<KDataGridRowData>): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length != 1) {
        KNotification.warn({
          message: '系统提示',
          description: '请选择一个对象'
        })
        return
      }
      switch (rows[0].targetClass) {
        case 'Document':
          DocClientSrv.editFile(rows[0].targetId).then(docResult => resolve(docResult))
          break
        default:
          break
      }
    })
  }

  /**
   * 从本地替换
   */
  public static async fileReplaceFromLocalFile(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        const docResult = await DocClientSrv.doReplaceFromLocalFile(rows[0].targetId)
        return docResult
      default:
        break
    }
  }

  /**
   * 从模板替换
   */
  public static async fileReplaceNew(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        break
      default:
        break
    }
  }

  /**
   * 从已有文档替换
   */
  public static async fileReplaceQuery(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        break
      default:
        break
    }
  }

  /**
   * 历史版本
   */
  public static async historyVersion(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }

    switch (rows[0].targetClass) {
      case 'Document':
        const doc = await DocClientSrv.getDoc(rows[0].targetId)
        CommonClientSrv.openPage(
          '文档历史版本',
          KDocRelationObj,
          {
            objParam: {
              id: doc.id,
              modelCode: 'Document',
              modelGroup: 'doc',
              masterId: doc.master.id
            },
            activeKey: ['4'],
            tabId: 'lsbb'
          },
          '文档历史版本' + doc.id
        )
        break
      default:
        break
    }
  }

  /**
   * 批注
   */
  public static async remark(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        // 判断文件类型
        const docData = (await DocClientSrv.getDoc(rows[0].targetId)) as any
        if (!docData) return
        DocClientSrv.doRemark(rows[0].targetId, docData.documentType)
        break
      default:
        break
    }
  }

  /**
   * 查看批注
   */
  public static async viewRemark(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        DocClientSrv.doViewRemark(rows[0].targetId)
        break
      default:
        break
    }
  }

  /**
   * 查看批注历史版本
   */
  public static async viewRemarkHistory(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Document':
        DocClientSrv.doViewRemarkHistory(rows[0].targetId)
        break
      default:
        break
    }
  }

  /**
   * 创建变更问题报告
   */
  public static async createChangeIssue(rows: Array<KDataGridRowData>, container: any, folder?: IKTreeNode): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const getData = []
      const partRows = rows.filter((item: KDataGridRowData) => item.targetClass == 'Part')
      const docRows = rows.filter((item: KDataGridRowData) => item.targetClass == 'Document')
      if (partRows.length > 0) getData.push(PartClientSrv.batchGetParts(partRows))
      if (docRows.length > 0) getData.push(DocClientSrv.getDocList(docRows))

      Promise.all(getData)
        .then(async res => {
          const data = res.map(item => {
            if (item.code == EnumRequestCode.SUCCESS) {
              return item.data
            } else {
              return []
            }
          })
          const listRows = data.flat()
          const result = await ChangeManageClientSrv.createChangeIssue(listRows, container, folder?.id)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            resolve(result)
          }
        })
        .catch(err => {
          KNotification.error({
            title: '获取信息失败',
            content: '获取零部件和文档信息失败'
          })
        })
    })
  }

  /**
   * 创建变更申请
   */
  public static async createChangeRequest(rows: Array<KDataGridRowData>, container: any, folder?: IKTreeNode): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const getData = []
      const partRows = rows.filter((item: KDataGridRowData) => item.targetClass == 'Part')
      const docRows = rows.filter((item: KDataGridRowData) => item.targetClass == 'Document')
      if (partRows.length > 0) getData.push(PartClientSrv.batchGetParts(partRows))
      if (docRows.length > 0) getData.push(DocClientSrv.getDocList(docRows))

      Promise.all(getData)
        .then(async res => {
          const data = res.map(item => {
            if (item.code == EnumRequestCode.SUCCESS) {
              return item.data
            } else {
              return []
            }
          })
          const listRows = data.flat()
          const result = await ChangeManageClientSrv.createChangeRequest(listRows, container, folder?.id)
          if (result && result.code == EnumRequestCode.SUCCESS) {
            resolve(result)
          }
        })
        .catch(err => {
          KNotification.error({
            title: '获取信息失败',
            content: '获取零部件和文档信息失败'
          })
        })
    })
  }

  /**
   * 创建变更单
   */
  public static async createChangeOrder(rows: Array<KDataGridRowData>, container: any, folder?: IKTreeNode): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      rows.forEach(item => {
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        item.rdmExtensionType = item.targetExtensionType
      })

      ChangeManageClientSrv.createChangeOrder(rows, container, rows[0].targetFolderId).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          resolve(result)
        }
      })
    })
  }

  /**
   * 基于改后创建变更活动
   */
  public static async afterUpdateCreate(rows: Array<KDataGridRowData>, container: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const data = (lodash.cloneDeep(rows) as any[]).map(item => {
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        if (!item.rdmExtensionType) {
          item.rdmExtensionType = item?.targetExtensionType
        }
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        return item
      })
      ChangeManageClientSrv.createChangeActivity('基于修改后创建变更活动', null, data, container, data[0].targetFolderId).then(
        result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            resolve(result)
          }
        }
      )
    })
  }

  /**
   * 基于改后添加至变更活动
   */
  public static async afterUpdateAddActivity(rows: Array<KDataGridRowData>, container: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }
      const partData = lodash.cloneDeep(rows) as any

      ChangeManageClientSrv.addToChangeActivity(false, partData, container).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          resolve(result)
        }
      })
    })
  }

  /**
   * 基于改前创建变更活动
   */
  public static async beforeUpdateCreate(rows: Array<KDataGridRowData>, container: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const data = (lodash.cloneDeep(rows) as any[]).map(item => {
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        if (!item.rdmExtensionType) {
          item.rdmExtensionType = item?.targetExtensionType
        }
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        return item
      })
      ChangeManageClientSrv.createChangeActivity('基于修改前创建变更活动', data, null, container, data[0].targetFolderId).then(
        result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            resolve(result)
          }
        }
      )
    })
  }

  /**
   * 基于改前添加至变更活动
   */
  public static async beforeUpdateAddActivity(rows: Array<KDataGridRowData>, container: any): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }
      const partData = lodash.cloneDeep(rows) as any

      ChangeManageClientSrv.addToChangeActivity(true, partData, container).then(result => {
        if (result && result.code == EnumRequestCode.SUCCESS) {
          resolve(result)
        }
      })
    })
  }

  /**
   * 批量转通用件
   */
  public static async convert(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        PartClientSrv.batchConvertUniversalparts(rows)
        break
      default:
        break
    }
  }

  /**
   * 加入流程
   */
  public static async addToWorkflow(rows: Array<KDataGridRowData>): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!rows || rows.length == 0) {
        KNotification.warn({
          message: '系统提示',
          description: '请至少选择一个对象'
        })
        return
      }

      const partParams = rows.map(row => {
        return {
          objID: row.targetBranchId ? row.targetBranchId : row.targetId,
          objClsCode: row.targetExtensionType,
          name: row.name,
          number: row.number,
          version: row.version,
          status: row.lifecycleStateCode,
          targetViewId: row.targetViewId
        }
      })

      WorkflowClientSrv.addObjectsToWorkflow(partParams).then(result => {
        resolve()
      })
    })
  }

  /**
   * 查看流程
   */
  public static async viewWorkFlow(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    const objClsCode = rows[0].targetExtensionType
    WorkflowClientSrv.viewWorkflow(rows[0].targetBranchId ? rows[0].targetBranchId : rows[0].targetId, objClsCode)
  }

  /**
   * 使用统计
   */
  public static async usingStatistics(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length != 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一个对象'
      })
      return
    }
    switch (rows[0].targetClass) {
      case 'Part':
        PartClientSrv.usingStatisticsManage(rows[0].targetId)
        break
      default:
        break
    }
  }

  /**
   * 基线对比
   */
  public static async baselineCompare(rows: Array<KDataGridRowData>) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    let targetId = ''
    // 判断是否是两条数据
    if (rows[1]) {
      // 判断两条数据是否是同一对象类
      if (rows[0].targetExtensionType == rows[1].targetExtensionType) {
        targetId = rows[1].targetId
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '请勿选中基线外的数据进行比较'
        })
        return
      }
      const param = {
        data: [
          {
            sort: 'DESC',
            orderBy: 'lastUpdateTime',
            filter: {
              joiner: 'and',
              conditions: [
                {
                  conditionName: 'id',
                  operator: 'in',
                  conditionValues: [rows[0].targetId, targetId]
                }
              ]
            },
            isNeedTotal: true
          },
          {
            curPage: 1,
            pageSize: 10
          }
        ]
      }
      const res = await Api.post('baseline', 'ManagedBaseline', 'page', param)
      if (res && res.code == EnumRequestCode.SUCCESS && res.data.data) {
        if (
          res.data.data[0]?.primaryObjectExt?.clazz != res.data.data[1]?.primaryObjectExt?.clazz ||
          res.data.data[0]?.primaryObjectExt?.masterId != res.data.data[1]?.primaryObjectExt?.masterId
        ) {
          KNotification.warn({
            message: '系统提示',
            description: '请选择同一个主对象下的基线进行对比'
          })
          return
        }
      } else {
        KNotification.error({
          title: '获取基线信息失败',
          content: res.message!
        })
        return
      }
    }
    switch (rows[0].targetClass) {
      case 'ManagedBaseline':
        BaselineClientSrv.baselineCompare(rows[0].targetId, targetId)
        break
      default:
        break
    }
  }

  /**
   * 创建需求变更请求
   */
  public static async createRequirementChange(rows: Array<KDataGridRowData>, folderId: string) {
    const res = await RequirementChangeClientSrv.create(rows[0], folderId)
    return res
  }
}
