import { connect, withInstall } from '@kmsoft/upf-core'
import KDocFormProcessView from './KDocFormProcess.vue'
import KDocFormProcessViewModel from './KDocFormProcessViewModel'

const KDocFormProcess = connect(KDocFormProcessView, KDocFormProcessViewModel)

export default withInstall(KDocFormProcess)
export { KDocFormProcess, KDocFormProcessView, KDocFormProcessViewModel }

// 模板生成文件
// export * from './{{folderName}}'
