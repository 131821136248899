import {
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  ObjectToolStripItem,
  toolStripItemDivider,
  EnumWorkingState,
  EnumLifecycleState,
  Api,
  ObjectClientSrv,
  clsCodeMap,
  CommonClientSrv
} from '@kmsoft/ebf-common'
import {
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  IKTreeNode,
  KDataGridRowData,
  ToolStripItemClickedEventArgs,
  PageManager
} from '@kmsoft/upf-core'
import { BaselineClientSrv } from '../../../ebf-baseline/src/client-srv'
import { ChangeManageClientSrv } from '../../../ebf-change-manage/src/client-srv'
import { DocClientSrv } from '../../../ebf-doc/src/client-srv'
import { PartClientSrv } from '../../../ebf-part/src'
import { KMetricsEditPanel } from '../../../ebf-requirement/src/pages/common-validation-metrics/metrics-edit-panel'
import { KProjectPageWrapper } from '../../../ebf-project-manage/src/components/project-page-wrapper'

export class FolderClientSrv {
  /**
   * 获取变更管理工具栏
   * @returns 变更管理工具栏
   */
  public static async getChangeManagedToolItem() {
    return [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED,
        title: '变更管理',
        icon: 'unordered-list',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE,
            title: '创建变更问题报告',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST,
            title: '创建变更申请',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER,
            title: '创建变更单',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY,
            title: '创建变更活动',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      }
    ] as Array<ObjectToolStripItem>
  }

  /**
   * 变更管理工具栏点击事件
   */
  public static async changeManagedToolStripItemClicked(
    event: ToolStripItemClickedEventArgs,
    rows: Array<KDataGridRowData>,
    folder?: IKTreeNode
  ) {
    return new Promise((resolve, reject) => {
      switch (event.name) {
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE:
          PartClientSrv.createChangeIssue(rows, folder).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST:
          PartClientSrv.createChangeRequest(rows, folder).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER:
          {
            rows.forEach(item => {
              item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
              item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
              item.className = item.targetClass
            })
            ChangeManageClientSrv.createChangeOrder(rows, rows[0].targetFolderId).then(newData => resolve(newData))
          }
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY:
          break
        default:
          resolve(void 0)
          break
      }
    })
  }

  /**
   * 获取零部件工具栏
   * @returns 零部件工具栏
   */
  public static async getPartToolItem(partSingleVisible: Boolean) {
    return [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
        title: '结构管理',
        icon: 'file-protect',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },

      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        icon: 'ObjSaveAs',
        visible: partSingleVisible,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH,
        title: '发布',
        icon: 'file-protect',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
        title: '版本操作',
        icon: 'DropObjOp',
        visible: partSingleVisible,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
            title: '检出(O)',
            icon: 'DocCheckOut',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
            title: '取消检出',
            icon: 'DocCancelCheckOut',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
            title: '检入(I)',
            icon: 'DocCheckIn',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            shortcutKey: 'alt+i'
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE,
            title: '修订',
            icon: 'tag',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT,
        title: '转通用件',
        icon: 'save',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS,
        title: '使用统计',
        icon: 'table',
        visible: partSingleVisible,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED,
        title: '变更管理',
        icon: 'unordered-list',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE,
            title: '创建变更问题报告',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST,
            title: '创建变更申请',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER,
            title: '创建变更单',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY,
            title: '创建变更活动',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      }
    ] as Array<ObjectToolStripItem>
  }

  /**
   * part工具栏点击事件
   */
  public static async partToolStripItemClicked(
    event: ToolStripItemClickedEventArgs,
    parts: Array<KDataGridRowData>,
    folder?: IKTreeNode
  ) {
    return new Promise((resolve, reject) => {
      switch (event.name) {
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
          PartClientSrv.checkInPart(parts[0].targetId).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
          PartClientSrv.checkoutPart(parts[0].targetId).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
          PartClientSrv.undoCheckoutPart(parts[0].targetId).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
          PartClientSrv.structManage(parts[0].targetId).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
          PartClientSrv.saveAsPart(parts[0].targetId, parts[0].targetFolderId).then(newData => resolve(newData))
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS:
          PartClientSrv.usingStatisticsManage(parts[0].targetId).then(newData => resolve(newData))
          break
        default:
          resolve(void 0)
          break
      }
    })
  }

  /**
   * 获取文档工具栏
   * @returns 文档工具栏
   */
  public static async getDocumentToolItem() {
    return [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
        title: '另存',
        icon: 'ObjSaveAs',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+s'
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OPERATION,
        title: '版本操作',
        icon: 'DropObjOp',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT,
            title: '检出(O)',
            icon: 'DocCheckOut',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT,
            title: '取消检出',
            icon: 'file-text',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN,
            title: '检入(I)',
            icon: 'DocCheckIn',
            visible: true,
            compType: EnumToolStripCompType.BUTTON,
            shortcutKey: 'alt+i'
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE,
            title: '修订',
            icon: 'tag',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE,
            title: '结构列表',
            icon: 'file-protect',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC,
        title: '浏览',
        icon: 'eye',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC,
        title: '下载',
        icon: 'download',
        visible: true,
        compType: EnumToolStripCompType.BUTTON
      },
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED,
        title: '变更管理',
        icon: 'unordered-list',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE,
            title: '创建变更问题报告',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST,
            title: '创建变更申请',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER,
            title: '创建变更单',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ACTIVITY,
            title: '创建变更活动',
            icon: 'plus',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      }
    ] as Array<ObjectToolStripItem>
  }

  /**
   * 按钮点击事件处理
   * @param event 按钮事件
   */
  public static async docToolStripItemClicked(event: ToolStripItemClickedEventArgs, docs: Array<KDataGridRowData>) {
    return new Promise((resolve, reject) => {
      switch (event.name) {
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT: {
          DocClientSrv.docCheckOut(docs[0].targetId).then(newData => resolve(newData))
          // todo 刷新网格
          event.itemCancelType = EnumItemClickedCancelType.CancelAll
          break
        }
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN: {
          DocClientSrv.docCheckIn(docs[0].targetId).then(newData => resolve(newData))
          event.itemCancelType = EnumItemClickedCancelType.CancelAll
          break
        }
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT: {
          DocClientSrv.docUnCheckOut(docs[0].targetId).then(newData => resolve(newData))
          event.itemCancelType = EnumItemClickedCancelType.CancelAll
          break
        }
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
          DocClientSrv.docSaveAs(docs[0].targetId, docs[0].targetFolderId).then(newData => resolve(newData))
          event.itemCancelType = EnumItemClickedCancelType.CancelAll
          break
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
          DocClientSrv.structManage(docs[0].targetId)
          break
        default:
          resolve(void 0)
          break
      }
    })
  }

  /**
   * 获取基线工具栏
   */
  static async getBaselineToolItem() {
    return [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE,
        title: '基线对比',
        icon: 'file-protect',
        visible: true,
        compType: EnumToolStripCompType.BUTTON,
        shortcutKey: 'alt+o',
        supportedObjNumMode: EnumSupportObjectNumMode.Single
      }
    ] as Array<ObjectToolStripItem>
  }

  /**
   * 按钮点击事件处理
   * @param event 按钮事件
   */
  public static async baselineToolStripItemClicked(event: ToolStripItemClickedEventArgs, sourceId: string, targetId: string) {
    return new Promise((resolve, reject) => {
      switch (event.name) {
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE: {
          BaselineClientSrv.baselineCompare(sourceId, targetId)
          break
        }
        default:
          resolve(void 0)
          break
      }
    })
  }

  /**
   * 获取CAD文档工具栏
   * @returns CAD文档工具栏
   */
  public static async getCADDocumentToolItem() {
    return [
      {
        name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_DOCUMENT_MANAGED,
        title: 'CAD文档操作',
        icon: 'unordered-list',
        visible: true,
        compType: EnumToolStripCompType.CONTEXT_MENU,
        items: [
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE,
            title: '文件替换',
            icon: 'unordered-list',
            visible: true,
            compType: EnumToolStripCompType.CONTEXT_MENU,
            items: [
              {
                name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_FILE_REPLACE_FROM_LOCAL_FILE,
                title: '从本地替换',
                icon: 'copy',
                visible: true,
                compType: EnumToolStripCompType.BUTTON
              }
            ]
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_BROWSE_DOC,
            title: '浏览',
            icon: 'eye',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_EDIT,
            title: '编辑',
            icon: 'edit',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          toolStripItemDivider(),
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_CHECK_OUT,
            title: '检出',
            icon: 'file-protect',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_DOWNLOAD_DOC,
            title: '下载',
            icon: 'download',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_HISTORY_VERSION,
            title: '历史版本管理',
            icon: 'history',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          },
          toolStripItemDivider(),
          {
            name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_STRUC_MANAGE,
            title: '查看结构',
            icon: 'align-left',
            visible: true,
            compType: EnumToolStripCompType.BUTTON
          }
        ]
      }
    ] as Array<ObjectToolStripItem>
  }

  /**
   * CAD文档操作
   * 按钮点击事件处理
   * @param event 按钮事件
   */
  public static async CADDocumentToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    return new Promise((resolve, reject) => {
      switch (event.name) {
        // 从本地替换
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_FILE_REPLACE_FROM_LOCAL_FILE: {
          break
        }
        //浏览
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_BROWSE_DOC: {
          break
        }
        //编辑
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_EDIT: {
          break
        }
        //检出
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_CHECK_OUT: {
          //如果需要刷新网格，如下
          resolve(1)
          break
        }
        //下载
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_DOWNLOAD_DOC: {
          break
        }
        //历史版本管理
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_HISTORY_VERSION: {
          break
        }
        //查看结构
        case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CAD_STRUC_MANAGE: {
          break
        }
        default:
          resolve(void 0)
          break
      }
    })
  }

  /**
   * 打开详情
   * @param params 对象数据
   */
  public static async openObjTab(params: any) {
    let domName = 'TaskInfoPanel'
    const objectInfo: Record<string, any> = {
      classCode: '',
      accessMode: 1
    }
    switch (params.rdmExtensionType) {
      case 'PMS_PLAN':
        if (params.type == 'task') {
          domName = 'TaskInfoPanel'
          objectInfo.objectId = params.id
          objectInfo.versionGroup = params.versionGroupId
        } else {
          domName = 'ApprovalWBSPanel'
          objectInfo.planIds = params.id
        }
        break
      case 'PMS_PROJECT':
        domName = 'ProjectInfoPanel'
        objectInfo.objectId = params.id
        break
      case 'PMS_ISSUE':
        domName = 'IssueManage'
        break
      case 'PMS_RISK':
        domName = 'RiskManage'
        break
    }
    if (['PMS_PLAN', 'PMS_PROJECT', 'PMS_ISSUE', 'PMS_RISK'].includes(params.rdmExtensionType)) {
      setTimeout(() => {
        CommonClientSrv.openPage(`我的项目`, KProjectPageWrapper, {
          name: domName,
          isAbsUrl: false,
          windowId: new Date().getTime(),
          objectInfo: objectInfo
        })
      }, 200)
      return
    }
    const param = {
      id: params.id,
      modelCode: params.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(params.rdmExtensionType)
    }
    ObjectClientSrv.openObj(param)
    // if (param.modelCode == 'ValidationMetrics') {
    //   const tabKey = `${param.id}#${param.modelCode}`
    //   const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
    //   const title =
    //     clsCodeMap.get(objResult.rdmExtensionType ? objResult.rdmExtensionType : objResult.targetExtensionType) +
    //     ':' +
    //     ObjectClientSrv.getObjBusinessDesc(objResult)
    //   PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
    // } else {
    //   ObjectClientSrv.openObj(param)
    // }
  }
}
