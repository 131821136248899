
import lodash from 'lodash'
import { computed, defineComponent, getCurrentInstance, ref } from 'vue'
import {
  AppContext,
  IIdentity,
  IIdentityStorage,
  request,
  UPF_IOC_KEYS,
  VuePropTypes,
  defineView,
  eventEmitter,
  EventArgs,
  utils
} from '@kmsoft/upf-core'
import { EnumLoginState } from './enums'
import { EBF_IOC_KEY, Api, EnumRequestCode, sysSrv } from '@kmsoft/ebf-common'
import KSsoLoginViewModel from './KSSOLoginViewModel'
import { symbol } from 'vue-types'
import { DpMsgHandler } from '@kmsoft/ebf-doc/src/client-agent'

/** 单点登录表单框 */
export default defineView({
  inheritAttrs: false,
  viewModel: KSsoLoginViewModel,
  props: {},
  setup(props, { vm }) {
    const loginState = ref<EnumLoginState>()
    const loginMsg = ref<string>('正在登录中......')
    /** 初始化 */
    const init = async () => {
      loginState.value = EnumLoginState.LoggingIn
      //存储登录凭证
      AppContext.current
        .getIocContainer()
        .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
        .clear()

      const setIntervalId = ref<any>()
      // 获取当前页面的 URL
      let currentUrl = window.location.href
      // 如果URL中包含两个或两个以上问号，只将第二个问号替换为&
      const questionMarkCount = (currentUrl.match(/\?/g) || []).length
      if (questionMarkCount >= 2) {
        currentUrl = currentUrl.replace(/\?/g, (match, index, original) => {
          if (index === original.indexOf('?', original.indexOf('?') + 1)) {
            return '&'
          }
          return match
        })
      }

      // 获取 'code' 参数
      const { code } = utils.getQueryObj(currentUrl, 'code') || {}
      let { agent } = utils.getQueryObj(currentUrl, 'agent') || {}
      let { objParam } = utils.getQueryObj(currentUrl, 'objParam') || {}
      const { openType } = utils.getQueryObj(currentUrl, 'openType') || {}
      const { id } = utils.getQueryObj(currentUrl, 'id') || {}
      const { rdmExtensionType } = utils.getQueryObj(currentUrl, 'rdmExtensionType') || {}

      // 如果code不存在则跳转到http://110.41.125.166/basic/mgmt/api/v1/oauth2/authorize?response_type=code&client_id=BFAchY9inzXTCIUcIr70tdIYFCEEf2HR89AwGNAbYbEkYKlX7MpUOXR_2vB9y-yO&redirect_uri=https://plm.kmsoft.com.cn&state=11
      if (!code) {
        let url = vm.getProperty('url.loginAuthorize') as string
        if (agent) {
          let baseUrl = currentUrl.split('#')[0]
          url = vm.getProperty('url.agentLoginAuthorize') as string
          baseUrl += `?agent=${agent}`
          url += `&redirect_uri=${encodeURIComponent(baseUrl)}`
        }
        window.location.href = url
        return
      }

      //根据code获取token
      // 定义要发送的数据
      const postData = {
        grantType: vm.getProperty('url.grantType'),
        clientId: vm.getProperty('url.clientId'),
        clientSecret: vm.getProperty('url.clientSecret'),
        code: code,
        redirectUri: vm.getProperty('url.redirectUri')
      }
      const res = await Api.post('common', 'SSOService', 'getUserInformation', { data: [postData] })
      if (res && res.code == EnumRequestCode.SUCCESS) {
        const identity = {
          id: res.data.userId,
          name: res.data.userName,
          code: res.data.userName,
          token: res.data.token,
          ipCenterToken: res.data.ipCenterToken,
          exprisTime: Number(res.data.tokenExpireTime) * 1000
        } as IIdentity
        //存储登录凭证
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .set(identity)
        //存储refreshToken
        if (agent) {
          await DpMsgHandler.handleAfterLogin(identity.token as string)
        } else {
          localStorage.setItem('KMPDM-refreshToken', res.data.refreshToken)
          loginMsg.value = '登录成功,正在进入......'
          setTimeout(() => {
            if (objParam || openType) {
              if (openType == 'detail') {
                objParam = btoa(`id=${id}&rdmExtensionType=${rdmExtensionType}`).replace(new RegExp('=', 'g'), '')
              }
              AppContext.current.getApplication().router.vueRouter.replace(`./?objParam=${objParam}`)
            } else {
              AppContext.current.getApplication().router.vueRouter.replace('./personalWorkspace')
            }
          }, 2000)
        }
        //开启定时任务，周期为两个小时
        setIntervalId.value = setInterval(() => {
          refreshToken()
        }, 1 * 60 * 60 * 1000 + 55 * 60 * 1000)

        //两小时后开始第一次执行
        setTimeout(() => {
          refreshToken()
        }, 1 * 60 * 60 * 1000 + 55 * 60 * 1000)
      } else {
        const url = vm.getProperty('url.loginAuthorize') as string
        window.location.replace(url)
      }
    }

    /**定时任务 */
    const refreshToken = async () => {
      //获取refreshToken
      const refreshToken = localStorage.getItem('KMPDM-refreshToken')
      const params = {
        grantType: 'refresh_token',
        clientId: vm.getProperty('url.clientId'),
        clientSecret: vm.getProperty('url.clientSecret'),
        refreshToken: refreshToken
      }
      const res = await Api.post('common', 'SSOService', 'refreshToken', { data: [params] })
      if (res && res.code == EnumRequestCode.SUCCESS) {
        const identity = {
          id: res.data.userId,
          name: res.data.userName,
          code: res.data.userName,
          token: res.data.token,
          ipCenterToken: res.data.ipCenterToken,
          exprisTime: Number(res.data.tokenExpireTime) * 1000
        } as IIdentity
        //先清理再重新存储
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .clear()
        //存储登录凭证
        AppContext.current
          .getIocContainer()
          .getBean<IIdentityStorage>(UPF_IOC_KEYS.IDENTITY_STORAGE)
          .set(identity)
        //存储refreshToken
        localStorage.setItem('KMPDM-refreshToken', res.data.refreshToken)
      } else {
        const url = vm.getProperty('url.loginAuthorize') as string
        window.location.replace(url)
      }
    }

    init()

    return {
      EnumLoginState,
      loginState,
      loginMsg
    }
  }
})
