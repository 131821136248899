import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  KDataGridRowData,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocBomCompareResultPanelPropOptions = {
  ...BaseViewPropOptions,
  /**
   * 源对象Id
   */
  source: VuePropTypes.createType<IRootTreeNodeParam>().def(),
  /**
   * 目标对象Id
   */
  target: VuePropTypes.createType<IRootTreeNodeParam>().def(),
  /**是否展开差异件 */
  expandDifferentialParts: VuePropTypes.createType<boolean>().def(),
  /**
   * 比较规则是否应用到根
   */
  applyToRoot: VuePropTypes.createType<boolean>().def(false)
}

/** 参数类型 **/
export type KDocBomCompareResultPanelPropType = ViewPropsTypeExtract<typeof KDocBomCompareResultPanelPropOptions>

/** 事件 */
export const KDocBomCompareResultPanelEventEmits = {
  ...BaseViewEventEmits,
  /** 行选中改变事件 */
  selectRowsChange: (rows: Array<KDataGridRowData>) => true,
  /**网格加载完成事件 */
  loadReady: (rows: Array<KDataGridRowData>) => true
}

/** 事件类型 **/
export type KDocBomCompareResultPanelEmitsType = ViewEmitsTypeExtract<typeof KDocBomCompareResultPanelEventEmits>

/**
 * 树根节点对象
 */
export interface IRootTreeNodeParam {
  /**
   * 根节点对象
   */
  root: ObjBusinessParam
  /**
   * 查询规则
   */
  queryRule?: IQueryRule
  applyToRoot?: boolean
  /**
   * 视图
   */
  view?: string
}

/**
 * 查询规则
 */
export interface IQueryRule {
  /**查询规则类型 */
  type: string
  /** 基线对象 */
  baseline?: ObjBusinessParam
}

/**
 * 自定义列配置
 */
export interface IColumnDefinition {
  code: string
  name: string
  value: string
}

export const initColumns = [
  {
    code: 'name',
    name: '名称'
  },
  {
    code: 'number',
    name: '编码'
  },
  {
    code: 'desc',
    name: '节点描述'
  },
  {
    code: 'className',
    name: '对象类'
  }
]
