
import { defineView, EnumDataGridRowModelType, EnumDataGridAutoSizeColumnsMode } from '@kmsoft/upf-core'
import { KRelatedRequirementsPropOptions, KRelatedRequirementsEventEmits } from './interface'
import { EnumLifecycleStateColor, EnumLifecycleStateSimple } from '@kmsoft/ebf-common'
import KRelatedRequirementsViewModel from './KRelatedRequirementsViewModel'

export default defineView({
  name: 'KRelatedRequirements',
  props: KRelatedRequirementsPropOptions,
  emits: KRelatedRequirementsEventEmits,
  viewModel: KRelatedRequirementsViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {
      EnumLifecycleStateColor,
      EnumLifecycleStateSimple,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      autosizeColumnsMode: EnumDataGridAutoSizeColumnsMode.FILL
    }
  }
})
