import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDistributionRecordCreateEmitsType, KDistributionRecordCreatePropType } from './interface'
import { ref } from 'vue'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { Api } from '@kmsoft/ebf-common'
import { KDistributionRecordDataInter } from '../interface'

/** KDistributionRecordCreate */
export default class KDistributionRecordCreateViewModel extends BaseViewModel<
  KDistributionRecordCreateEmitsType,
  KDistributionRecordCreatePropType
> {
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  formData = ref<KDistributionRecordDataInter>({
    receiver: '',
    countOfCopies: '',
    status: 'UNREAD',
    rdmExtensionType: 'DistributionRecord'
  })
  constructor(options: ViewModelOptions<KDistributionRecordCreatePropType>) {
    super(options)
  }

  viewDidMount() {}
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }
  getValue(): KDistributionRecordDataInter {
    const createObject = this.refObjectCreatePanel.value?.getValue()
    return createObject as KDistributionRecordDataInter
  }
}
