/** 权限类型 */
export enum EnumPurviewType {
  /** 对象类 */
  ObjectClass = 1,
  /** 对象 */
  ObjectOperation = 2,
  /** 功能权限 */
  Func = 3
}
/** 权限码 */
export enum EnumPurviewCode {
  /** 产品工作区-全部产品库 */
  AllProductLibraries = 'allProductLibraries',
  /** 产品工作区-新建产品库 */
  CreateProductLibrary = 'createProductLibrary',
  /** 产品工作区-编辑产品库 */
  ModifyProductLibrary = 'modifyProductLibrary',
  /** 产品工作区-删除产品库 */
  DeleteProductLibrary = 'deleteProductLibrary',
  /** 产品工作区-打开产品库 */
  OpenProductLibrary = 'openProductLibrary',

  /** 流程建模-流程模板定义 */
  DefineWorkflowTemplage = '1010601',
  /** 流程建模-新增 */
  CreateWorkflowTemplate = '1010602',
  /** 流程建模-刷新 */
  RefreshWorkflowTemplate = '1010603',
  /** 流程建模-预览 */
  PreviewWorkflowTemplate = '1010604',
  /** 流程建模-发布 */
  PublishWorkflowTemplate = '1010605',
  /** 流程建模-停用 */
  DisableWorkflowTemplate = '1010606',
  /** 流程建模-修改 */
  ModifyWorkflowTemplate = '1010607',
  /** 流程建模-删除 */
  DeleteWorkflowTemplate = '1010608',

  /** 查看（本目录） */
  View = 'view',
  /** 查看对象列表 */
  ViewList = 'viewList',
  /** 修改目录属性 */
  ModifyFolder = 'modifyFolder',
  /** 创建子目录 */
  CreateFolder = 'createFolder',
  /** 添加/删除对象链接 */
  MergeObjectLinks = 'mergeObjectLinks',
  /** 删除目录 */
  DeleteFolder = 'deleteFolder',
  /** 删除子目录 */
  DeleteChildFolders = 'deleteChildFolders',

  /** 批量创建文档 */
  BatchCreateDocuments = 'batchCreateDocuments',
  /** 添加 */
  Add = 'add',
  /** 添加至CAD工作区 */
  AddToEmpWorkspace = 'addToEmpWorkspace',
  /** 发布 */
  Publish = 'Rapidrelease',
  /** 复制 */
  Copy = 'copy',
  /** 剪切 */
  Cut = 'cut',
  /** 粘贴 */
  Paste = 'paste',
  /** 移动 */
  Move = 'mergeObjectLinks',
  /** 移除 */
  Remove = 'mergeObjectLinks',
  /** 粘贴 */
  ObjLinkRemove = '1012001',
  /** 列表刷新 */
  RefreshList = 'refreshList',
  /** 列表搜索 */
  Search = 'search',
  /** 零部件导入模板下载 */
  DownloadPartTemplate = 'downloadPartTemplate',
  /** 零部件导入 */
  ImportParts = 'importParts',
  /** 批量转通用件 */
  BatchConvertToCommonParts = 'batchConvertToCommonParts',
  /** 批量维护物料 */
  BatchManagedParts = 'batchManagedParts',
  /** 批量维护BOM属性 */
  BatchModifyBom = 'batchModifyBom',
  /** 使用统计 */
  StatUsages = 'statUsages',
  /** 创建基线 */
  CreateBaseline = 'createBaseline',
  /** 创建转阶段活动 */
  CreateTransitionStageActivities = 'createTransitionStageActivities',
  /** BOM报表 */
  BomReport = 'bomReport',
  /** 查看流程 */
  ViewWorkflow = 'ViewWorkflows',
  /** 加入流程 */
  AddWorkflow = 'AddinWorkflow',

  /** 标签页-签审历史 */
  ReviewHistory = 'reviewHistory',
  /** 标签页-结构列表 */
  UsageList = 'usageList',
  /** 标签页-描述关系 */
  DescribeRelations = 'describeRelations',
  /** 标签页-参考关系 */
  ReferenceRelations = 'referenceRelations',
  /** 标签页-描述文档 */
  DescribeDocuments = 'describeDocuments',
  /** 标签页-参考文档 */
  ReferenceDocuments = 'referenceDocuments',
  /** 标签页-附属文档 */
  AttachedDocuments = 'attachedDocuments',
  /** 标签页-历史版本 */
  VersionHistory = 'versionHistory',
  /** 标签页-变更记录 */
  ChangeHistory = 'changeHistory',
  /** 标签页-所属基线 */
  BalongToBaseline = 'balongToBaseline',
  /** 标签页-全局替代件 */
  GlobalSubstitution = 'globalSubstitution',
  /** 标签页-局部替代件 */
  PartialSubstitution = 'partialSubstitution',
  /** 标签页-使用统计 */
  StatUsageRelations = 'statUsageRelations',
  /** 标签页-可视化 */
  Visualization = 'visualization',
  /** 标签页-关系 */
  Relations = 'relations',
  /** 标签页-关联元器件 */
  EdaDocument = 'edaDocument'
}
