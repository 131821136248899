import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocFormProcessPropOptions = {
  ...BaseViewPropOptions,
  id: VuePropTypes.string()
    .setRequired()
    .def(''),
  type: VuePropTypes.string()
    .setRequired()
    .def('')
}

/** 参数类型 **/
export type KDocFormProcessPropType = ViewPropsTypeExtract<typeof KDocFormProcessPropOptions>

/** 事件 */
export const KDocFormProcessEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocFormProcessEmitsType = ViewEmitsTypeExtract<typeof KDocFormProcessEventEmits>
