import { connect, withInstall } from '@kmsoft/upf-core'
import KObjectStructFileUploadView from './KObjectStructFileUpload.vue'
import KObjectStructFileUploadViewModel from './KObjectStructFileUploadViewModel'

const KObjectStructFileUpload = connect(KObjectStructFileUploadView, KObjectStructFileUploadViewModel)

export default withInstall(KObjectStructFileUpload)
export { KObjectStructFileUpload, KObjectStructFileUploadView, KObjectStructFileUploadViewModel }

// 模板生成文件
// export * from './{{folderName}}'
