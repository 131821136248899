import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam } from '../../client-srv'

/** 参数 **/
export const KOfficePreviewPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KOfficePreviewPropType = ViewPropsTypeExtract<typeof KOfficePreviewPropOptions>

/** 事件 */
export const KOfficePreviewEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KOfficePreviewEmitsType = ViewEmitsTypeExtract<typeof KOfficePreviewEventEmits>
