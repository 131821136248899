import {
  Api,
  defineToolStripData,
  EBF_IOC_KEY,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  TREE_VIEW_ROOT_NODE_KEY
} from '@kmsoft/ebf-common'
import {
  BaseViewModel,
  EnumTreeViewNodeRefreshType,
  KNotification,
  KTreeViewViewModel,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { AppContext } from '@kmsoft/upf-core/src'
import lodash from 'lodash'
import { ref } from 'vue'
import { Doc } from '../../beans'
import { DocClientSrv } from '../../client-srv'
import { KDocStructureTreeEmitsType, KDocStructureTreePropType } from './interface'
import { DocStructureTreeNode } from './node/DocStructureTreeNode'

/** KDocStructureTree */
export default class KDocStructureTreeViewModel extends BaseViewModel<KDocStructureTreeEmitsType, KDocStructureTreePropType> {
  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /**数控件引入 */
  refTreeView = ref<KTreeViewViewModel>()
  /** 选择严格受控,待确定 */
  notCheckStrictly = ref<boolean>(true)
  /** 是否是多选 */
  isMultipleSelection = ref<boolean>(false)
  /** 右键菜单 */
  // toolStripItems = defineToolStripData(getToolStrip())
  toolStripItems = defineToolStripData([])

  /** 根节点 */
  rootNode: DocStructureTreeNode
  /** 根节点 */
  highlightNodes: Array<DocStructureTreeNode> = []
  /** 排序方式 */
  sortMode: string = ''
  constructor(options: ViewModelOptions<KDocStructureTreePropType>) {
    super(options)
    this.isMultipleSelection.value = options.props.isMultipleSelection
  }

  get treeView() {
    return this.refTreeView.value
  }
  viewDidMount() {
    AppContext.current
      .getIocContainer()
      .registerSingleton(EBF_IOC_KEY.SET_NODE_HIGH_LIGHT, { setSelectNode: this.setSelectNodes.bind(this) })
  }

  setSelectNodes(event: any) {
    if (event.environment != 'Document') return
    const path = event.path
    const ids = []
    const rootNode = this.refTreeView.value?.getRootNodes()[0] as DocStructureTreeNode
    for (const id_index of path) {
      if (id_index == 'root') {
        ids.push(rootNode!.id)
      } else {
        const id = id_index.split('_')[0]
        ids.push(id)
      }
    }
    this.refTreeView.value?.expandByPath(rootNode!, ids.join(','), true)
  }
  /**工具栏事件 */
  onToolStripClicked(event: ToolStripItemClickedEventArgs) {
    console.log('onToolStripItemClicked', event.name)
    const eventName = event.name
    const objParam = ref<ObjBusinessParam>()
    objParam.value = {
      id: event.attachParams?.node.docObjParam.id,
      iteratorId: event.attachParams?.node.docObjParam.iteration,
      modelCode: event.attachParams?.node.docObjParam.modelCode,
      modelGroup: event.attachParams?.node.docObjParam.modelGroup
    }
    switch (eventName) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        this.doBrowse(objParam?.value!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        this.doDownload(objParam?.value!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        this.doCheckout(objParam?.value!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        this.doCheckin(objParam?.value!)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        this.doUnCheckout(objParam?.value!)
        break
      default:
        break
    }
  }

  /**
   * 执行下载操作
   * @param objParam 文档标识
   */
  doDownload(objParam: ObjBusinessParam) {
    const doc = Object.assign(new Doc(), objParam)
    doc.checkoutPath = `C:\\KMSOFT\\temp\\Documents`
    DocClientSrv.downloadDoc(doc)
  }

  /**
   * 执行浏览操作
   * @param objParam 文档标识
   */
  doBrowse(objParam: ObjBusinessParam) {
    const doc = Object.assign(new Doc(), objParam)
    DocClientSrv.browseDoc(doc)
  }

  /**
   * 执行检出操作
   */
  async doCheckout(objParam: ObjBusinessParam) {
    await DocClientSrv.docCheckOut(objParam.id)
    return
  }

  /**
   * 执行撤销检出
   */
  async doUnCheckout(objParam: ObjBusinessParam) {
    const result = await Api.post('doc', 'Document', 'undoCheckout', {
      data: [objParam]
    })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '操作成功'
      })
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  /**
   * 执行检入
   */
  async doCheckin(objParam: ObjBusinessParam) {
    const reqParam = { data: [{ id: objParam.id }] }
    const result = await Api.post('doc', 'Document', 'checkin', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      KNotification.success({
        title: '系统提示',
        content: '检入成功'
      })
      return
    }
    KNotification.error({
      title: '系统错误',
      content: result.message
    })
    return
  }

  // /**
  //  * 容器点击事件
  //  * @param e
  //  */
  // onContainerClick(e: MouseEvent) {
  //   // 按下指定按键才能打开
  //   if (e.shiftKey && e.ctrlKey) {
  //     this.refToolStrip.value?.openToolStripManage()
  //   }
  // }
  /**
   * 节点选择后事件
   * @param event
   */
  async onAfterSelect(event: TreeViewSelectEventArgs<DocStructureTreeNode>) {
    if (!event.node) {
      return
    }

    // 发出事件
    this.emit('afterSelect', event)
  }

  /**重载节点 */
  async refreshNode(node: DocStructureTreeNode, refreshType: EnumTreeViewNodeRefreshType, properties?: Record<string, any>) {
    //如果刷新根节点,直接刷新整个树
    if (node.id == TREE_VIEW_ROOT_NODE_KEY) {
      await this.refTreeView.value?.refresh(undefined, true)
      return
    }
    //刷新节点
    await this.refTreeView.value?.refreshNode(node, refreshType)

    //拼接节点路径
    if (refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD || refreshType == EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF) {
      const nodePath = node.id + (properties ? ',' + properties['id'] : '')
      await this.refTreeView.value?.expandByPath(node, nodePath)
      return
    }

    if (refreshType == EnumTreeViewNodeRefreshType.NODE_PARENT) {
      if (node.parent) {
        const nodePath = node.parent.id + ',' + node.id
        await this.refTreeView.value?.expandByPath(node.parent!, nodePath)
      }
    }
  }

  /**
   * 刷新节点，自己和子节点
   * @param node
   */
  async refreshStructureNode(node: DocStructureTreeNode) {
    await this.refreshNode(node, EnumTreeViewNodeRefreshType.NODE_CHILD_AND_SELF)
  }

  /** 刷新树 */
  async refreshTree() {
    // 刷新树
    await this.refTreeView.value?.refresh(undefined, true)
    // 刷新后
    this.emit('refresh')
  }

  /** 清除高亮节点 */
  clearHighlight(): void {
    if (this.highlightNodes.length == 0) {
      return
    }
    this.highlightNodes.forEach(node => (node.highLight = false))
  }
  /**
   * 根据路径高亮
   * @param path
   */
  async setHighlightByPath(path: string) {
    /** 展开节点 */
    const resultNode = (await this.refTreeView.value?.expandByPath(this.rootNode, path, false)) as
      | DocStructureTreeNode
      | undefined

    if (resultNode) {
      this.setHighlightNodes(resultNode, true)
    }
  }

  /**
   * 设置高亮节点
   * @param nodes
   * @returns
   */
  setHighlightNodes(nodes: DocStructureTreeNode | Array<DocStructureTreeNode>, isClear: boolean): void {
    // 清除所有高亮节点
    if (isClear) {
      this.clearHighlight()
    }

    if (!nodes) {
      return
    }

    if (!lodash.isArray(nodes)) {
      nodes = [nodes]
    }

    const datas = nodes.map(a => {
      return {
        key: a.key,
        highLight: true
      }
    })

    // 标记高亮
    this.refTreeView.value?.modifyNode(datas)

    // 滚动到节点
    this.refTreeView.value?.scrollTo(datas[0].key, 'auto')

    this.highlightNodes = nodes
  }
  /**是否多选 */
  getIsMultipleSelection(): boolean {
    return this.isMultipleSelection.value
  }
  /** 获取级联状态 */
  getNotCheckStrictly() {
    return this.notCheckStrictly.value
  }

  /**获取排序方式 */
  getSortMode(): string {
    return this.sortMode
  }
  /**加载跟数据 */
  async onLoadData() {
    const reqParam = {
      data: [{ id: this.props.objParams?.id }]
    }
    const result = await Api.post('doc', 'Document', 'getDocTreeRootNode', reqParam)
    const nodeData = result.data!
    let fileId = ''
    let filename = ''
    if (nodeData.primary) {
      fileId = nodeData.primary[0]?.id
      filename = nodeData.primary[0]?.name
    }

    this.rootNode = new DocStructureTreeNode()
    this.rootNode.key = TREE_VIEW_ROOT_NODE_KEY
    this.rootNode.id = nodeData.id
    this.rootNode.iconType = 'setting'
    this.rootNode.docObjParam = {
      modelCode: nodeData.rdmExtensionType,
      id: nodeData.id,
      masterId: nodeData.master.id,
      modelGroup: '',
      name: nodeData.name,
      code: nodeData.master.number,
      version: nodeData.version,
      iteration: nodeData.iteration,
      checkout: nodeData.workingCopy,
      checkoutPath: nodeData.checkoutPath,
      lifecycleStateCode: nodeData.lifecycleStateCode,
      orderId: 0,
      selected: '',
      file: {
        id: fileId,
        md5: '',
        originalFileName: filename,
        size: '',
        location: '',
        displayLocation: '',
        uploadStartTime: '',
        uploadEndTime: ''
      },
      kiaguid: nodeData.kiaguid,
      documentType: nodeData.documentType,
      number: nodeData.master?.number,
      workingState: nodeData.workingState
    }
    this.rootNode.parentDocObjParam = this.rootNode.docObjParam // 根节点的父就是自己
    this.rootNode.name = result.data?.name!
    this.rootNode.leaf = !result.data!.hasChildren
    this.rootNode.index = result.data.orderId
    return [this.rootNode]
  }
  // /**获取当前选中节点--多选 */
  // getCheckedNodes() {
  //   return this.refTreeView.value?.getCheckedNodes()
  // }

  // 递归获取未展开节点集合
  getAllUnExpandNodes(node: DocStructureTreeNode): Array<DocStructureTreeNode> {
    const list: Array<DocStructureTreeNode> = []
    const queue: Array<DocStructureTreeNode> = []
    //初始化队列
    queue.push(node)
    while (queue.length) {
      //删除第一个循环项
      const curNode = queue.shift()
      //如果符合条件
      if (curNode && !curNode.leaf && !curNode.children) {
        list.push(curNode)
      }
      //如果有子项，加入循环队列
      if (curNode && curNode.children) {
        queue.push(...(curNode.children as Array<DocStructureTreeNode>))
      }
    }
    return list
  }
}
