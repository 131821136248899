import { ObjBusinessParam } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KDocEditPanelPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  onDataUpdated: VuePropTypes.func().def()
}

/**
 * 工作状态
 * @description 工作状态
 */
export enum EnumWorkState {
  /**检入 */
  CHECKED_IN = 'CHECKED_IN',
  /** 检出 */
  CHECKED_OUT = 'CHECKED_OUT',
  /** 工作副本 */
  INWORK = 'INWORK'
}

/** 参数类型 **/
export type KDocEditPanelPropType = ViewPropsTypeExtract<typeof KDocEditPanelPropOptions>

/** 事件 */
export const KDocEditPanelEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KDocEditPanelEmitsType = ViewEmitsTypeExtract<typeof KDocEditPanelEventEmits>
