import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1c50fd59"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "k-object-property-editor" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!
  const _component_k_date_time = _resolveComponent("k-date-time")!
  const _component_k_input_number = _resolveComponent("k-input-number")!
  const _component_k_user_selector = _resolveComponent("k-user-selector")!
  const _component_k_input = _resolveComponent("k-input")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.componentType === _ctx.EnumPropertyType.DROPDOWN)
      ? (_openBlock(), _createBlock(_component_k_select, {
          key: 0,
          value: _ctx.localValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.localValue) = $event)),
          options: _ctx.options,
          "show-search": "",
          placeholder: _ctx.placeholder,
          style: {"width":"100%"}
        }, null, 8, ["value", "options", "placeholder"]))
      : (_ctx.componentType === _ctx.EnumPropertyType.DATETIME)
        ? (_openBlock(), _createBlock(_component_k_date_time, {
            key: 1,
            value: _ctx.localValue,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.localValue) = $event)),
            format: "YYYY-MM-DD HH:mm:ss",
            placeholder: _ctx.placeholder
          }, null, 8, ["value", "placeholder"]))
        : (_ctx.componentType === _ctx.EnumPropertyType.NUMBER)
          ? (_openBlock(), _createBlock(_component_k_input_number, {
              key: 2,
              class: "number-input",
              value: _ctx.localValue,
              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.localValue) = $event))
            }, null, 8, ["value"]))
          : (_ctx.userSelecter)
            ? (_openBlock(), _createBlock(_component_k_user_selector, {
                key: 3,
                value: _ctx.localValue,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.localValue) = $event))
              }, null, 8, ["value"]))
            : (_openBlock(), _createBlock(_component_k_input, {
                key: 4,
                class: "text-input",
                value: _ctx.localValue,
                "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.localValue) = $event)),
                placeholder: _ctx.placeholder
              }, null, 8, ["value", "placeholder"]))
  ]))
}