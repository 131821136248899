import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentEditor = _resolveComponent("DocumentEditor")!

  return (_openBlock(), _createBlock(_component_DocumentEditor, {
    id: "docEditor",
    documentServerUrl: _ctx.$vm.documentServerUrl,
    config: _ctx.$vm.config
  }, null, 8, ["documentServerUrl", "config"]))
}