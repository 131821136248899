import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, withCtx as _withCtx, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"8px 0px","width":"100%","height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { style: {"margin-left":"5px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tree_view = _resolveComponent("k-tree-view")!
  const _component_k_panel = _resolveComponent("k-panel")!
  const _component_k_split_pane = _resolveComponent("k-split-pane")!
  const _component_k_object_tool_strip = _resolveComponent("k-object-tool-strip")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_date_time_column = _resolveComponent("k-data-grid-date-time-column")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_split_container = _resolveComponent("k-split-container")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_split_container, null, {
        default: _withCtx(() => [
          _createVNode(_component_k_split_pane, {
            size: 15,
            minSize: 15,
            maxSize: 50
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_panel, null, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_k_tree_view, {
                      ref: _ctx.$vm.refTree,
                      "default-expanded-level": 0,
                      unselectablePredicate: (node)=>node!.id=='root',
                      autoSelectFirstNode: true,
                      loadData: _ctx.$vm.loadTreeData,
                      onAfterSelect: _cache[0] || (_cache[0] = (event) => _ctx.$vm.setSelectNode(event))
                    }, null, 8, ["unselectablePredicate", "loadData"])
                  ])), [
                    [_directive_focus]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_k_split_pane, null, {
            default: _withCtx(() => [
              _createVNode(_component_k_split_container, { horizontal: true }, {
                default: _withCtx(() => [
                  _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_k_panel, null, {
                      header: _withCtx(() => [
                        _createVNode(_component_k_object_tool_strip, {
                          ref: "refToolStrip",
                          items: _ctx.$vm.toolStripItems,
                          onItemClicked: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onToolStripItemClicked(event))
                        }, null, 8, ["items"])
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_k_data_grid, {
                          rowModelType: _ctx.rowModelType,
                          class: "workflow-grid",
                          rowKey: "taskId",
                          name: "workflowGrid",
                          ref: _ctx.$vm.refWorkflowGrid,
                          loadData: _ctx.$vm.loadData,
                          pagination: _ctx.pagination
                        }, {
                          columns: _withCtx(() => [
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "processName",
                              name: "processName",
                              width: "500",
                              dataPropertyName: "processName",
                              headerText: "名称",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "processName",
                              name: "processName",
                              dataPropertyName: "processCode",
                              width: "160",
                              headerText: "编码",
                              align: "left"
                            }, {
                              default: _withCtx(({ row }) => [
                                _createElementVNode("a", {
                                  href: "javascript:void(0)",
                                  onClick: () => _ctx.$vm.openWorkflowPage(row)
                                }, [
                                  _createElementVNode("span", _hoisted_4, _toDisplayString(row.processCode), 1)
                                ], 8, _hoisted_3)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "taskName",
                              name: "taskName",
                              dataPropertyName: "taskName",
                              width: "153",
                              headerText: "当前步骤",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_template_column, {
                              id: "taskContentMasterWorkObjType",
                              name: "taskContentMasterWorkObjType",
                              dataPropertyName: "workObjInfo.rdmExtensionType",
                              headerText: "实体类型",
                              width: "150",
                              align: "left"
                            }, {
                              default: _withCtx(({ row }) => [
                                _createTextVNode(_toDisplayString(row.workObjInfo ? _ctx.clsCodeMap.get(row.workObjInfo.rdmExtensionType) + _ctx.$vm.getView(row.workObjInfo) : ''), 1)
                              ]),
                              _: 1
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "taskContentMasterWorkObjName",
                              name: "taskContentMasterWorkObjName",
                              dataPropertyName: "workObjInfo.name",
                              width: "153",
                              headerText: "主对象名称",
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_text_box_column, {
                              id: "taskContentMasterWorkObjCode",
                              name: "taskContentMasterWorkObjCode",
                              dataPropertyName: "workObjInfo.code",
                              width: "153",
                              headerText: "主对象编码",
                              align: "left"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "startTime",
                              name: "startTime",
                              width: "160",
                              dataPropertyName: "startTime",
                              headerText: "启动时间",
                              align: "center"
                            }),
                            _createVNode(_component_k_data_grid_date_time_column, {
                              id: "createTime",
                              name: "createTime",
                              width: "160",
                              dataPropertyName: "createTime",
                              headerText: "创建时间",
                              align: "center"
                            })
                          ]),
                          _: 1
                        }, 8, ["rowModelType", "loadData", "pagination"])
                      ]),
                      _: 1
                    })
                  ])), [
                    [_directive_focus]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}