import { resolveComponent as _resolveComponent, createVNode as _createVNode, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "k-model-browser" }
const _hoisted_2 = { class: "file-box" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_vue_browser = _resolveComponent("k-vue-browser")!
  const _component_k_loading = _resolveComponent("k-loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_vue_browser, {
        ref: "refBrowser",
        fileUrl: _ctx.$vm.fileUrl,
        fileType: _ctx.$vm.fileType,
        structData: _ctx.$vm.structData,
        onSaveComment: _ctx.$vm.onSaveComment,
        environment: _ctx.environment,
        container: _ctx.container,
        showRemark: _ctx.$vm.props.showMark,
        showTree: _ctx.$vm.props.showTree,
        onLoaded: _ctx.$vm.loaded
      }, null, 8, ["fileUrl", "fileType", "structData", "onSaveComment", "environment", "container", "showRemark", "showTree", "onLoaded"])
    ], 512), [
      [_vShow, !_ctx.$vm.loading]
    ]),
    (_ctx.$vm.loading)
      ? (_openBlock(), _createBlock(_component_k_loading, {
          key: 0,
          value: _ctx.$vm.rate,
          desc: _ctx.$vm.rate < 100 ? '加载中...' : '加载完成',
          type: 2
        }, null, 8, ["value", "desc"]))
      : _createCommentVNode("", true)
  ]))
}