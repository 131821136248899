
import { defineView } from '@kmsoft/upf-core'
import { RangePicker } from 'ant-design-vue'
import { KrequirementKanbanPropOptions, KrequirementKanbanEventEmits } from './interface'
import KrequirementKanbanViewModel from './KrequirementKanbanViewModel'
import KChangeStatistics from './change-statistics'

export default defineView({
  name: 'KrequirementKanban',
  props: KrequirementKanbanPropOptions,
  emits: KrequirementKanbanEventEmits,
  components: { KChangeStatistics, ARangePicker: RangePicker },
  viewModel: KrequirementKanbanViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
