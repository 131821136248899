import { connect, withInstall } from '@kmsoft/upf-core'
import KModelBrowserView from './KModelBrowser.vue'
import KModelBrowserViewModel from './KModelBrowserViewModel'
import { NodesColorParams } from './interface'

const KModelBrowser = connect(KModelBrowserView, KModelBrowserViewModel)

export default withInstall(KModelBrowser)
export { KModelBrowser, KModelBrowserView, KModelBrowserViewModel, NodesColorParams }

// 模板生成文件
// export * from './{{folderName}}'
