import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-cae99bfc"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "border1 h100p"
}
const _hoisted_2 = { style: {"height":"100%"} }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { key: 1 }
const _hoisted_8 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_tool_strip_button_item = _resolveComponent("k-tool-strip-button-item")!
  const _component_k_tool_strip = _resolveComponent("k-tool-strip")!
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_data_grid_combo_box_column = _resolveComponent("k-data-grid-combo-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, { class: "panel-content" }, {
    header: _withCtx(() => [
      (!_ctx.readonly)
        ? (_openBlock(), _createElementBlock("section", _hoisted_1, [
            _createVNode(_component_k_tool_strip, null, {
              default: _withCtx(() => [
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "添加",
                  icon: "plus",
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.addData()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "移除",
                  icon: "minus",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.removeData()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "刷新",
                  icon: "sync",
                  onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.refresh()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "相关零部件",
                  icon: "pic-left",
                  onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.relatedPart()))
                }),
                _createVNode(_component_k_tool_strip_button_item, {
                  title: "相关文档",
                  icon: "pic-right",
                  onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.isDesigner ? null : _ctx.$vm.relatedDocument()))
                })
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true)
    ]),
    default: _withCtx(() => [
      _createElementVNode("section", _hoisted_2, [
        _createVNode(_component_k_data_grid, {
          dataSource: _ctx.$vm.gridData,
          ref: "refDataGrid",
          onCellEditingStarted: _ctx.$vm.cellEditingStarted,
          onCellEditingStopped: _ctx.$vm.cellEditingStopped,
          onReady: _ctx.$vm.onReady,
          disabled: _ctx.readonly
        }, {
          columns: _withCtx(() => [
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "编码",
              dataPropertyName: "number",
              align: "center",
              readonly: "true",
              width: "150"
            }, {
              default: _withCtx(({ row }) => [
                _createElementVNode("span", null, [
                  _createElementVNode("a", {
                    href: "javascript:void(0)",
                    onClick: ($event: any) => (_ctx.$vm.openObjTab(row))
                  }, _toDisplayString(row.number), 9, _hoisted_3)
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "名称",
              name: "name",
              dataPropertyName: "name",
              align: "center",
              readonly: "true",
              width: "150"
            }),
            (!_ctx.isAfterObject)
              ? (_openBlock(), _createBlock(_component_k_data_grid_combo_box_column, {
                  key: 0,
                  id: "inventoryDisposition",
                  name: "inventoryDisposition",
                  dataPropertyName: "inventoryDisposition",
                  headerText: "处理方式",
                  options: _ctx.$vm.handingMethodOptions,
                  width: "150"
                }, null, 8, ["options"]))
              : _createCommentVNode("", true),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "阶段",
              dataPropertyName: "phase",
              align: "center",
              readonly: "true",
              width: "80"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.EnumPhaseState[cellValue]), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_5, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "实体类型",
              dataPropertyName: "rdmExtensionType",
              align: "center",
              readonly: "true",
              width: "150"
            }, {
              default: _withCtx(({ cellValue, row}) => [
                _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue) + _ctx.$vm.getView(row)), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "版本",
              dataPropertyName: "version",
              align: "center",
              readonly: "true",
              width: "80"
            }, {
              default: _withCtx(({ row }) => [
                (row.version || row.iteration)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(row.version) + "." + _toDisplayString(row.iteration), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_7, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "状态",
              dataPropertyName: "lifecycleStateCode",
              align: "center",
              readonly: "true",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue }) => [
                cellValue
                  ? (_openBlock(), _createBlock(_component_k_tag, {
                      key: 0,
                      color: _ctx.EnumLifecycleStateColor[cellValue]
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                      ]),
                      _: 2
                    }, 1032, ["color"]))
                  : (_openBlock(), _createElementBlock("span", _hoisted_8, "--"))
              ]),
              _: 1
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "检入标记",
              dataPropertyName: "checkInState",
              align: "center",
              readonly: "true",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_text_box_column, {
              headerText: "创建时间",
              dataPropertyName: "createTime",
              align: "center",
              readonly: "true",
              width: "auto"
            }),
            _createVNode(_component_k_data_grid_template_column, {
              headerText: "创建者",
              dataPropertyName: "creator",
              align: "center",
              readonly: "true",
              width: "auto"
            }, {
              default: _withCtx(({ cellValue}) => [
                _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["dataSource", "onCellEditingStarted", "onCellEditingStopped", "onReady", "disabled"])
      ])
    ]),
    _: 1
  }))
}