import { connect, withInstall } from '@kmsoft/upf-core'
import KRelatedRequirementsView from './KRelatedRequirements.vue'
import KRelatedRequirementsViewModel from './KRelatedRequirementsViewModel'

const KRelatedRequirements = connect(KRelatedRequirementsView, KRelatedRequirementsViewModel)

export default withInstall(KRelatedRequirements)
export { KRelatedRequirements, KRelatedRequirementsView, KRelatedRequirementsViewModel }

// 模板生成文件
// export * from './{{folderName}}'
