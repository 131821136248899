import { connect, withInstall } from '@kmsoft/upf-core'
import KOfficePreviewView from './KOfficePreview.vue'
import KOfficePreviewViewModel from './KOfficePreviewViewModel'

const KOfficePreview = connect(KOfficePreviewView, KOfficePreviewViewModel)

export default withInstall(KOfficePreview)
export { KOfficePreview, KOfficePreviewView, KOfficePreviewViewModel }

// 模板生成文件
// export * from './{{folderName}}'
