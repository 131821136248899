import { ObjBusinessParam, ObjectPanelAttachParams } from '@kmsoft/ebf-common'
import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KWorkflowSigningHistoryPropOptions = {
  ...BaseViewPropOptions,
  /** 对象参数 */
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def(),
  /** 附属参数 */
  attachParams: VuePropTypes.createType<ObjectPanelAttachParams>().def({}),
  refreshTip: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KWorkflowSigningHistoryPropType = ViewPropsTypeExtract<typeof KWorkflowSigningHistoryPropOptions>

/** 事件 */
export const KWorkflowSigningHistoryEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KWorkflowSigningHistoryEmitsType = ViewEmitsTypeExtract<typeof KWorkflowSigningHistoryEventEmits>
