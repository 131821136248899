import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-21fc6130"),n=n(),_popScopeId(),n)
const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = { class: "layout-content-wrapper" }
const _hoisted_3 = { class: "full chang-obj-manage" }
const _hoisted_4 = { class: "full chang-obj-manage" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!
  const _component_k_collapse_panel = _resolveComponent("k-collapse-panel")!
  const _component_k_collapse = _resolveComponent("k-collapse")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_collapse, {
        activeKey: _ctx.$vm.activeKey,
        "onUpdate:activeKey": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.activeKey) = $event)),
        class: "base-content right-content",
        ghost: ""
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_collapse_panel, {
            id: "change-issue",
            key: "1",
            header: "变更请求-问题报告",
            forceRender: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_3, [
                _createVNode(_component_k_object_class_grid, {
                  style: {"height":"300px"},
                  ref: "refChangeIssueGrid",
                  modelCode: "ChangeRequest",
                  modelGroup: "change",
                  loadData: _ctx.$vm.loadChangeIssueData,
                  schemaType: "ChangeRequest_relationship"
                }, null, 8, ["loadData"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_k_collapse_panel, {
            id: "change-order",
            key: "2",
            header: "变更请求-变更单",
            forceRender: ""
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_4, [
                _createVNode(_component_k_object_class_grid, {
                  style: {"height":"300px"},
                  ref: "refChangeOrderGrid",
                  modelCode: "ChangeRequest",
                  modelGroup: "change",
                  loadData: _ctx.$vm.loadChangeOrderData,
                  schemaType: "ChangeRequest_relationship"
                }, null, 8, ["loadData"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["activeKey"])
    ])
  ]))
}