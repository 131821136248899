import { Api, ObjBusinessParam } from '@kmsoft/ebf-common'
import { KTreeNode } from '@kmsoft/upf-core'
import { EnumPartNodeChildExpandMode } from '../../../client-srv'
import { DocNode, DocStructureQueryRule } from '../interface'

export class DocStructureTreeNode extends KTreeNode {
  /** 文档对象参数 */
  docObjParam: DocNode
  /** 父文档对象参数 */
  parentDocObjParam: ObjBusinessParam
  /** 节点深度 */
  nodeDepth: number = 0
  /** 展开方式 */
  currentExtendMode?: EnumPartNodeChildExpandMode
  /** 查询条件 */
  queryRule: DocStructureQueryRule
  /** 视图名称 */
  viewName: string
  /** 是否是基线 */
  isBaseLine: boolean
  /** 位置号 */
  index: number

  constructor() {
    super()
  }
  /** 深度 */
  get depth(): number {
    this.nodeDepth = 0
    return this.getDepth(this)
  }

  /** 节点路径 */
  get path(): string {
    return this.getPath(this)
  }

  /**
   * 获取深度
   * @param node
   * @returns
   */
  private getDepth(node: KTreeNode | undefined): number {
    this.nodeDepth = this.nodeDepth + 1
    if (node?.parent && node?.parent.checked) {
      this.getDepth(node.parent)
    }
    return this.nodeDepth
  }

  private getPath(node: KTreeNode): string {
    let parentPath = ''
    if (node.parent && node.parent) {
      parentPath = this.getPath(node.parent) + ','
    }
    return parentPath + node.id
  }
  /**加载子节点 */
  async populateChildren(populateType?: number): Promise<Array<KTreeNode>> {
    /** 获取子节点 */
    // const currentExtendMode = DocClientSrv.getPartNodeChildExpandMode(this.viewName, this.isBaseLine)

    /*     const params = {
      object: {
        parentId: {
          modelCode: this.docObjParam.modelCode,
          id: this.docObjParam.id
        },
        queryRule: this.queryRule
      }
    } */
    const params = {
      data: [{ id: this.docObjParam.id }, 'LATEST']
    }

    // /**查询子节点 */
    const result = await Api.post('doc', 'Document', 'listDocChildrenNode', params) //await doc.listDocChildren(params)
    const nodes = result.data!.map((item: any) => {
      let fileId = ''
      let filename = ''
      if (item.primary) {
        fileId = item.primary[0]?.id
        filename = item.primary[0]?.name
      }
      const childNode = new DocStructureTreeNode()
      childNode.leaf = !item.hasChildren
      childNode.docObjParam = {
        modelCode: item.rdmExtensionType,
        id: item.id,
        masterId: item.master.id,
        modelGroup: '',
        name: item.name,
        code: item.master.number,
        version: item.version,
        iteration: item.iteration,
        checkout: item.workingCopy,
        checkoutPath: item.checkoutPath,
        orderId: 0,
        selected: '',
        file: {
          id: fileId,
          md5: '',
          originalFileName: filename,
          size: '',
          location: '',
          displayLocation: '',
          uploadStartTime: '',
          uploadEndTime: ''
        },
        documentType: item.documentType,
        lifecycleStateCode: item.lifecycleStateCode,
        workingState: item.workingState,
        kiaguid: item.kiaguid
      }
      childNode.parentDocObjParam = this.docObjParam
      childNode.id = item.id
      childNode.name = item.name
      childNode.iconType = 'setting'
      childNode.index = item.orderId
      return childNode
    })
    return nodes || []
  }
  /**
   * 通过一个路径来populate child
   * @param path 路径
   * @returns 得到的节点
   */
  async populateByPath(path: string[]): Promise<undefined | DocStructureTreeNode> {
    if (path.length === 0) {
      return this
    } else if (this.leaf) {
      console.error('reach leaf buf remain child')
      return
    } else {
      if (!this.children) {
        if (this.host) {
          const children = await this.populateChildren()
          this.host.insertNode(this.key, children)
        }
      }
      if (this.children) {
        const children = this.children as Array<DocStructureTreeNode>
        const nextPath = path.shift()
        const next = children.find(child => child.id === nextPath)
        if (next) {
          return await next.populateByPath(path)
        }
        console.error(`fail to populate child on object ${this.id}`)
      } else {
        console.error('reach leaf buf remain child')
      }
    }
  }
}
