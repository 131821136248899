// import {
//   EnumDialogResult,
//   EnumDialogSize,
//   KDialog,
//   ObjectCreateDialogProps,
//   ObjectQueryDialogProps,
//   SuccessCallBack,
//   commonClientSrv,
//   objectClientSrv
// } from '@kmplm/ecol-base'
// import { EnumObjGridRule, ObjBusinessParam, ServiceAttachParams, projectSrv } from '@kmplm/ecol-data-srv'
import lodash from 'lodash'
import { ObjPlanParam, PMSCreateObjectResult } from '../components/types'

// import * as DataSrv from '@kmplm/ecol-data-srv'
import { EnumDialogResult, EnumDialogSize } from '@kmsoft/upf-core'
import {
  EnumObjGridRule,
  ObjBusinessParam,
  ObjectClientSrv,
  ObjectCreateDialogParams,
  ObjectCreateDynamicPanelParams,
  ObjectSelectDialogParam,
  ServiceAttachParams
} from '@kmsoft/ebf-common'

/**
 * 交付物客户端服务
 */
class DeliverableClientSrv {
  /**服务标识 */
  Id = 'Project'
  /**主对象参数 */
  ExtendParam_Deliverable = 'Deliverable'
  /**生成服务端扩展参数集。
   * @param params 服务端扩展参数。
   * @returns 服务端扩展参数集。*/
  generateParams(param: ObjPlanParam): ServiceAttachParams {
    return {
      serviceIdentity: this.Id,
      attachParam: { [this.Id + this.ExtendParam_Deliverable]: param }
    }
  }

  /**
   * 打开创建交付物对话框
   * @param planObjParam 主对象对象参数
   */
  onOpenCreateDeliverableDialog = async (
    mainObjParam: ObjBusinessParam,
    folderId: string,
    showObjClsId?: string
  ): Promise<PMSCreateObjectResult | undefined> => {
    const dialogProps: ObjectCreateDynamicPanelParams = {
      showObjClsCodes: showObjClsId == '' ? [] : [showObjClsId!],
      folderId: folderId
    }
    const result = await ObjectClientSrv.openCreateDialog(dialogProps)

    if (!result.success) {
      return
    }
    const params = {
      objClsID: result.objBusiness![0].rdmExtensionType,
      objID: result.objBusiness![0].id
    }
    return { objBusiness: [(params as unknown) as ObjBusinessParam] }
  }

  /**
   * 添加交付物
   * @param objClsID
   * @param objID
   * @param showObjClsId 显示的树节点
   * @param callBack
   */
  addDeliverable(mainObjParam: ObjBusinessParam, showObjClsId: string): Promise<PMSCreateObjectResult> {
    //1.定义弹出查找对象对话框属性
    //对象类树属性
    return new Promise<PMSCreateObjectResult>((resolve, reject) => {
      const objectClassTreeProps = {
        defaultSelectClsCode: showObjClsId,
        // objClassTypes: [
        //   DataSrv.metaObjClsType.DOC,
        //   DataSrv.metaObjClsType.PART,
        //   DataSrv.metaObjClsType.COMMONOBJ,
        //   DataSrv.metaObjClsType.COMMONOBJCUSTOMTABLE
        // ],
        showObjClsCodes: showObjClsId ? [showObjClsId] : []
      }
      const objGridRule = {
        objGridRuleType: EnumObjGridRule.AddDescObj,
        objGridRuleArgs: { mainObjID: mainObjParam.modelCode },
        isMultipleSelection: showObjClsId ? false : true
      }
      const porps = {
        size: EnumDialogSize.Fat,
        onClosing: async (e): Promise<void> => {
          //3.参数校验
          if (e.dialogResult == EnumDialogResult.Cancel || e.dialogResult == EnumDialogResult.Close) return

          if (e.dialogResult == EnumDialogResult.Apply) {
            e.cancel = true
          }

          const selectedObjParam = (e.viewModel as any).getSelectedObjParams()!
          if (selectedObjParam.length == 0) {
            // commonClientSrv.alertSelectARow()
            e.cancel = true
            return
          }
          const param = selectedObjParam?.map((m: { modelCode: any; id: any }) => ({ objClsID: m.modelCode, objID: m.id }))

          resolve({ objBusiness: param })
        },
        objectClassManageQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectSearchQueryParam: { ...objectClassTreeProps, ...objGridRule },
        objectDirectoryManageProps: { ...objGridRule },
        objectSearchProps: { ...objectClassTreeProps, ...objGridRule },
        recentOpenObjectGridProps: { objClsID: showObjClsId, ...objGridRule }
      } as ObjectSelectDialogParam
      //2.弹出查找对象对话框
      ObjectClientSrv.openObjectSelectDialog(porps)
    })
  }

  // /**
  //  * 移除交付物关联关系
  //  */
  // removeDeliverable(planObjParam: ObjPlanParam, objParams: Array<ObjPlanParam>, callBack: SuccessCallBack) {
  //   const filterParams = objParams.filter(a => a.objID != null)
  //   let msg
  //   if (!filterParams || filterParams.length == 0) {
  //     KDialog.warning({ title: '提示', content: '未选中实际交付物！' })
  //     return
  //   } else if (filterParams.length !== objParams.length) {
  //     msg = '已选中' + filterParams.length + '个对象,确定移除选中对象吗?'
  //   }

  //   commonClientSrv.confirmRemoveObj(async () => {
  //     const result = await projectSrv.removeDeliverableObjs({
  //       projectObjParam: planObjParam,
  //       relObjs: objParams
  //     })
  //     if (result) callBack()
  //   }, msg)
  // }

  // /**
  //  * 删除交付物
  //  */

  // async deleteDeliverable(planObjParam: ObjPlanParam, objParams: Array<ObjBusinessParam>, callBack: SuccessCallBack) {
  //   const filterParams = objParams.filter(a => a.objID != null)
  //   let msg
  //   if (!filterParams || filterParams.length == 0) {
  //     KDialog.warning({ title: '提示', content: '未选中实际交付物！' })
  //     return
  //   } else if (filterParams.length !== objParams.length) {
  //     msg = '已选中' + filterParams.length + '个对象,确定移除选中对象吗?'
  //   }

  //   // 获取选中的对象参数
  //   // 批量删除对象
  //   let i = 0
  //   const rowIdList = objParams.map(o => i++)

  //   await objectClientSrv.deleteBusinessObjBatch(objParams, rowIdList, msg)
  //   callBack()
  // }
}

export const deliverableClientSrv = new DeliverableClientSrv()
