import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-a1fcb800"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "chart-header" }
const _hoisted_2 = {
  ref: "refChart",
  style: {"width":"100%","height":"300px"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", null, _toDisplayString(_ctx.targetTitle), 1)
    ]),
    _createElementVNode("div", _hoisted_2, null, 512)
  ], 64))
}