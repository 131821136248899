import { Api, EnumRequestCode, KFolderSelectorViewModel } from '@kmsoft/ebf-common'
import KObjectPropertyPanelViewModel from '@kmsoft/ebf-common/src/controls/object-property-panel/KObjectPropertyPanelViewModel'
import { BaseViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { KIssueReportAffectedViewsGridViewModel } from '../../../controls/issue-report-affected-views'
import { KIssueReportCreateEmitsType, KIssueReportCreatePropType } from './interface'

/** KIssueReportCreate */
export default class KIssueReportCreateViewModel extends BaseViewModel<KIssueReportCreateEmitsType, KIssueReportCreatePropType> {
  formData = ref<any>({})
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()

  /**
   * 继续创建下一个
   */
  createNext = ref<boolean>(false)
  /**
   * 打开对象
   */
  openObj = ref<boolean>(true)

  /**
   * 是否正在保存中
   */
  isConfirming = ref<boolean>(false)

  /**
   * 是否从工作区通用创建入口而来
   */
  isFromCommonCreate = ref<boolean>(false)

  constructor(options: ViewModelOptions<KIssueReportCreatePropType>) {
    super(options)
    this.initForm()
  }

  initForm() {
    this.formData = ref<any>({
      number: '',
      name: '',
      type: 'DESIGN_CHANGE',
      description: '',
      proposedSolution: '',
      priority: 'LOW',
      complexity: 'Low',
      needDate: null,
      folder: {
        id: '',
        clazz: ''
      },
      secondary: null
    })
  }

  viewDidMount() {
    /**
     * 场景1：从导航菜单进入, 不携带任何参数
     * 场景2：从工作区-新建按钮进入, 携带[文件夹]参数
     * 场景3：从工作区-问题报告按钮进入, 携带[文件夹、受影响对象]参数
     */
    // 文件夹赋值
    if (this.props.formValue?.folder?.id) {
      this.formData.value.folder.id = this.props.formValue.folder?.id
      this.isFromCommonCreate.value = true
    }
    if (this.props.formValue?.plmChangeIssueAffectedViews) {
      this.formData.value.plmChangeIssueAffectedViews = this.props.formValue.plmChangeIssueAffectedViews || []
    }
  }

  /** 校验 */
  async validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  public getValue() {
    let createObject = this.refObjectCreatePanel.value?.getValue()
    // 类型
    // const rdmExtensionType = this.rdmExtensionType.value
    createObject = { ...createObject } as any

    return createObject
  }

  public async save(): Promise<any> {
    if (await this.validate()) {
      const saveValue = this.getValue() as any
      const plmChangeIssueAffectedViewsCur = saveValue.plmChangeIssueAffectedViews as any
      const plmChangeIssueAffectedViewsNew = [] as any
      if (plmChangeIssueAffectedViewsCur != null && plmChangeIssueAffectedViewsCur.length > 0) {
        plmChangeIssueAffectedViewsCur.forEach(function(item: any) {
          plmChangeIssueAffectedViewsNew.push({
            target: {
              id: item.id,
              rdmExtensionType: item.rdmExtensionType
            }
          })
        })
        saveValue.plmChangeIssueAffectedViews = plmChangeIssueAffectedViewsNew
      }
      if (saveValue!.secondary) {
        const secondary = saveValue!.secondary.map((element: string) => {
          return { id: element }
        })
        saveValue!.secondary = secondary
      }
      const params = {
        data: [
          {
            ...saveValue
          }
        ]
      }

      // 是否是自定义保存按钮
      const isConfirming = this.props.showExtButton
      if (isConfirming) {
        this.setLoading(true)
      }
      const result = await Api.post('change', 'ChangeIssue', 'create', params)
      this.setLoading(false)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (this.isFromCommonCreate.value) {
          return result
        }
        if (this.props.showAddButton) {
          // 保存成功后，初始化状态
          this.cleanAll()
        }
        return result
      } else {
        KNotification.error({
          title: '操作失败',
          content: result.message || '新建失败',
          details: result.detail
        })
        return result
      }
    }
    return Promise.resolve(false)
  }

  cleanAll() {
    this.initForm()
    this.refObjectCreatePanel.value?.refresh()
    const refFolder = this.refObjectCreatePanel.value?.getByRecursion('_#folder#1726799419200') as KFolderSelectorViewModel
    const refObjGrid = this.refObjectCreatePanel.value?.getByRecursion(
      'KIssueReportAffectedViewsGrid#1726799841835'
    ) as KIssueReportAffectedViewsGridViewModel
    refFolder.cleanForce()
    refObjGrid.cleanAllForce()
    // TODO: 后续附件实现后, 此处还需要清理附件内容
  }

  onCancel() {
    this.emit('cancel')
  }

  onConfirm() {
    this.emit('confirm', {
      createNext: this.createNext.value,
      openObj: this.openObj.value
    })
  }

  setLoading(status: boolean) {
    this.isConfirming.value = status
  }
}
