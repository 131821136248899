import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KProjectPageWrapperEmitsType, KProjectPageWrapperPropType } from './interface'
import { ref } from 'vue'
import { IFrameEventManager } from '@kmsoft/ebf-common'

/** KProjectPageWrapper */
export default class KProjectPageWrapperViewModel extends BaseViewModel<
  KProjectPageWrapperEmitsType,
  KProjectPageWrapperPropType
> {
  iFrameEventManager = ref<IFrameEventManager>()
  constructor(options: ViewModelOptions<KProjectPageWrapperPropType>) {
    super(options)
  }

  viewDidMount() {}
  refFrame = ref<HTMLIFrameElement>()

  refresh() {
    this.iFrameEventManager.value?.broadcastEvent('PMS', {
      type: 'refresh'
    })
  }
}
