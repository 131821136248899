
import { defineView } from '@kmsoft/upf-core'
import { KObjectStructFileUploadPropOptions, KObjectStructFileUploadEventEmits } from './interface'
import KObjectStructFileUploadViewModel from './KObjectStructFileUploadViewModel'

export default defineView({
  name: 'KObjectStructFileUpload',
  props: KObjectStructFileUploadPropOptions,
  emits: KObjectStructFileUploadEventEmits,
  viewModel: KObjectStructFileUploadViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
