
import { defineView } from '@kmsoft/upf-core'
import { KOfficePreviewPropOptions, KOfficePreviewEventEmits } from './interface'
import KOfficePreviewViewModel from './KOfficePreviewViewModel'

export default defineView({
  name: 'KOfficePreview',
  props: KOfficePreviewPropOptions,
  emits: KOfficePreviewEventEmits,
  viewModel: KOfficePreviewViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return {}
  }
})
