import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { ObjBusinessParam, enumFactory } from '@kmsoft/ebf-common'

/** 参数 **/
export const KPartReportPropOptions = {
  ...BaseViewPropOptions,
  objParam: VuePropTypes.createType<ObjBusinessParam>()
    .setRequired()
    .def()
}

/** 参数类型 **/
export type KPartReportPropType = ViewPropsTypeExtract<typeof KPartReportPropOptions>

/** 事件 */
export const KPartReportEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KPartReportEmitsType = ViewEmitsTypeExtract<typeof KPartReportEventEmits>

/** 采购类型 */
export const ProcurementType = enumFactory({
  F: '外购',
  E: '自制',
  X: '自制&外购'
})
