import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocFormProcessEmitsType, KDocFormProcessPropType } from './interface'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import { ref, h, watch } from 'vue'
import { Loading3QuartersOutlined } from '@ant-design/icons-vue'

/** KDocFormProcess */
export default class KDocFormProcessViewModel extends BaseViewModel<KDocFormProcessEmitsType, KDocFormProcessPropType> {
  percent = ref<number>(10)
  processData = ref<any>([])
  loading = ref<boolean>(true)
  clearTimer = ref<boolean>(false)
  indicator = h(Loading3QuartersOutlined, {
    style: {
      fontSize: '14px',
      color: 'gray'
    },
    spin: true
  })
  timer = ref<any>()

  constructor(options: ViewModelOptions<KDocFormProcessPropType>) {
    super(options)
  }

  viewDidMount() {
    this.getData()
  }

  async getData() {
    let num = 1
    this.timer.value = setInterval(async () => {
      if (this.percent.value >= 100 || this.clearTimer.value || num >= 150) {
        this.loading.value = false
        clearInterval(this.timer.value)
        this.timer.value = null
        return
      }
      const key = encodeURIComponent(this.props.id)
      const params = {
        data: [this.props.type, key]
      }
      const result = await Api.post('doc', 'Document', 'getProcessProgress', params)
      if (result && result.code == EnumRequestCode.SUCCESS && result.data) {
        this.processData.value = []
        if (result.data.history) {
          this.processData.value = result.data.history
        }
        this.processData.value.push({
          step: result.data.step,
          endFlag: result.data.endFlag,
          time: result.data.time,
          percent: result.data.percent,
          status: result.data.status
        })
        if (result.data.endFlag) {
          this.clearTimer.value = true
        }
        num++
        this.percent.value = this.processData.value.length * 9
      }
    }, 800)
  }

  setClearTimer() {
    clearInterval(this.timer.value)
    this.timer.value = null
  }
}
