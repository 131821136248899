import { EnumSupportObjectNumMode, ObjectToolStripItem } from '../../object-tool-strip'
import { EnumToolStripItemKeys } from '../types'
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import { EnumClassTemplate } from '../../../client-srv'
import { EnumPurviewCode } from '../../../common'

export const getBaselineEditToolStripItems = (): Array<ObjectToolStripItem> => {
  return [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE,
      title: '基线对比',
      icon: 'file-protect',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjClsTempletID: [EnumClassTemplate.CHANGE_ISSUE],
      shortcutKey: 'alt+o',
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW,
      title: '加入流程',
      icon: 'AddToFlow',
      visible: true,
      purviewId: EnumPurviewCode.AddWorkflow,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW,
      title: '查看流程',
      icon: 'ViewFlowObj',
      visible: true,
      purviewId: EnumPurviewCode.ViewWorkflow,
      compType: EnumToolStripCompType.BUTTON
    }
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE,
    //   title: '删除',
    //   icon: 'delete',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON,
    //   shortcutKey: 'alt+r'
    // },
    // {
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD,
    //   title: '废弃',
    //   icon: 'file-text',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // }
  ]
}
