import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_form_item = _resolveComponent("k-form-item")!
  const _component_k_date_time = _resolveComponent("k-date-time")!
  const _component_k_textarea = _resolveComponent("k-textarea")!
  const _component_k_form = _resolveComponent("k-form")!

  return (_openBlock(), _createBlock(_component_k_form, {
    style: {"padding":"0 15px"},
    ref: "refFolderForm",
    model: _ctx.$vm.formDataState,
    "label-col": { span: 4 },
    "wrapper-col": { span: 20 },
    autocomplete: "off"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_form_item, {
        label: "目录名称",
        name: "name",
        "has-feedback": "",
        rules: [{ required: true, message: '请输入' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formDataState.name,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.formDataState.name) = $event)),
            style: {"width":"100%"},
            required: true,
            disabled: _ctx.disabled
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }),
      _withDirectives(_createVNode(_component_k_form_item, {
        label: "创建者",
        name: "creator",
        "has-feedback": "",
        rules: [{ required: false, message: 'Please select your country!' }]
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_input, {
            value: _ctx.$vm.formDataState.creator,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.formDataState.creator) = $event)),
            disabled: true
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 512), [
        [_vShow, _ctx.visible]
      ]),
      _withDirectives(_createVNode(_component_k_form_item, {
        label: "创建时间",
        name: "createTime",
        "has-feedback": "",
        enable: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_date_time, {
            value: _ctx.$vm.formDataState.createTime,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.$vm.formDataState.createTime) = $event)),
            format: "YYYY-MM-DD HH:mm:ss",
            disabled: true
          }, null, 8, ["value"])
        ]),
        _: 1
      }, 8, ["enable"]), [
        [_vShow, _ctx.visible]
      ]),
      _createVNode(_component_k_form_item, {
        label: "描述",
        name: "remark",
        enable: _ctx.disabled
      }, {
        default: _withCtx(() => [
          _createVNode(_component_k_textarea, {
            rows: 4,
            value: _ctx.$vm.formDataState.description,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.$vm.formDataState.description) = $event)),
            multiline: true,
            visible: true,
            disabled: _ctx.disabled
          }, null, 8, ["value", "disabled"])
        ]),
        _: 1
      }, 8, ["enable"])
    ]),
    _: 1
  }, 8, ["model"]))
}