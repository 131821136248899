import {
  AppContext,
  BaseViewModel,
  EnumDialogResult,
  EnumItemChangeCancelType,
  KDialog,
  KDialogClosingEvent,
  KInputViewModel,
  KLoading,
  KNotification,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KBaselineEditEmitsType, KBaselineEditPropType } from './interface'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  loadingHandle,
  ObjBusinessParam,
  EnumLifecycleState,
  ObjectToolStripItem,
  ToolStripHelper
} from '@kmsoft/ebf-common'
import { ref } from 'vue'
import KBaselineCompare from '../baseline-compare'
import { BaselineClientSrv } from '../../client-srv'
import { WorkflowClientSrv } from '../../../../ebf-workflow/src/client-srv'

/** KBaselineEdit */
export default class KBaselineEditViewModel extends BaseViewModel<KBaselineEditEmitsType, KBaselineEditPropType> {
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  taskParam = ref<ObjBusinessParam>()
  originFormData = ref<any>()
  toolStripItems = ref<ObjectToolStripItem[]>(ToolStripHelper.getBaselineEditToolStripItems())

  constructor(options: ViewModelOptions<KBaselineEditPropType>) {
    super(options)
    this.taskParam.value = options.props.objParam
  }

  viewDidMount() {
    this.disabledEdit()
  }

  /** 禁用编辑**/
  disabledEdit(disabled: boolean = true) {
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, disabled)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, disabled)
    this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW, disabled)
  }

  async loadData() {
    const result = (await Api.post('baseline', 'ManagedBaseline', 'get', {
      data: [this.props.objParam.id]
    })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
      data.primaryObject = Object.assign(data.primaryObject, data.primaryObjectExt)
      data.plmBaselineMemberTargetViewDTOList = data.plmBaselineMemberTargetViewDTOList?.map((item: any) => {
        item.versionInfo = `${item.version}.${item.iteration}`
        return item
      })

      if (EnumLifecycleState[data.lifecycleStateCode] == '工作中') {
        this.disabledEdit(this.judgeEditOrNot(data))
      }

      this.originFormData.value = data
      return data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取问题报告件失败',
        details: result.detail
      })
      return
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    if (event.name == EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY) {
      if (event.value) {
        this.refObjectProperty.value?.setReadOnly(false)
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
      } else {
        if (await this.save(false)) {
          this.refObjectProperty.value?.setReadOnly(true)
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        }
      }
    }
  }

  onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const buttonName = event.name as EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE
    const method = this[buttonName]
    if (typeof method === 'function') {
      method.call(this, event)
    }
  }

  /** 保存 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE](event: ToolStripItemClickedEventArgs) {
    this.save()
    // this.refObjectProperty.value?.setReadOnly(true)
  }
  /** 加入流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.addWorkflow(
      this.taskParam.value!.id,
      'ManagedBaseline',
      EnumLifecycleState[this.originFormData.value.lifecycleStateCode] == '已发布' ? 'Released' : '',
      this.originFormData.value.name,
      this.originFormData.value.number
    )
  }

  /** 查看流程 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW](event: ToolStripItemClickedEventArgs) {
    WorkflowClientSrv.viewWorkflow(this.taskParam.value!.id, 'ManagedBaseline')
  }

  /** 刷新 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH](event: ToolStripItemClickedEventArgs) {
    this.refObjectProperty.value?.refresh()
  }

  /** 基线对比 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE](event: ToolStripItemClickedEventArgs) {
    BaselineClientSrv.baselineCompare(this.props.objParam.id, '')
  }

  /** 删除 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE](event: ToolStripItemClickedEventArgs) {
    const id = this.originFormData.value.id
    if (id) {
      KDialog.confirm({
        content: '确认要删除该基线么？',
        onOk: async () => {
          const result = await Api.post('baseline', 'ManagedBaseline', 'delete', { data: [id] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('操作成功！')
          }
        },
        onCancel: () => {}
      })
    }
  }

  /** 废弃 **/
  [EnumToolStripItemKeys.TOOL_STRIP_ITEM_DISCARD]() {
    const id = this.originFormData.value.id
    if (id) {
      KDialog.confirm({
        content: '确认要废弃该基线么？',
        onOk: async () => {
          const result = await Api.post('baseline', 'ManagedBaseline', 'discard', { data: [id] })
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success('操作成功！')
          }
        },
        onCancel: () => {}
      })
    }
  }
  /** 校验 */
  async validate() {
    try {
      // TODO  变更编辑验证
      return await this.refObjectProperty.value?.validate()
    } catch (error) {
      return false
    }
  }

  async save(isSave: boolean = true) {
    if (await this.validate()) {
      const modifiedValue = this.refObjectProperty.value?.getModifiedValue() as any
      const value = this.refObjectProperty.value?.getValue() as any
      //如果有修改对
      if (Object.keys(modifiedValue).length) {
        if (Object.keys(modifiedValue).includes('primaryObject')) {
          modifiedValue!.primaryObject = {
            id: modifiedValue!.primaryObject?.id ?? '',
            clazz: modifiedValue!.primaryObject?.rdmExtensionType ?? '',
            className: modifiedValue!.primaryObject?.className ?? ''
          }
        }

        // 处理表格
        // 处理表格
        if (Object.keys(value).includes('plmBaselineMemberTargetViewDTOList')) {
          const baselineMemberViewDTOS = (value!.plmBaselineMemberTargetViewDTOList.newData || []).reduce(
            (arr: any[], cur: any, index: number) => {
              arr.push({
                branchId: cur.branch.id ?? '',
                target: {
                  id: cur.id ?? '',
                  rdmExtensionType: cur.rdmExtensionType ?? ''
                },
                orderIndex: index
              })
              return arr
            },
            []
          )

          modifiedValue.baselineMemberViewDTOS = baselineMemberViewDTOS
          modifiedValue.deleteIds = value!.plmBaselineMemberTargetViewDTOList.deleteData
          Reflect.deleteProperty(modifiedValue, 'plmBaselineMemberTargetViewDTOList')
        }

        const params = {
          data: [
            {
              id: this.props.objParam.id,
              ...modifiedValue
            }
          ]
        }
        loadingHandle.show()
        const result = await Api.post('baseline', 'ManagedBaseline', 'update', params)
        loadingHandle.remove()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
          return true
        } else {
          KNotification.error({
            title: '系统错误',
            content: result.message
          })
          return false
        }
      } else {
        if (isSave)
          KNotification.warn({
            message: '系统提示',
            description: '没有修改数据'
          })
      }
      return true
    } else {
      return false
    }
  }

  /** 校验当前用户是否等于创建者 */
  judgeEditOrNot(row: any) {
    if (row.isLocked) {
      return row.creator !== AppContext.current.getIdentity()?.name
    } else {
      return false
    }
  }
}
