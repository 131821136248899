import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"height":"100%"} }
const _hoisted_2 = {
  key: 0,
  class: "full part-obj-manage"
}
const _hoisted_3 = {
  key: 1,
  class: "full part-obj-manage"
}
const _hoisted_4 = {
  key: 2,
  class: "full part-obj-manage"
}
const _hoisted_5 = {
  key: 3,
  class: "full part-obj-manage"
}
const _hoisted_6 = {
  key: 4,
  class: "full part-obj-manage"
}
const _hoisted_7 = {
  key: 5,
  class: "full part-obj-manage"
}
const _hoisted_8 = {
  key: 6,
  class: "full part-obj-manage"
}
const _hoisted_9 = {
  key: 7,
  class: "full part-obj-manage"
}
const _hoisted_10 = {
  key: 8,
  class: "full part-obj-manage"
}
const _hoisted_11 = { key: 1 }
const _hoisted_12 = {
  key: 9,
  class: "full part-obj-manage"
}
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_object_class_grid = _resolveComponent("k-object-class-grid")!
  const _component_k_model_browser = _resolveComponent("k-model-browser")!
  const _component_k_empty = _resolveComponent("k-empty")!
  const _component_k_office_preview = _resolveComponent("k-office-preview")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$vm.tabId == 'lbj-mswd')
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          _createVNode(_component_k_object_class_grid, {
            ref: "refPartDocDescDataGrid",
            modelCode: "PartDescribeLink",
            modelGroup: "part",
            loadData: _ctx.$vm.loadDescData,
            onToolStripItemClicked: _cache[0] || (_cache[0] = (event)=>_ctx.$vm.onDescToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.descToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'lbj-dzsjwd')
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_k_object_class_grid, {
            ref: "refPartEDADocumentDataGrid",
            modelCode: "PartDescribeLink",
            modelGroup: "part",
            loadData: _ctx.$vm.loadEDADocumentData,
            onToolStripItemClicked: _cache[1] || (_cache[1] = (event)=>_ctx.$vm.onEDAToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.edaToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'lbj-ckwd')
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_k_object_class_grid, {
            ref: "refPartDocReferenceDataGrid",
            modelCode: "PartDescribeLink",
            modelGroup: "part",
            loadData: _ctx.$vm.loadReferenceData,
            onToolStripItemClicked: _cache[2] || (_cache[2] = (event)=>_ctx.$vm.onReferenceToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.referenceToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'lsbb')
      ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
          _createVNode(_component_k_object_class_grid, {
            ref: _ctx.$vm.refHistoryDataGrid,
            modelCode: "Part",
            modelGroup: "part",
            loadData: _ctx.$vm.loadHistoryData,
            onToolStripItemClicked: _cache[3] || (_cache[3] = (event)=>_ctx.$vm.onHistoryToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.historyToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'bgjl')
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _createVNode(_component_k_object_class_grid, {
            ref: _ctx.$vm.refChangeLogDataGrid,
            modelCode: "ChangeHis",
            schemaType: "ChangeHis_grid",
            loadData: _ctx.$vm.loadChangeLogData,
            onToolStripItemClicked: _cache[4] || (_cache[4] = (event)=>_ctx.$vm.onChangeToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.changeToolStripItems,
            pagination: false
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'ssjx')
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_k_object_class_grid, {
            ref: _ctx.$vm.refManagedBaselineDataGrid,
            modelCode: "ChangeHis",
            schemaType: "ChangeHis_grid",
            loadData: _ctx.$vm.loadManagedBaselineData,
            onToolStripItemClicked: _cache[5] || (_cache[5] = (event)=>_ctx.$vm.onBaselineToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.baselineToolStripItems,
            pagination: false
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'qjtdj')
      ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
          _createVNode(_component_k_object_class_grid, {
            ref: _ctx.$vm.refPartAlterLinkDataGrid,
            modelCode: "PartAlterLink",
            modelGroup: "part",
            loadData: _ctx.$vm.loadPartAlterLinkData,
            onToolStripItemClicked: _cache[6] || (_cache[6] = (event)=>_ctx.$vm.onAlternateToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.replaceToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'jbtdj')
      ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
          _createVNode(_component_k_object_class_grid, {
            ref: _ctx.$vm.refBomSubstituteLinkDataGrid,
            modelCode: "BOMSubstituteLink",
            modelGroup: "part",
            loadData: _ctx.$vm.loadBomSubstituteLinkData,
            onToolStripItemClicked: _cache[7] || (_cache[7] = (event)=>_ctx.$vm.onSubstituteToolStripItemClicked(event)),
            toolStripItems: _ctx.$vm.replaceToolStripItems
          }, null, 8, ["loadData", "toolStripItems"])
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'ksh')
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          (_ctx.$vm.showBrowser)
            ? (_openBlock(), _createBlock(_component_k_model_browser, {
                key: 0,
                ref: _ctx.$vm.refBrowser,
                container: "partObjManage",
                objParam: _ctx.$vm.docObjParam,
                environment: "Part"
              }, null, 8, ["objParam"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_11, [
                _createVNode(_component_k_empty, {
                  description: "未找到可视化文件",
                  style: {"position":"absolute","top":"50%","transform":"translateY(-50%)"}
                })
              ]))
        ]))
      : _createCommentVNode("", true),
    (_ctx.$vm.tabId == 'ptksh')
      ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
          (_ctx.$vm.showBrowser)
            ? (_openBlock(), _createBlock(_component_k_office_preview, {
                key: 0,
                objParam: _ctx.$vm.docObjParam
              }, null, 8, ["objParam"]))
            : (_openBlock(), _createElementBlock("div", _hoisted_13, [
                _createVNode(_component_k_empty, {
                  description: "未找到可视化文件",
                  style: {"position":"absolute","top":"50%","transform":"translateY(-50%)"}
                })
              ]))
        ]))
      : _createCommentVNode("", true)
  ]))
}