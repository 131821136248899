import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-afb031e2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "full flex-start-center" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_select = _resolveComponent("k-select")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_k_select, {
      value: _ctx.$vm.selectedValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.selectedValue) = $event)),
      style: {"width":"100%"},
      options: _ctx.$vm.userList,
      mode: _ctx.mode,
      maxTagCount: 1,
      "show-search": "",
      filterOption: (inputValue, option) => _ctx.$vm.filterOption(inputValue, option),
      disabled: _ctx.disabled,
      readonly: _ctx.readonly,
      onSelect: _ctx.$vm.onSelect
    }, null, 8, ["value", "options", "mode", "filterOption", "disabled", "readonly", "onSelect"])
  ]))
}