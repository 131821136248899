import { KNotification, MemoryCacheBase } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { Api, EnumRequestCode, updateClsCodeMap } from '../../../../../client-srv'
import { ClassMeta, ClassMetaClientSrv, MetaClass } from '../../../../meta'
/** 对象类缓存 */
export class ClassMetaMemoryCache extends MemoryCacheBase<Array<ClassMeta>> {
  public static cacheKey = 'ClassMetaMemoryCache'
  constructor() {
    super()
    this.cacheKey = ClassMetaMemoryCache.cacheKey
  }

  public async reload() {
    /**通过接口获取对象类元数据定义 */
    const res = await Api.post('common', 'TypeDefinition', 'listAll', { data: [] })
    if (res && res.code == EnumRequestCode.SUCCESS) {
      this.cacheData = res.data! as Array<ClassMeta>
      this.getAllClass()
    } else {
      KNotification.error({
        title: '系统提示',
        content: '对象类元数据获取失败',
        details: res.message
      })
      this.cacheData = []
    }
  }

  /** 获取所有数据 */
  public getAll() {
    return lodash.cloneDeep(this.cacheData)
  }

  /** 获取实体类型数据 */
  private getAllClass() {
    const allClass: Array<MetaClass> = []
    const classMetas = this.cacheData.filter(item => item.tableName?.toLocaleUpperCase()?.includes('PDM'))
    for (const classMeta of classMetas) {
      const metaClass: MetaClass = ClassMetaClientSrv.convertClassMetaToMetaClass(classMeta)
      allClass.push(metaClass)
    }
    const allClassMap: Array<[string, string]> = allClass.map(item => [item.code, item.name])
    updateClsCodeMap(new Map(allClassMap))
  }
}
