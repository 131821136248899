import {
  MemoryCacheFactory,
  EnumDialogResult,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  SimpleViewModel,
  ViewModelOptions,
  AppContext,
  SelectOption
} from '@kmsoft/upf-core'
import { KChangeActivityObjGridEmitsType, KChangeActivityObjGridPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectSelectorViewModel,
  ObjectClientSrv,
  EnumWorkingState,
  EnumLifecycleState,
  EBF_IOC_KEY,
  PartViewMemoryCache
} from '@kmsoft/ebf-common'
import lodash from 'lodash'
import {
  KIssueReportRelationPart,
  KIssueReportRelationPartViewModel
} from '../issue-report-affected-views/issue-report-relation-part'
import {
  KIssueReportRelationDoc,
  KIssueReportRelationDocViewModel
} from '../issue-report-affected-views/issue-report-relation-doc'

/** KChangeActivityObjGrid */
export default class KChangeActivityObjGridViewModel extends SimpleViewModel<
  KChangeActivityObjGridEmitsType,
  KChangeActivityObjGridPropType
> {
  gridData = ref<any[]>([])
  refDataGrid = ref<KDataGridViewModel>()
  modified = ref<boolean>(false)
  originData = ref<any[]>([])
  isEdit = ref<boolean>(false)
  handingMethodOptions = ref<Array<SelectOption>>([
    { label: '改进', value: 'IMPROVE' },
    { label: '报废', value: 'SCRAP' },
    { label: '继续使用', value: 'USEEXISTING' },
    { label: '服务', value: 'SERVICE' },
    { label: '返工', value: 'REWORK' }
  ])
  cellEditingStarted(event: any) {
    this.isEdit.value = true
  }
  cellEditingStopped() {
    this.isEdit.value = false
  }
  constructor(options: ViewModelOptions<KChangeActivityObjGridPropType>) {
    super(options)

    watch(
      () => this.props.readonly,
      (newValue, oldVal) => {
        if (!this.props.isAfterObject) {
          !newValue ? this.refDataGrid.value?.beginEdit() : this.refDataGrid.value?.endEdit()
        }
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  onReady() {
    if (!this.props.readonly) {
      this.refDataGrid.value?.beginEdit()
    }
  }

  public setValue(newValue: any, setChanged?: boolean) {
    if (!this.isEdit.value) {
      //没有结束编辑时不要将新的网格数据更新到原始数据中
      this.originData.value = lodash.cloneDeep(newValue)
    }
    const newData = lodash.cloneDeep(newValue)
    if (!this.props.isAfterObject) {
      newData.forEach((item: any) => {
        if (!item.inventoryDisposition) {
          item.inventoryDisposition = 'IMPROVE'
        }
      })
    }
    this.gridData.value = newData
  }

  /** 添加 **/
  addData() {
    if (!this.props.readonly && !this.props.isAfterObject) {
      this.refDataGrid.value?.beginEdit()
    }
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      getContainer: this.refDataGrid.value?.getContainer(),
      objectClassManageQueryParam: {
        showObjClsCodes: ['Part', 'Document'],
        filter: {
          joiner: EnumQueryConditionJoiner.And,
          conditions: [
            {
              conditionName: 'lifecycleState.internalName',
              operator: EnumQueryConditionOperator.EQUAL,
              conditionValues: [this.props.isAfterObject ? 'InWork' : 'Released']
            }
          ]
        }
      },
      objectSearchQueryParam: {
        showObjClsCodes: ['Part', 'Document']
      },
      onClosing: async event => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
        // 获取当前弹出组件实例的ViewModel
        const selectorViewModel = event.viewModel as KObjectSelectorViewModel
        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows() || []

        if (selectedRows.length > 0) {
          const hasRepeat = this.checkRepeat(selectedRows)
          if (hasRepeat) {
            event.cancel = true
            return
          }
          // 改后对象需检查是否能加入
          if (this.props.isAfterObject) {
            const checkParams = selectedRows.map(item => {
              return {
                id: item?.branch?.id,
                clazz: item?.branch?.rdmExtensionType,
                name: item.name
              }
            })

            const result = await Api.post('change', 'ChangeActivity', 'checkByCaAndWf', {
              data: [checkParams]
            })

            if (result && result.code == EnumRequestCode.SUCCESS) {
              if (!result.data.success) {
                KNotification.error({
                  title: '添加失败',
                  content: result.data.msg
                })
                event.cancel = false
                return
              }
            } else {
              KNotification.error({
                title: '系统错误',
                content: result.message
              })
              event.cancel = false
              return
            }
          }

          this.addGridData(selectedRows)
        }
      }
    })
  }

  // 根据masterId检查是否有重复数据
  checkRepeat(selectData: Record<string, any>[]) {
    let hasRepeat = false
    let hasNoReleased = false
    let hasNoInwork = false
    const selectMasterId = selectData.map(item => {
      if (item.lifecycleStateCode !== 'Released') hasNoReleased = true
      if (item.lifecycleStateCode !== 'InWork') hasNoInwork = true
      if (!hasRepeat) {
        hasRepeat = this.gridData.value.some(data => data.targetMasterId == item.master.id || data?.master?.id == item.master.id)
      }
      return item.master.id
    })
    if (selectMasterId.length != new Set(selectMasterId).size) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在重复数据'
      })
      return true
    }
    if (hasRepeat) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中存在已添加的数据'
      })
      return true
    }
    if (hasNoInwork && this.props.isAfterObject) {
      KNotification.warn({
        message: '系统提示',
        description: '只能添加工作中的数据'
      })
      return true
    }
    if (hasNoReleased && !this.props.isAfterObject) {
      KNotification.warn({
        message: '系统提示',
        description: '只能添加已发布的数据'
      })
      return true
    }
    return false
  }

  addGridData(gridData: any[]) {
    this.modified.value = true
    const addDataList = this.gridDataFilter(gridData)
    if (!this.props.isAfterObject) {
      addDataList.forEach((item: any) => {
        if (!item.inventoryDisposition) {
          item.inventoryDisposition = 'IMPROVE'
        }
      })
    }
    this.gridData.value.push(...addDataList)
    this.refresh()
  }

  gridDataFilter(gridData: any[]) {
    // 去重
    const addDataList =
      gridData.map((item: any) => {
        // 版本
        item.versionInfo = `${item.version}.${item.iteration}`
        // 状态
        item.state = item.lifecycleStateCode ? EnumLifecycleState[item.lifecycleStateCode] : '--'
        // 检入标记
        item.checkInState = item.workingState ? EnumWorkingState[item.workingState as keyof typeof EnumWorkingState] : '--'
        return item
      }) || []

    return addDataList
  }

  /** 移除 **/
  removeData() {
    const selectedRows = this.refDataGrid.value?.getSelectedRows() || []
    if (selectedRows.length > 0) {
      this.modified.value = true
      this.gridData.value = this.gridData.value.filter(item => !selectedRows.some(row => row.id === item.id))
    }
  }
  /** 刷新 **/
  refresh() {}
  /** 相关零部件 **/
  async relatedPart() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    const className = await ObjectClientSrv.getModelGroupByCode(rows[0].rdmExtensionType!)
    if (className !== 'part') {
      KNotification.warn({
        message: '系统提示',
        description: '只能选择零部件行'
      })
      return
    }

    KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportRelationPart,
      props: {
        objParam: {
          id: rows[0].targetId || rows[0].id,
          rdmExtensionType: rows[0].targetExtensionType || rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change',
          expandType: this.props.isAfterObject ? 'FORWARD_EXPANSION' : null
        }
      },
      extraButtons: ['添加'],
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationPartViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()
        const hasRepeat = this.checkRepeat(selectedRows)
        if (hasRepeat) {
          event.cancel = true
          return
        }
        this.addGridData(selectedRows)

        if (event.dialogResult === '添加') {
          event.cancel = true
        }
      }
    })
  }
  /** 相关文档 **/
  relatedDocument() {
    const rows = this.refDataGrid.value?.getSelectedRows() || []
    if (rows && rows.length !== 1) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }

    const refDialog = KDialog.show({
      title: '相关文档',
      size: { width: 1000, height: 600 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KIssueReportRelationDoc,
      props: {
        objParam: {
          id: rows[0].targetId || rows[0].id,
          rdmExtensionType: rows[0].targetExtensionType || rows[0].rdmExtensionType,
          modelCode: 'ChangeIssue',
          modelGroup: 'change'
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as KIssueReportRelationDocViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const selectedRows = selectorViewModel.getSelectedRows()
        const hasRepeat = this.checkRepeat(selectedRows)
        if (hasRepeat) {
          event.cancel = true
          return
        }
        this.addGridData(selectedRows)
      }
    })
    AppContext.current.getIocContainer().registerSingleton(EBF_IOC_KEY.CLOSE_DIALOG, { closeDialog: () => refDialog.close() })
  }

  isModified(): boolean {
    if (this.props.isAfterObject) {
      return this.modified.value
    }
    this.refDataGrid.value?.endEdit()
    const data = this.getValue()
    const compare = this.compareData(data, this.originData.value)
    if (compare && !this.modified.value) {
      return false
    }
    return true
  }

  compareData(newData: any, oldData: any) {
    return JSON.stringify(newData) == JSON.stringify(oldData)
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.gridData.value || []
  }

  filterReleasedData(selectedRows: any, showWarn: boolean) {
    const filterReleasedRows: Array<Record<string, any>> = []
    if (selectedRows != null && selectedRows.length > 0) {
      selectedRows.forEach((item: any) => {
        if (item.lifecycleStateCode == 'Released') {
          filterReleasedRows.push(item)
        }
      })
    }

    if (filterReleasedRows.length == 0 && showWarn) {
      KNotification.warn({
        message: '系统提示',
        description: '选择数据行中没有已发布数据'
      })
      return []
    }
    return filterReleasedRows
  }
  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId || row.id,
      modelCode: row!.targetExtensionType || row.rdmExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType || row.rdmExtensionType!)
    }
    ObjectClientSrv.openObj(param)
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    } else if (row.targetViewId) {
      const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
      const partView = cacheInstance.getAll()
      if (row.targetViewId && partView.length > 0) {
        const view = partView.filter(item => item.id == row.targetViewId)[0]?.description
        return '(' + view + ')'
      }
    }
    return ''
  }
}
