import { BaseViewModel, KDataGridViewModel, KNotification, ViewModelOptions } from '@kmsoft/upf-core'
import { KPartSimulationReportFormEmitsType, KPartSimulationReportFormPropType } from './interface'
import { ref } from 'vue'
import { Api, EnumRequestCode } from '@kmsoft/ebf-common'
import _ from 'lodash'

/** KPartSimulationReportForm */
export default class KPartSimulationReportFormViewModel extends BaseViewModel<
  KPartSimulationReportFormEmitsType,
  KPartSimulationReportFormPropType
> {
  /**网格 */
  refSimulationReportGrid = ref<KDataGridViewModel>()

  constructor(options: ViewModelOptions<KPartSimulationReportFormPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 网格数据加载
   */
  loadData() {
    return new Promise(async (resolve, reject) => {
      const versionId = this.props.objParam.id
      const result = (await Api.post('part', 'Part', 'get', { data: [versionId] })) as any
      if (result && result.code == EnumRequestCode.SUCCESS) {
        const part = result.data
        const extAttrs = part.extAttrs
        const attr = _.find(extAttrs, attr => attr.name == 'SimulationRequestId')
        const simulationRequestId = attr.value
        if (simulationRequestId && simulationRequestId != null && simulationRequestId != '') {
          const simulationRequestIdList = simulationRequestId.split(';')
          const reportResult = (await Api.post('common', 'DataManager', 'findReportListByRequestId', {
            data: [simulationRequestIdList]
          })) as any
          if (reportResult && reportResult.code == EnumRequestCode.SUCCESS) {
            const data = reportResult.data
            resolve(data)
          } else {
            KNotification.error({
              title: '查询仿真报告失败',
              content: result.message || '查询仿真报告失败',
              details: result.detail
            })
            resolve([])
          }
        } else {
          KNotification.warn({
            message: '该零部件暂无仿真报告'
          })
          resolve([])
        }
      }
    })
  }

  /**
   * 刷新网格
   */
  refresh() {
    this.refSimulationReportGrid.value?.refresh()
  }

  /**
   * 导出excel
   */
  exportAsExcel() {
    const params = {
      columnKeys: this.refSimulationReportGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refSimulationReportGrid.value?.exportDataAsExcel(params)
  }

  /**
   * 导出选中
   */
  exportSelectAsExcel() {
    const data = this.refSimulationReportGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refSimulationReportGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id)
    }
    this.refSimulationReportGrid.value?.exportDataAsExcel(params)
  }
}
