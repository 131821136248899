import {
  EnumObjectPanelTabParamType,
  KObjectPanelEmitsType,
  KObjectPanelPropType,
  ObjectPanelRetrieveEvent,
  ObjectPanelTabChangeEvent
} from '@kmsoft/ebf-common'
import { TreeViewMenuEventArgs, VNodeProps, defineView } from '@kmsoft/upf-core'
import {
  KPartStructureTreeEmitsType,
  KPartStructureTreePropType,
  PartStructureTreeMenuItemClickedEvent,
  PartStructureTreeNode
} from '../part-structure-tree'
import { EnumPartToolStripItemKeys } from '../toolstrip'
import KPartStructureManageViewModel from './KPartStructureManageViewModel'
import { KPartStructureManageEventEmits, KPartStructureManagePropOptions } from './interface'

export default defineView({
  name: 'KPartStructureManage',
  props: KPartStructureManagePropOptions,
  emits: KPartStructureManageEventEmits,
  viewModel: KPartStructureManageViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染零部件结构树 */
      const renderTree = () => {
        /** 零部件结构树面板 */
        const partStructurePanelSlots = {
          header: () => {
            const popoverSlots = {
              default: () => {
                const title = vm.structureFilterName.value ? ',' + vm.structureFilterName.value : ''
                return (
                  <k-button type="text" size="small">
                    <k-icon type="filter" />
                    过滤：{vm.structureViewName.value}
                    {title}
                    <k-icon type="down" />
                  </k-button>
                )
              },
              content: () => {
                const structureViewName = '最新版本'
                return (
                  <k-space direction="vertical" style="width:300px">
                    <k-form labelAlign={'right'} labelCol={{ span: 5, offset: 0 }} wrapperCol={{ span: 22, offset: 1 }}>
                      <k-form-item label="视图">
                        <k-select
                          placeholder="视图"
                          options={vm.partViewOptions.value}
                          disabled={false}
                          onChange={vm.onPartViewChange}
                          v-model={[vm.partViewName.value, 'value']}
                        ></k-select>
                      </k-form-item>
                      <k-form-item label="版本规则">
                        <k-cascader
                          ref={vm.refCascader}
                          placeholder="版本规则"
                          options={vm.structureViewOptions.value}
                          disabled={props.disabledView}
                          onChange={vm.onStructureViewChange}
                          v-model={[structureViewName, 'value']}
                          defaultValue={['最新版本']}
                          bordered="true"
                        ></k-cascader>
                      </k-form-item>
                      <k-form-item label="时间" vShow={vm.structureViewName.value == '时间有效性'}>
                        <k-date-time
                          v-model={[vm.datedValue.value, 'value']}
                          showTime
                          format="YYYY-MM-DD HH:mm:ss"
                          onChange={vm.onDatedValueChange}
                        />
                      </k-form-item>
                      {/* <k-form-item label="过滤条件">
                        <k-select
                          placeholder="过滤条件"
                          options={vm.structureFilterOptions.value}
                          allowClear={true}
                          disabled={props.disabledFilter}
                          v-model={[vm.structureFilterName.value, 'value']}
                        ></k-select>
                      </k-form-item> */}
                    </k-form>
                  </k-space>
                )
              }
            }

            return (
              <div style="display:flex; align-items: center">
                <k-popover trigger="click" placement="bottomLeft" v-slots={popoverSlots} />
                <k-input-search
                  value={vm.queryCriteria}
                  placeholder="请输入零部件编码或名称"
                  onSearch={() => vm.locateSearch()}
                  onChange={() => vm.refreshQueryCriteria()}
                ></k-input-search>
              </div>
            )
          },
          default: () => {
            const partStructureViewMode = vm.structureViews.value.find(f => f.viewName == vm.structureViewName.value)

            if (!vm.isInitialized.value) {
              return <k-spin />
            }

            const treeProps: VNodeProps<KPartStructureTreePropType, KPartStructureTreeEmitsType> = {
              ref: vm.refPartStructureTree,
              objParam: props.objParam,
              viewName: vm.structureViewName.value,
              partViewName: vm.partViewName.value,
              filterName: vm.structureFilterName.value,
              isMultipleSelection: props.isMultipleSelection,
              checkStrictly: props.checkStrictly,
              disabledContextMenu: props.disabledContextMenu,
              partStructureViewMode: partStructureViewMode,
              datedValue: vm.datedValue.value,
              findPrevious: vm.findPrevious,
              findTop: vm.findTop,
              findNext: vm.findNext,
              findBottom: vm.findBottom,
              selectAll: vm.selectAll,
              cancelSelectAll: vm.cancelSelectAll,
              clearQueryNode: vm.clearQueryNode,
              refershSearchData: vm.refershSearchData,
              onBeforeSelect: vm.onBeforeNodeSelect,
              onAfterSelect: vm.onAfterSelect,
              onBeforeNodeInsert: vm.onBeforeNodeInsert,
              onReady: vm.onTreeReady,
              onAfterCheck: vm.onTreeAfterCheck,
              onNodeRefresh: vm.onNodeRefresh,
              onToolStripClicked: (event: EnumPartToolStripItemKeys) => {
                vm.onToolStripClicked(event)
              },
              onContextMenuItemClicked: (event: PartStructureTreeMenuItemClickedEvent) => {
                emit('treeContextMenuItemClicked', event)
              },
              onBeforeContextMenuPopup: (event: TreeViewMenuEventArgs<PartStructureTreeNode>) => {
                emit('beforeTreeContextMenuPopup', event)
              }
            }

            return <k-part-structure-tree {...treeProps} />
          }
        }

        return (
          <k-split-pane v-focus>
            <k-panel hideHeader={props.suppressView} v-slots={partStructurePanelSlots} />
          </k-split-pane>
        )
      }

      /** 对象面板 */
      const renderObjectPanel = () => {
        const partStructureViewMode = vm.structureViews.value.find(f => f.viewName == vm.structureViewName.value)
        if (props.suppressObjectPanel) {
          return
        }
        /** 对象面板参数 */
        const objectPanelProps: VNodeProps<KObjectPanelPropType, KObjectPanelEmitsType> = {
          ref: vm.refObjectPanel,
          objParam: vm.selectObjParam.value,
          suppressedTabs: props.suppressedTabs,
          suppressOperation: props.suppressOperation,
          suppressObjectLife: props.suppressObjectLife,
          container: vm.refPartStructureTree.value,
          attachParams: {
            [EnumObjectPanelTabParamType.ExpandMode]: vm.nodeChildExpandMode.value!,
            [EnumObjectPanelTabParamType.RootPartObjParam]: props.objParam,
            [EnumObjectPanelTabParamType.SelectedNode]: vm.selectedNode.value,
            [EnumObjectPanelTabParamType.ComponentEnviroment]: 'PartStructure',
            [EnumObjectPanelTabParamType.BomLinkParam]: vm.bomLinkParam.value,
            [EnumObjectPanelTabParamType.RootNode]: vm.partStructureTree?.treeView?.getRootNodes()[0],
            [EnumObjectPanelTabParamType.QueryRule]: partStructureViewMode,
            [EnumObjectPanelTabParamType.PartView]: vm.partViewName.value
          },
          isHighestOpen: false,
          onDataUpdated: vm.onObjectPanelDataUpdated,
          onRetrieve: (event: ObjectPanelRetrieveEvent<any>) => {
            vm.onRetrieve(event)
            emit('objectPanelRetrieve', event)
          },
          onTabChange: (event: ObjectPanelTabChangeEvent) => {
            emit('objectPanelTabChange', event)
          }
        }

        return (
          <k-split-pane v-focus collapse={!props.showObjectPanel} size={75}>
            <k-object-panel {...objectPanelProps} />
          </k-split-pane>
        )
      }

      return (
        <k-split-container class="k-part-structure-manage" sizeUnit={2}>
          <k-split-pane size={25}>
            <k-split-container horizontal={true}>
              {renderTree()}
              {/* {renderSwapGrid()} */}
            </k-split-container>
          </k-split-pane>
          {renderObjectPanel()}
        </k-split-container>
      )
    }
  }
})
