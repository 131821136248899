import {
  BaseViewModel,
  EnumItemClickedCancelType,
  EnumToolStripCompType,
  KDataGridViewModel,
  KDialog,
  KNotification,
  PageManager,
  SelectOption,
  ToolStripItemClickedEventArgs,
  ViewModelOptions,
  MemoryCacheFactory
} from '@kmsoft/upf-core'
import { KPlanValidationMetricsGridEmitsType, KPlanValidationMetricsGridPropType } from './interface'
import { ref } from 'vue'
import {
  Api,
  EnumRequestCode,
  EnumToolStripItemKeys,
  EnumValidationMetrics,
  KObjectToolStripViewModel,
  ObjBusinessParam,
  ObjectClientSrv,
  clsCodeMap,
  UserInfoCache
} from '@kmsoft/ebf-common'
import { KMetricsEditPanel } from '../../common-validation-metrics/metrics-edit-panel'

/** KPlanValidationMetricsGrid */
export default class KPlanValidationMetricsGridViewModel extends BaseViewModel<
  KPlanValidationMetricsGridEmitsType,
  KPlanValidationMetricsGridPropType
> {
  /** 对象 */
  objParam = ref<ObjBusinessParam>()
  /** 验证指标网格 */
  refValidationMetricsGrid = ref<KDataGridViewModel>()
  /** 验证指标工具栏 */
  refMetricsToolStrip = ref<KObjectToolStripViewModel>()
  /** 指标类型候选值 */
  validationMetricsTypeOptions = ref<Array<SelectOption>>(EnumValidationMetrics._list)
  /** 人员列表 */
  userList = ref<Record<string, any>[]>([])

  constructor(options: ViewModelOptions<KPlanValidationMetricsGridPropType>) {
    super(options)
    if (!this.props.isDesigner) {
      const model = this.props.api.getCurrentPanelViewModel() as any
      this.objParam.value = {
        id: model?.stateValue.value.id,
        modelCode: model?.stateValue.value.rdmExtensionType
      }
    }
    this.getUserData()
  }

  viewDidMount() {}

  /** 加载指标网格 */
  async loadMetricsData() {
    if (this.props.isDesigner) return []
    const obj = this.objParam.value
    const params = {
      data: [obj?.id]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'listByPlanId', params)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取验证指标失败',
        details: result.detail
      })
      return
    }
  }

  getUserData() {
    const cacheInstance = MemoryCacheFactory.get<UserInfoCache>(UserInfoCache.cacheKey)
    this.userList.value = cacheInstance.getUserOptionList()
  }

  /** 指标操作按钮 */
  metricsToolStripItems = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE,
      title: '移除',
      icon: 'delete',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'delete'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  /** 指标操作 */
  async onMetricsToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (this.props.isDesigner) return
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE:
        this.removeMetrics()
        event.itemCancelType = EnumItemClickedCancelType.CancelAll
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refValidationMetricsGrid.value?.refresh()
        break
      default:
        break
    }
  }

  /** 从计划移除指标 */
  removeMetrics() {
    const rows = this.refValidationMetricsGrid.value?.getSelectedRows()
    if (rows && rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }

    KDialog.confirm({
      title: '确认移除吗？移除后不可恢复!',
      onOk: async () => {
        // 获取对象id
        const rowIds = rows!.map(row => row.id)
        const reqParam = { data: [rowIds] }

        Api.post('requirement', 'ValidationMetrics', 'batchRemoveFromPlan', reqParam).then(result => {
          if (result && result.code == EnumRequestCode.SUCCESS) {
            // 刷新网格
            this.refValidationMetricsGrid.value?.removeSelectedRows()
            //this.refValidationMetricsGrid.value?.refresh()
            KNotification.success({
              title: '移除成功',
              content: result.message
            })
            return Promise.resolve({
              rows: result.data,
              total: result.data.length
            })
          } else {
            KNotification.error({
              title: '移除失败',
              content: result.message,
              details: result.detail
            })
            return
          }
        })
      }
    })
  }

  /** 打开指标对象 */
  async openMetricsTab(row: any) {
    const param = {
      id: row?.validationMetrics.id,
      modelCode: row?.validationMetrics.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row?.validationMetrics.className!)
    }
    ObjectClientSrv.openObj(param)
    // const tabKey = `${param.id}#${param.modelCode}`
    // const objResult = (await ObjectClientSrv.getObjBusiness(param)) as Record<string, any>
    // const title = clsCodeMap.get(objResult.rdmExtensionType) + ':' + ObjectClientSrv.getObjBusinessDesc(objResult)
    // PageManager.openPage(tabKey, title, KMetricsEditPanel, { objParam: param })
  }

  /** 指标通过 */
  async passMetrics(row: any) {
    if (row.result == 'PASS') {
      KNotification.warn({
        message: '系统提示',
        description: '当前指标已通过, 不可重复操作'
      })
      return
    }
    const reqParam = {
      data: [[row?.id]]
    }
    const result = await Api.post('requirement', 'ValidationMetrics', 'passMetricsWithPlan', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.refValidationMetricsGrid.value?.refresh()
      KNotification.success({
        title: '操作成功',
        content: result.message
      })
      return result.data
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '指标通过失败',
        details: result.detail
      })
      return
    }
  }
}
