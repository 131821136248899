
import {
  EnumDataGridRowModelType,
  KDataGridRowDoubleClickEvent,
  EnumDataGridRowSpanType,
  KDataGridRowClickEvent,
  defineView
} from '@kmsoft/upf-core'
import KObjectAddWorkflowViewModel from './KObjectAddWorkflowViewModel'
import { KObjectAddWorkflowEventEmits, KObjectAddWorkflowPropOptions } from './interface'
import { KCollectRelatedObject } from './collect-related-object'

export default defineView({
  name: 'KObjectAddWorkflow',
  props: KObjectAddWorkflowPropOptions,
  emits: KObjectAddWorkflowEventEmits,
  components: { KCollectRelatedObject },
  viewModel: KObjectAddWorkflowViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    /** 表单规则 */
    const rules: Record<string, Array<any>> = {
      templateId: [{ required: true, message: '请选择流程模板', trigger: 'blur' }],
      name: [{ required: true, message: '请输入流程名称', trigger: 'blur' }],
      code: [{ required: true, message: '请输入流程编码', trigger: 'blur' }]
    }
    return {
      rules,
      rowModelType: EnumDataGridRowModelType.SERVER_SIDE,
      rowSpanMode: EnumDataGridRowSpanType.SAME_ROW
    }
  }
})
