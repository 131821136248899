import { BaseViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KDocFilePropertyEmitsType, KDocFilePropertyPropType } from './interface'
import { ref, watch } from 'vue'
import { AddFilePropertyReadOption } from '../../interface'

/** KDocFileProperty */
export default class KDocFilePropertyViewModel extends BaseViewModel<KDocFilePropertyEmitsType, KDocFilePropertyPropType> {
  model = ref<AddFilePropertyReadOption>({
    isProperties: false,
    isStruct: false,
    isCascade: false
  })

  /** 对象属性禁用 */
  propertiesDisabled = ref<boolean>(false)
  /**零部件结构禁用 */
  structDisabled = ref<boolean>(false)
  /**联级禁用 */
  cascadeDisabled = ref<boolean>(false)

  /**零部件结构隐藏 */
  structVisible = ref<boolean>(true)
  /**联级隐藏  */
  cascadeVisible = ref<boolean>(false)
  /**属性隐藏 */
  propertiesVisible = ref<boolean>(true)
  constructor(options: ViewModelOptions<KDocFilePropertyPropType>) {
    super(options)
    this.model.value.isCascade = this.props.isCascade
    this.cascadeDisabled.value = this.props.cascadeDisabled
    this.propertiesVisible.value = this.props.propertiesVisible
    this.propertiesDisabled.value = this.props.propertiesDisabled
  }

  getValue(): AddFilePropertyReadOption {
    return this.model.value
  }

  viewDidMount() {}
}
