import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KObjectStructFileUploadPropOptions = {
  ...BaseViewPropOptions,
  modelName: VuePropTypes.string().def('Document'),
  attributeName: VuePropTypes.string().def('primary')
}

/** 参数类型 **/
export type KObjectStructFileUploadPropType = ViewPropsTypeExtract<typeof KObjectStructFileUploadPropOptions>

/** 事件 */
export const KObjectStructFileUploadEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KObjectStructFileUploadEmitsType = ViewEmitsTypeExtract<typeof KObjectStructFileUploadEventEmits>
