import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-3e906d92"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "kanban-main" }
const _hoisted_2 = { class: "change-kanban-header" }
const _hoisted_3 = { class: "change-kanban-collect" }
const _hoisted_4 = { class: "collect" }
const _hoisted_5 = { class: "collect-number" }
const _hoisted_6 = { class: "collect" }
const _hoisted_7 = { class: "collect-number" }
const _hoisted_8 = { class: "collect" }
const _hoisted_9 = { class: "collect-number" }
const _hoisted_10 = { class: "collect" }
const _hoisted_11 = { class: "collect-number" }
const _hoisted_12 = { class: "change-kanban-context" }
const _hoisted_13 = { class: "context-item" }
const _hoisted_14 = { class: "context-item" }
const _hoisted_15 = { class: "context-item" }
const _hoisted_16 = { class: "context-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_segmented = _resolveComponent("k-segmented")!
  const _component_k_date_time = _resolveComponent("k-date-time")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_change_statistics = _resolveComponent("k-change-statistics")!
  const _component_k_empty = _resolveComponent("k-empty")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_k_segmented, {
        value: _ctx.$vm.dateValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.$vm.dateValue) = $event)),
        options: _ctx.$vm.dateData,
        size: "large",
        onChange: _ctx.$vm.changeDate
      }, {
        label: _withCtx(({ label }) => [
          _createTextVNode(_toDisplayString(label), 1)
        ]),
        _: 1
      }, 8, ["value", "options", "onChange"]),
      _createVNode(_component_k_date_time, {
        style: {"width":"250px"},
        format: _ctx.$vm.format,
        value: _ctx.$vm.dateTimeValue,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.$vm.dateTimeValue) = $event)),
        type: "range",
        allowClear: false,
        disabledDate: _ctx.$vm.disabledDate,
        picker: _ctx.$vm.datePicker,
        onCalendarChange: _ctx.$vm.onCalendarChange
      }, null, 8, ["format", "value", "disabledDate", "picker", "onCalendarChange"]),
      _createVNode(_component_k_button, {
        onClick: _ctx.$vm.searchRequirementData
      }, {
        default: _withCtx(() => [
          _createTextVNode("查询")
        ]),
        _: 1
      }, 8, ["onClick"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createTextVNode(" 未完成的产品需求 "),
        _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.$vm.collectData.productNeeds || 0), 1)
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(" 未完成的原始需求 "),
        _createElementVNode("div", _hoisted_7, _toDisplayString(_ctx.$vm.collectData.customerNeeds || 0), 1)
      ]),
      _createElementVNode("div", _hoisted_8, [
        _createTextVNode(" 未完成的验证计划 "),
        _createElementVNode("div", _hoisted_9, _toDisplayString(_ctx.$vm.collectData.verificationPlan || 0), 1)
      ]),
      _createElementVNode("div", _hoisted_10, [
        _createTextVNode(" 未完成产品需求项 "),
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$vm.collectData.planActivity || 0), 1)
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createVNode(_component_k_change_statistics, {
          targetTitle: "新增产品需求趋势",
          echartsType: "bar",
          xData: _ctx.$vm.barXAxisData,
          yData: _ctx.$vm.trendData
        }, null, 8, ["xData", "yData"])
      ]),
      _createElementVNode("div", _hoisted_14, [
        _createVNode(_component_k_change_statistics, {
          targetTitle: "新增产品需求变更趋势",
          echartsType: "bar",
          xData: _ctx.$vm.barXAxisData,
          yData: _ctx.$vm.changeTrendData
        }, null, 8, ["xData", "yData"])
      ]),
      _createElementVNode("div", _hoisted_15, [
        (!_ctx.$vm.emptyVerificationPlan)
          ? (_openBlock(), _createBlock(_component_k_change_statistics, {
              key: 0,
              targetTitle: "验证计划完成情况排行",
              echartsType: "bar",
              tooltip: { trigger: 'axis', formatter: _ctx.$vm.formatter },
              xAxis: { type: 'value' },
              yAxis: _ctx.$vm.verificationPlanYAxis,
              series: _ctx.$vm.verificationPlanSeries,
              grid: { top: 30, left: 100, bottom: 20 }
            }, null, 8, ["tooltip", "yAxis", "series"]))
          : (_openBlock(), _createBlock(_component_k_empty, {
              key: 1,
              description: "暂无数据"
            }))
      ]),
      _createElementVNode("div", _hoisted_16, [
        _createVNode(_component_k_change_statistics, {
          targetTitle: "产品需求类别统计",
          echartsType: "pie",
          title: _ctx.$vm.productNeedsTypeTitle,
          xAxis: { show: false },
          yAxis: { show: false },
          tooltip: { trigger: 'item', formatter: _ctx.$vm.productNeedsTypeFormatter },
          series: _ctx.$vm.productNeedsTypeSeries
        }, null, 8, ["title", "tooltip", "series"])
      ])
    ])
  ]))
}