import { Api, EnumRequestCode, ObjectToolStripItem } from '@kmsoft/ebf-common'
import { BaseViewModel, KNotification, KTreeViewViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { ref } from 'vue'
import { CopyOrCutEnum, FolderTreeNode, IFolder } from '../../../pages/folder-manage/folder-tree/interface'
import { folderObjOperationToolStripItems } from '../../../pages/folder-manage/toolStripItems'
import { KPersonalWorkspaceEmitsType, KPersonalWorkspacePropType, PersonalWorkspaceRootTreeNode } from './interface'
import { KFolderManageViewModel } from '../../../pages/folder-manage'

/** KPersonalWorkspace */
export default class KPersonalWorkspaceViewModel extends BaseViewModel<KPersonalWorkspaceEmitsType, KPersonalWorkspacePropType> {
  refFolderManage = ref<KFolderManageViewModel>()
  selectNode = ref<FolderTreeNode>()
  refFolderTree = ref<KTreeViewViewModel>()
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /**工具栏item */
  toolStripItems = ref<Array<ObjectToolStripItem>>([])
  /** 运行的对象类 */
  allowedClsCodes = ref<Array<string>>([])
  /** 文件夹类型 */
  folderType = ref<number>()
  /** 父文件夹Id */
  parentFolderId = ref<string>()
  // menuList = ref<Array<MenuOption>>([
  //   { label: '默认存储文件夹', value: '10', disabled: false },
  //   { label: '数据转移规则', value: '20', disabled: false }
  // ])

  constructor(options: ViewModelOptions<KPersonalWorkspacePropType>) {
    super(options)
    this.toolStripItems.value = folderObjOperationToolStripItems
  }

  viewDidMount() {}
  /**
   * 加载树节点
   */
  async loadTreeData() {
    const param = {
      data: []
    }
    const res = await Api.post('folder', 'Folder', 'getPersonalWorkspace', param)
    if (res && res.code == EnumRequestCode.SUCCESS) {
      const node = {
        id: res.data?.id,
        name: res.data?.name,
        leafFlag: res.data.leafFlag,
        nameEn: res.data.nameEn,
        type: res.data.type
      } as IFolder
      return [new PersonalWorkspaceRootTreeNode(node)]
    } else {
      KNotification.error({
        title: '获取个人工作区数据异常',
        content: res.message!
      })
      return Promise.resolve([])
    }
  }

  refresh() {
    this.refFolderManage.value?.refresh()
  }
}
