import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_bottom_bar = _resolveComponent("k-bottom-bar")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!
  const _component_k_panel = _resolveComponent("k-panel")!

  return (_openBlock(), _createBlock(_component_k_panel, null, {
    default: _withCtx(() => [
      _createVNode(_component_k_data_grid, {
        rowModelType: _ctx.rowModelType,
        class: "simulation-report-grid",
        rowKey: "id",
        name: "SimulationReportGrid",
        pagination: false,
        ref: _ctx.$vm.refSimulationReportGrid,
        loadData: _ctx.$vm.loadData,
        autosizeColumnsMode: _ctx.autosizeColumnsMode
      }, {
        columns: _withCtx(() => [
          _createVNode(_component_k_data_grid_template_column, {
            id: "name",
            name: "name",
            dataPropertyName: "name",
            headerText: "报告名称",
            align: "center",
            width: 315
          }, {
            default: _withCtx(({ row }) => [
              _createElementVNode("a", {
                href: row.reportUrl,
                target: "_blank"
              }, _toDisplayString(row.name), 9, _hoisted_1)
            ]),
            _: 1
          })
        ]),
        bottomBar: _withCtx(() => [
          _createVNode(_component_k_bottom_bar, {
            enableExcelExport: "",
            onRefresh: _cache[0] || (_cache[0] = () => _ctx.$vm.refresh()),
            onExcelSelectExport: _cache[1] || (_cache[1] = () => _ctx.$vm.exportSelectAsExcel()),
            onExcelExport: _cache[2] || (_cache[2] = () => _ctx.$vm.exportAsExcel())
          })
        ]),
        _: 1
      }, 8, ["rowModelType", "loadData", "autosizeColumnsMode"])
    ]),
    _: 1
  }))
}