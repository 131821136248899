import { BaseViewModel, DataGridLoadResult, IQuery, KDataGridViewModel, ViewModelOptions } from '@kmsoft/upf-core'
import { KRelatedRequirementsEmitsType, KRelatedRequirementsPropType } from './interface'
import { ref } from 'vue'
import { ObjectClientSrv, Api, EnumRequestCode } from '@kmsoft/ebf-common'
/** KRelatedRequirements */
export default class KRelatedRequirementsViewModel extends BaseViewModel<
  KRelatedRequirementsEmitsType,
  KRelatedRequirementsPropType
> {
  refDataGrid = ref<KDataGridViewModel>()
  constructor(options: ViewModelOptions<KRelatedRequirementsPropType>) {
    super(options)
  }

  viewDidMount() {}

  /**
   * 刷新
   */
  refersh() {
    this.refDataGrid.value?.refresh()
  }

  /**
   * 网格数据加载
   */
  loadData(query: IQuery): Promise<DataGridLoadResult> {
    return new Promise((resolve, reject) => {
      const param = { data: [this.props.objParam.id] }
      Api.post('requirement', 'ValidationMetrics', 'listAssociatedRequirement', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          resolve(res.data)
        } else {
          resolve([])
        }
      })
    })
  }

  /**
   * 打开计划详情
   * @param row 选中行
   */
  async openPlanTab(row: any) {
    const param = {
      id: row?.id,
      modelCode: row!.className,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.className!)
    }
    ObjectClientSrv.openObj(param)
  }
}
