import { Api, EnumRequestCode, localStorageCache, EBF_IOC_KEY } from '@kmsoft/ebf-common'
import { IMenuProvider, NavigationMenuGroup, AppContext } from '@kmsoft/upf-core'
import { sysSrv } from '@kmsoft/ebf-common'

export default class MenuProvider implements IMenuProvider {
  async getMenus(): Promise<Array<NavigationMenuGroup>> {
    let groups = [] as Array<NavigationMenuGroup>
    groups.push(
      {
        id: 'staging',
        name: '工作台',
        icon: 'workbench',
        children: [
          // {
          //   id: 'PersonalHomePage',
          //   name: '个人首页',
          //   icon: '',
          //   actionType: 1,
          //   actionParam: '{"target":"personal-homepage"}'
          // },
          {
            id: 'personalWorkspace',
            name: '个人工作区',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"personal-workspace"}'
          },
          {
            id: 'epmWorksapce',
            name: 'CAD工作区',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"cad-workspace-list"}'
          },
          {
            id: 'myProjectActivities',
            name: '我的项目任务',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'TaskMange' }
            })
          },
          // {
          //   id: 'myProcessActivities',
          //   name: '我的流程任务',
          //   icon: '',
          //   actionType: 1,
          //   actionParam: '{"target":"personal-workflow-tasks"}'
          // },
          {
            id: 'myChanges',
            name: '我的变更',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"personal-change-items"}'
          },
          {
            id: 'myDiDistributions',
            name: '我的发放单',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"personal-electronic-distribution"}'
          },
          {
            id: 'myVerificationPlan',
            name: '我的验证计划',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"personal-verification-plan"}'
          },
          {
            id: 'dataQuery',
            name: '数据查询',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"object-workspace"}'
          }
        ]
      },
      {
        id: 'productWorkspace',
        name: '产品工作区',
        icon: 'product-area',
        children: [
          {
            id: 'allProductLibraries',
            name: '全部产品库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"product-space-list"}'
          },
          {
            id: 'manageProeuctLibraries',
            name: '我管理的产品库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"my-managed-production-workspace"}'
          }
        ]
      },
      {
        id: 'projectManaged',
        name: '项目管理',
        icon: 'working-area',
        children: [
          {
            id: 'projectList',
            name: '项目列表',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'projectManage' }
            })
          },
          {
            id: 'createWorkspace',
            name: '项目工作区',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"project-workspace-list"}'
          },
          {
            id: 'projectResources',
            name: '项目资源',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'ResourceManage' }
            })
          },
          {
            id: 'projectChanges',
            name: '项目变更',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'ProjectChange' }
            })
          },
          {
            id: 'projectIssues',
            name: '项目问题',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'IssueManage' }
            })
          },
          {
            id: 'projectRisks',
            name: '项目风险',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'RiskManage' }
            })
          },
          {
            id: 'projectBudget',
            name: '项目预算',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'BudgetManage' }
            })
          },
          {
            id: 'projectTemplates',
            name: '项目模板',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'TemplateManage' }
            })
          },
          {
            id: 'projectConfigs',
            name: '项目配置',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'project/project-page-wrapper',
              params: { name: 'Setting' }
            })
          }
        ]
      },
      {
        id: 'enterpriseResourceLibrary',
        name: '企业资源库',
        icon: 'standard-library',
        children: [
          {
            id: 'publicWorkspace',
            name: '公共工作区',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"public-workspace"}'
          },
          {
            id: 'standardLibrary',
            name: '标准件库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"standard-workspace"}'
          },
          {
            id: 'electronicComponentsLibrary',
            name: '元器件库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"electronic-components-workspace"}'
          },
          {
            id: 'commonLibrary',
            name: '通用件库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"common-part-workspace"}'
          }
        ]
      },
      {
        id: 'changeManagement',
        name: '变更管理',
        icon: 'change-management',
        children: [
          {
            id: 'changeKanban',
            name: '变更看板',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"change-kanban"}'
          },
          {
            id: 'traceChange',
            name: '变更追溯',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"change-tracing"}'
          }
        ]
      },
      {
        id: 'requirementManage',
        name: '需求管理',
        icon: 'require-management',
        children: [
          {
            id: 'commonValidationMetrics',
            name: '指标库',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"common-validation-metrics"}'
          },
          {
            id: 'requirementTracing',
            name: '需求追溯',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"requirement-tracing"}'
          },
          {
            id: 'requirementBoard',
            name: '需求看板',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"requirement-kanban"}'
          }
        ]
      },
      // {
      //   id: '600',
      //   name: '流程建模',
      //   icon: 'workflow',
      //   children: [
      //     {
      //       id: 'defineWorkflowTemplage',
      //       name: '流程模板定义',
      //       icon: '',
      //       actionType: 1,
      //       actionParam: '{"target":"workflow-template"}'
      //     }
      //     // ,
      //     // {
      //     //   id: '600-2',
      //     //   name: '流程查看',
      //     //   icon: '',
      //     //   actionType: 1,
      //     //   actionParam: '{"target":"workflow-view"}'
      //     // },
      //     // {
      //     //   id: '600-3',
      //     //   name: '签审历史',
      //     //   icon: '',
      //     //   actionType: 1,
      //     //   actionParam: '{"target":"workflow-signing-history"}'
      //     // }
      //   ]
      // },
      {
        id: 'SystemManagement',
        name: '系统管理',
        icon: 'system',
        children: [
          {
            id: 'systemConfigs',
            name: '系统配置',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"config-manage"}'
          },
          {
            id: 'layoutManage',
            name: '布局定义',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"class-ui-define"}'
          },
          {
            id: 'logManage',
            name: '日志管理',
            icon: '',
            actionType: 3,
            actionParam: '{"target":"log-manage","params":{"path":"/pdm/elk"}}'
          }
        ]
      }
    )

    groups = sysSrv.isSSOLogin() ? await this.getMenuList(groups) : groups
    const env = process.env.NODE_ENV
    if (env == 'development') {
      groups.push({
        id: '700',
        name: '组件测试',
        icon: 'folder',
        children: [
          {
            id: '700-4',
            name: '上传',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"file-upload"}'
          },
          {
            id: '700-5',
            name: '对象分类管理',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"object-class-manage"}'
          },
          {
            id: '700-6',
            name: '远程加载测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"extends-test"}'
          },
          {
            id: '700-7',
            name: '对象选择测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"object-create-panel-test"}'
          },
          {
            id: '700-8',
            name: '树网格测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"tree-grid-demo"}'
          },
          {
            id: '700-9',
            name: '文件夹选择测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"folder-selector-test"}'
          },
          {
            id: '700-10',
            name: '零部件管理',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"part-manage"}'
          },
          {
            id: '700-11',
            name: '零部件导入',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"part-import"}'
          },
          {
            id: '700-13',
            name: '零部件导入错误列表',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"part-import-error-msg"}'
          },
          {
            id: '800-10',
            name: '文档测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"doc-demo"}'
          },
          {
            id: '801-10',
            name: 'kmvue测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"object-panel-test-kmvue"}'
          },
          {
            id: '801-hw',
            name: '分类属性测试',
            icon: '',
            actionType: 1,
            actionParam: '{"target":"catetory-test"}'
          },
          {
            id: 'uit',
            name: 'UIT',
            icon: '',
            actionType: 2,
            actionParam: '{"target":"http://www.baidu.com"}'
          },
          {
            id: '900-0',
            name: '创建变更问题报告',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'issue-report-create',
              params: { showAddButton: true }
            })
          },
          {
            id: '900-04',
            name: '创建变更请求',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'change-request-create',
              params: { showAddButton: true }
            })
          },
          {
            id: '900-05',
            name: '导航模式测试',
            icon: '',
            actionType: 1,
            actionParam: JSON.stringify({
              target: 'navigation-style-test',
              params: { showAddButton: true }
            })
          }
        ]
      })
      groups
        .find(x => x.id == '900')
        ?.children.push(
          ...[
            {
              id: '900-01',
              name: '创建变更问题报告',
              icon: '',
              actionType: 3,
              actionParam: JSON.stringify({
                target: 'issue-report-create-popup',
                params: { showExtButton: true }
              })
            },
            {
              id: '900-1',
              name: '变更问题报告',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"issue-report"}'
            },
            {
              id: '900-2',
              name: '创建变更活动',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"change-activity-create"}'
            },
            {
              id: '900-3',
              name: '编辑变更活动',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"change-activity-edit"}'
            },
            {
              id: '900-4',
              name: '创建变更请求',
              icon: '',
              actionType: 3,
              actionParam: JSON.stringify({
                target: 'change-request-create-popup',
                params: { showExtButton: true }
              })
            },
            {
              id: '900-5',
              name: '变更请求',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"change-request"}'
            },
            {
              id: '900-6',
              name: '创建变更单',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"change-order-create"}'
            },
            {
              id: '900-7',
              name: '编辑变更单',
              icon: '',
              actionType: 1,
              actionParam: '{"target":"change-order-edit"}'
            }
          ]
        )
    }
    return groups
  }

  /** 获取当前用户权限（菜单、功能）*/
  async getMenuList(groups: Array<NavigationMenuGroup>) {
    // 单点登陆暂时限制为以下几个账号，其余账号不给权限
    const userList = [
      'lixuan@ipdc.huaweiapaas.com',
      'zhangshibo@ipdc.huaweiapaas.com',
      'tangyixiang@ipdc.huaweiapaas.com',
      'zengcong@ipdc.huaweiapaas.com',
      'yandong@ipdc.huaweiapaas.com',
      'taoshihu@ipdc.huaweiapaas.com',
      'shenbochen@ipdc.huaweiapaas.com',
      'zhaomousheng@ipdc.huaweiapaas.com',
      'luodewang@ipdc.huaweiapaas.com',
      'huyuanyuan@ipdc.huaweiapaas.com',
      'lilongdong@ipdc.huaweiapaas.com',
      'yinpan@ipdc.huaweiapaas.com',
      'liuling@ipdc.huaweiapaas.com',
      'sujp@ipdc.huaweiapaas.com',
      'weizejian@ipdc.huaweiapaas.com',
      'likunyi@ipdc.huaweiapaas.com',
      'xuying@ipdc.huaweiapaas.com',
      'chenglei@ipdc.huaweiapaas.com'
    ]
    const limitControl = AppContext.current.getEnvironment().getProperty<string>('gsk.limitControl')
    if (limitControl == 'false') {
      return groups
    }
    const limitAccountFlag = AppContext.current.getEnvironment().getProperty<string>('gsk.limitAccountFlag')
    const userCode = AppContext.current.getIdentity()?.name
    if (!userCode) {
      const url: string = AppContext.current.getEnvironment().getProperty('url.loginAuthorize')
      window.location.href = url
      return []
    }
    if (limitAccountFlag == 'true' && !userList.includes(userCode?.split(' ')[0])) {
      return []
    }
    const token = JSON.parse(localStorage.getItem('KMPDM-km_app_identity_info') as string)?.value?.ipCenterToken
    const param = {
      data: [token] // 用户token
    }
    const menuList = await Api.post('si', 'PurviewService', 'getCurrentUserPurview', param)
    const result: Array<NavigationMenuGroup> = []
    if (menuList && menuList.code == EnumRequestCode.SUCCESS) {
      localStorageCache.setCache('KMPDM-system_limits', JSON.stringify(menuList.data))

      // 筛序菜单权限
      groups.map((nodes: NavigationMenuGroup) => {
        if (nodes.children.length) {
          if (menuList.data.some((it: any) => it.purviewCode === nodes.id)) {
            result.push({ ...nodes, children: [] })
            for (const node of nodes.children) {
              if (menuList.data.some((it: any) => it.purviewCode === node.id)) {
                result[result.length - 1].children.push({ ...node })
              }
            }
          }
        }
      })
    }
    return result
  }
}
