import {
  Api,
  CommonClientSrv,
  ConfigClientSrv,
  EnumRequestCode,
  ObjectHelper,
  KObjectStructFileUpload,
  KObjectStructFileUploadViewModel
} from '@kmsoft/ebf-common'
import {
  AppContext,
  BaseViewModel,
  EnumDialogResult,
  IKTreeNode,
  KDialog,
  KDialogClosingEvent,
  KDialogResult,
  KIcon,
  KModal,
  KNotification,
  KOrderAdjustPanel,
  KTreeNode,
  KTreeViewViewModel,
  TreeViewMenuClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { createVNode, ref, watch } from 'vue'
import { DocClientSrv } from '../../../../../ebf-doc/src/client-srv/DocClientSrv'
import { CopyOrCutEnum, EnumToolStripItemKeys } from '../../../../../ebf-folder/src/pages/folder-manage/folder-tree/interface'
import { KModelBrowser } from '../../../../../ebf-km-vue/src/components'
import { EnumKmVueFileType } from '../../../../../ebf-km-vue/src/components/vue-browser/enums'
import {
  KCustomerNeedsSectionCreate,
  KCustomerNeedsSectionCreateViewModel
} from '../../../pages/customer-needs/customer-needs-section-create'
import {
  KProductNeedsSectionCreate,
  KProductNeedsSectionCreateViewModel
} from '../../../pages/product-needs/product-needs-section-create'
import { KRequireSearchFrom } from '../require-search-from'
import { KRequireTreeEmitsType, KRequireTreePropType, RequireTreeNode } from './interface'
import { EnumRequirementToolStripItemKeys } from '../../../typings'

/** KRequireTree */
export default class KRequireTreeViewModel extends BaseViewModel<KRequireTreeEmitsType, KRequireTreePropType> {
  refFolderTreeEl = ref<HTMLElement>()
  /**树组件 */
  refRequireTree = ref<KTreeViewViewModel>()
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /** 查询条件 */
  queryCriteria = ref<string>('')
  /** 查询索引 */
  queryIndex = ref<number>(0)
  /** 查询结果 */
  queryData = ref<Array<any>>([])
  /** 查询对话窗 */
  diaResult = ref<KDialogResult>()
  /**导入确认窗 */
  affirmPop = ref<any>()
  /**下载数据 */
  loadData = ref<any>()
  constructor(options: ViewModelOptions<KRequireTreePropType>) {
    super(options)
    watch(
      () => this.props.folderId,
      () => {
        this.refRequireTree.value?.refresh()
      }
    )
  }

  viewDidMount() {}

  refresh() {
    this.refRequireTree.value?.refresh()
  }

  toolClick(event: TreeViewMenuClickedEventArgs<any>) {
    if (event.name == undefined) {
      return
    }
    switch (event.name) {
      case EnumToolStripItemKeys.NEW:
        this.addDir(event.node)
        break
      case EnumToolStripItemKeys.DELETE:
        this.removeDir(event.node)
        break
      case EnumToolStripItemKeys.REFRESH:
        this.refreshNode(event.node)
        break
      case EnumToolStripItemKeys.SORT:
        this.sortDir(event.node)
        break
      case EnumToolStripItemKeys.FIND:
        this.openSearch(event.node)
        break
      case EnumRequirementToolStripItemKeys.TOOL_STRIP_WORD_EXPORT:
        this.exportData(event.node, 'docx')
        break
      case EnumRequirementToolStripItemKeys.TOOL_STRIP_EXCEL_EXPORT:
        this.exportData(event.node, 'xlsx')
        break
      case EnumToolStripItemKeys.IMPORT:
        this.affirmImport(event.node)
        break
      case EnumToolStripItemKeys.EXPORT:
        this.exportData(event.node, 'docx')
        break
      case EnumToolStripItemKeys.LOOK:
        this.previewWord(event.node)
        break
      default:
        break
    }
  }

  /**
   * 新建需求
   * @param node  节点
   *
   */
  addDir(node: IKTreeNode) {
    if ((node?.modelCode && node?.modelCode.indexOf('Customer') > -1) || (node.type && node?.type.indexOf('Customer') > -1)) {
      KDialog.show({
        title: '创建原始需求项',
        props: {
          formValue: {
            id: node?.id,
            rdmExtensionType: node?.type || node?.modelCode
          }
        },
        size: { height: 800, width: 1200 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KCustomerNeedsSectionCreate,
        onClosing: async (event: KDialogClosingEvent) => {
          const needViewModel = event.viewModel as KProductNeedsSectionCreateViewModel
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          if (needViewModel.validate()) {
            const res = await needViewModel.save()
            if (res && res.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '添加成功'
              })
              this.refreshNode(node)
            } else {
              KNotification.warn({
                message: '添加失败',
                description: res.message
              })
              event.cancel = true
              return
            }
          } else {
            event.cancel = true
            return
          }
        }
      })
    } else {
      KDialog.show({
        title: '创建产品需求项',
        props: {
          formValue: {
            id: node?.id,
            rdmExtensionType: node?.type || node?.modelCode
          }
        },
        size: { height: 800, width: 1200 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        content: KProductNeedsSectionCreate,
        onClosing: async (event: KDialogClosingEvent) => {
          const needViewModel = event.viewModel as KCustomerNeedsSectionCreateViewModel
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return
          if (needViewModel.validate()) {
            const res = await needViewModel.save()
            if (res && res.code == EnumRequestCode.SUCCESS) {
              KNotification.success({
                title: '系统提示',
                content: '添加成功'
              })
              this.refreshNode(node)
            } else {
              KNotification.warn({
                message: '添加失败',
                description: res.message
              })
              event.cancel = true
              return
            }
          } else {
            event.cancel = true
            return
          }
        }
      })
    }
  }

  /**
   * 删除需求
   * @param node 节点
   */
  removeDir(node: IKTreeNode) {
    KModal.delete({
      title: '目录删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: async () => {
        const childNodes = this.refRequireTree.value?.getChildNodes(node.id)
        if (childNodes && childNodes.length != 0) {
          KNotification.warn({
            message: '删除失败',
            description: '请先删除子需求'
          })
          return
        }
        const param = {
          data: [node.id]
        }
        Api.post('requirement', node.type, 'delete', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refRequireTree.value?.removeNode(node.id)
            KNotification.success({
              title: '系统提示',
              content: '目录删除成功'
            })
          } else {
            KNotification.warn({
              message: '删除失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  async sortDir(node: IKTreeNode) {
    if (!node.leaf && !node.children) {
      await node.expand()
    }

    /** 子 */
    const children = (node.children || []) as Array<KTreeNode>
    if (children.length === 0) {
      KNotification.warn({
        message: '系统提示',
        description: '无子需求排序'
      })
      return
    }
    /** 子零件 */
    const childParts = children.map((a: any) => {
      return { id: a.id, name: a.name, orderId: a.orderId } as any
    })
    const { modelCode, id } = node
    /** 排序结果 */
    const result = await this.sortChildParts({
      objParam: { modelCode, id },
      childParts: childParts
    })

    if (result) {
      await this.refreshNode(node)
    }
  }

  /**
   * 在同一个父节点下是否存在重名
   * @param parentId 父节点id
   * @param currentNodeId 当前节点id
   * @param newName 新名字
   */
  async ifExistUnderParentNode(parentId: string, newName: string, currentNodeId?: string): Promise<boolean> {
    const childNodes = this.refRequireTree.value?.getChildNodes(parentId)
    if (!childNodes) {
      return false
    }
    const duplicateNameNode = childNodes?.find(node => node.name == newName)
    //不存在重名
    if (!duplicateNameNode) {
      return false
      //存在重名，但是新名称与当前节点名称相同
    } else if (currentNodeId && duplicateNameNode.id == currentNodeId) {
      return false
    }
    return true
  }

  isDynamicSubfolder(node: IKTreeNode) {
    return node?.enableDynamicSubfolder == true
  }

  refreshRootNode(node: IKTreeNode) {
    this.refRequireTree.value?.refresh(node.parentId, true)
  }
  /**
   * 刷新子节点
   */
  refreshNode(node: IKTreeNode) {
    this.refRequireTree.value?.refresh(node.key, true)
  }

  /**
   * 清空查询数据
   */
  refreshQueryCriteria() {
    this.queryIndex.value = 0
    this.queryData.value = []
  }
  /**
   * 搜索需求
   */
  async openSearch(node: IKTreeNode) {
    this.diaResult.value = KDialog.show({
      title: '查找',
      size: { width: 500, height: 370 },
      props: {
        onClose: () => this.closeDialog(),
        onFindPrevious: (params: any, reFind: boolean) => this.findPrevious(params, reFind),
        onFindNext: (params: any, reFind: boolean) => this.findNext(params, reFind),
        // onSelectAll: (params: any, reFind: boolean, onSuccess?: () => void) => this.selectAll(params, reFind, onSuccess),
        // onCancelSelectAll: () => this.cancelSelectAll(),
        onRefershData: () => this.refershSearchData(),
        onFindTop: (params: any, reFind: boolean) => this.findTop(params, reFind),
        onFindBottom: (params: any, reFind: boolean) => this.findBottom(params, reFind)
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      showCancel: false,
      showConfirm: false,
      floatFooter: true,
      content: KRequireSearchFrom,
      onClosing: async (event: KDialogClosingEvent) => {
        // 清除查询节点
        // this.queryNode.value = undefined
        return
      }
    })
  }

  /**
   * 获取选中的树节点
   * @returns 选中的树节点
   */
  getSelectNode() {
    return this.refRequireTree.value?.getSelectedNode()
  }
  getContainer() {
    return this.refFolderTreeEl.value
  }

  // 找上一处
  findPrevious(params: any, reFind: boolean) {
    this.find(params, reFind, 1)
  }

  // 最上
  findTop(params: any, reFind: boolean) {
    this.find(params, reFind, 3)
  }

  // 找下一处
  findNext(params: any, reFind: boolean) {
    this.find(params, reFind, 2)
  }

  // 最下
  findBottom(params: any, reFind: boolean) {
    this.find(params, reFind, 4)
  }

  // 选中全部
  selectAll(params: any, reFind: boolean, onSuccess?: () => void) {
    // this.props.selectAll(params, reFind, onSuccess)
  }

  // 取消选中全部
  cancelSelectAll() {
    // this.props.cancelSelectAll()
  }

  // 关闭搜索窗
  closeDialog() {
    // this.props.clearQueryNode()
    this.diaResult.value?.close()
  }

  // 关闭级联
  closeCheckStrictly() {
    // this.notCheckStrictly.value = false
  }

  // 清洗数据
  refershSearchData() {
    // this.props.refershSearchData()
  }

  /** 按搜索名称查询 */
  async searchProperty() {
    const qry = this.queryCriteria.value
    const index = this.queryIndex.value
    const queryData = this.queryData.value
    const rootNode = this.refRequireTree.value?.getRootNodes()[0] as KTreeNode
    if (qry == '') {
      this.refreshQueryCriteria()
    }

    // /** 判断queryData是否为空 */
    if (queryData.length == 0 || queryData[index] == undefined) {
      // 获取根据点
      const node = this.refRequireTree.value?.getRootNodes()[0]
      const param = {
        data: [
          {
            key: '',
            name: qry,
            number: '',
            maxLevel: 99,
            root: {
              id: node?.id,
              clazz: node?.modelCode
            },
            searchFirst: 'breathFirstSearch'
          }
        ]
      }
      const result = await Api.post('requirement', node?.modelCode, 'findRequirementPath', param)
      if (result && result.code == EnumRequestCode.SUCCESS) {
        // 接口成功
        // 判断是否查询到属性
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询属性不存在'
          })
          this.queryIndex.value = 0
          return
        }

        if (result.data.length > 0) {
          const data = result.data
          this.queryData.value = data
          // 左侧树勾选当前选中的节点，会触发右侧面板更新
          this.refRequireTree.value?.expandByPath(rootNode!, data[0], true)
          this.queryIndex.value = 1
          return
        }
      }
    } else {
      this.refRequireTree.value?.expandByPath(rootNode!, queryData[index], true)
      this.queryIndex.value = index + 1
    }
  }

  /**
   * 结构树查找
   * 查找上(下)一处(place: 1(上) 2 (下) 3(最上) 4(最下))
   */

  async find(params: any, reFind: boolean, place: number) {
    const name = params?.name
    const number = params?.number
    const maxLevel = params?.maxLevel
    const isFindAll = params?.isFindAll
    const index = this.queryIndex.value
    const queryData = this.queryData.value
    const originRootNode = this.refRequireTree.value?.getRootNodes()[0] as KTreeNode
    if (name == '' && number == '') {
      // 参数为空不做查询
      this.refreshQueryCriteria()
      return
    }
    const root = this.refRequireTree.value?.getRootNodes()[0]
    // 判断是否需要查询
    if (reFind || queryData.length == 0) {
      const param = {
        name: name,
        number: number,
        maxLevel: maxLevel == 0 ? 99 : maxLevel,
        root: {
          id: root?.id,
          clazz: root?.modelCode
        },
        searchFirst: 'depthFirstSearch'
      }
      const result = await Api.post('requirement', root?.modelCode, 'findRequirementPath', { data: [param] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        if (result.data.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '查询结果不存在'
          })
          this.queryIndex.value = 0
          this.queryData.value = []
          return
        }
        if (result.data.length > 0) {
          const data = result.data
          this.queryData.value = data
          // 左侧树勾选当前选中的节点，会触发右侧面板更新
          this.queryIndex.value = place == 1 || place == 4 ? data.length - 1 : 0
          this.refRequireTree.value?.expandByPath(originRootNode!, data[this.queryIndex.value], true)
          return
        }
      } else {
        KNotification.error({
          title: '查找失败',
          content: result.message || '查找失败',
          details: result.detail
        })
        return
      }
    } else {
      if (place == 1) {
        this.queryIndex.value = queryData[index - 1] == undefined ? queryData.length - 1 : index - 1
      } else if (place == 2) {
        this.queryIndex.value = queryData[index + 1] == undefined ? 0 : index + 1
      } else if (place == 3) {
        this.queryIndex.value = 0
      } else {
        this.queryIndex.value = queryData.length - 1
      }
      if (!isFindAll) {
        this.refRequireTree.value?.expandByPath(originRootNode!, queryData[this.queryIndex.value], true)
      } else {
        this.refRequireTree.value?.expandByPath(originRootNode!, queryData[this.queryIndex.value], true)
      }
    }
  }

  /**
   * 排序子零件
   * @param params
   * @returns
   */
  async sortChildParts(params: any) {
    /** 获取排序结果 */
    const result = await KOrderAdjustPanel.show<any>({
      rowKey: 'id',
      list: params.childParts,
      fields: [
        { title: '名称', id: 'name' },
        { title: '描述', id: 'description' }
      ]
    })

    // 如果取消排序
    if (result.dialogResult == EnumDialogResult.Cancel || result.dialogResult == EnumDialogResult.Close) {
      return false
    }

    const data: any = []
    result.result.sortedItems.map((a, index) => {
      data.push({
        id: a.id,
        sortNum: index + 1
      })
    })
    const root = this.refRequireTree.value?.getRootNodes()[0]

    const modelType = root?.modelCode || params.objParam.modelCode
    /** 排序 */
    const reqParams = {
      data: [data]
    }
    const sortResult = await Api.post('requirement', `${modelType}Section`, 'batchUpdate', reqParams)
    if (sortResult && sortResult.code == EnumRequestCode.SUCCESS) {
      return true
    } else {
      KNotification.warn({
        message: '系统提示',
        description: sortResult.message
      })
      return false
    }
  }

  /** 导入需求 */
  affirmImport(node: RequireTreeNode) {
    if (node.children?.length) {
      this.affirmPop.value = KModal.confirm({
        title: '导入文件',
        icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
        content: '您将进行导入需求，此操作将覆盖当前所有子需求，是否继续？',
        onOk: async () => {
          this.importData(node)
        }
      })
    } else {
      this.importData(node)
    }
  }

  /** 导出需求 */
  async exportData(node: RequireTreeNode, type: string) {
    const dialog = KDialog.info({ content: '正在下载数据，请稍后...', title: '提示', showOk: false })
    const id = ObjectHelper.getGuid()
    const identity = AppContext.current.getIdentity()
    const base64Token = btoa(identity!.token).replaceAll('=', '')
    const url = `${ConfigClientSrv.getFileApiBaseUrl}/requirement/export/${base64Token}/${node.modelCode}/${id}/${node.id}.${type}`
    // a标签下载
    const link = document.createElement('a')
    link.href = url
    link.download = `${node.name}.docx`

    link.click()
    let progress = 0
    let timer: any = null
    try {
      fetch(`${url}`)
      timer = setInterval(async () => {
        const result = await Api.post('requirement', 'Requirement', 'getExportProcess', { data: [id] })
        if (result && result.code == EnumRequestCode.SUCCESS && result.data && result.data != '-1') {
          if (progress < Number(result.data)) {
            progress = Number(result.data).toFixed(0) as any
          }
          if (Number(progress) === 100) {
            setTimeout(() => {
              clearInterval(timer)
              dialog.destroy()
            }, 500)
          } else {
            dialog.update({ content: `下载进度：${progress}%` })
          }
        } else {
          clearInterval(timer)
          dialog.destroy()
        }
      }, 800)
    } catch (error) {
      if (timer) {
        clearInterval(timer)
        dialog.destroy()
        KNotification.error({
          title: '系统错误',
          content: error as string
        })
      }
    }
  }

  /** 预览需求  */
  async previewWord(node: RequireTreeNode) {
    const tabKey = `${node.name}##预览`
    const identity = AppContext.current.getIdentity()
    const base64Token = btoa(identity!.token).replaceAll('=', '')
    const id = ObjectHelper.getGuid()
    CommonClientSrv.openPage(
      '对象浏览',
      KModelBrowser,
      {
        loading: true,
        guid: id,
        type: 'requirement',
        url: `${ConfigClientSrv.getFileApiBaseUrl}/requirement/export/${base64Token}/${node.modelCode}/${id}/${node.id}.docx`,
        fileType: EnumKmVueFileType.Office
      },
      tabKey
    )
  }

  /** 下载文件 */
  async downFileData(node: RequireTreeNode) {
    const param = {
      id: node?.id,
      clazz: node?.modelCode
    }
    const dialog = KDialog.info({ content: '正在下载数据，请稍后...', title: '提示', showOk: false })
    const resDoc = await Api.post('requirement', 'Requirement', 'exportToWord', { data: [param] })
    const str = atob(resDoc.data.file)
    let len = str.length
    const arr = new Uint8Array(len)
    while (len--) arr[len] = str.charCodeAt(len)
    const url = window.URL.createObjectURL(new Blob([arr]))

    this.loadData.value = {
      url,
      name: resDoc.data.fileName
    }
    dialog.destroy() // 销毁对话框
    return this.loadData.value
  }

  /** 导入需求 */
  async importData(node: RequireTreeNode) {
    this.affirmPop.value && this.affirmPop.value?.close()
    KDialog.show({
      title: '导入文件',
      size: { width: 800, height: 600 },
      props: {
        modelName: node.modelCode,
        attributeName: 'secondary'
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KObjectStructFileUpload,
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const fileViewModel = event.viewModel as KObjectStructFileUploadViewModel
        const { fileId, type } = fileViewModel.getFileId()
        if (!fileId) {
          KNotification.warn({
            message: '系统提示',
            description: '未选择文件'
          })
          event.cancel = true
          return
        }
        const param = {
          id: node?.id,
          clazz: node?.modelCode
        }
        const dialog = KDialog.info({ content: '正在导入数据，请稍后...', title: '提示', showOk: false })
        try {
          const res = await Api.post('requirement', 'Requirement', 'importFileForCreate', { data: [param, fileId, type] })
          dialog.destroy()
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refresh()
          } else {
            KNotification.error({
              title: '系统错误',
              content: res.message
            })
          }
        } catch (error) {
          dialog.destroy()
        }
      }
    })
  }
}
