import {
  Api,
  CommonClientSrv,
  EnumQueryConditionJoiner,
  EnumQueryConditionOperator,
  EnumRequestCode,
  KObjectPanelPropType,
  ObjectClientSrv,
  EBF_IOC_KEY
} from '@kmsoft/ebf-common'
import { AgentManager, AgentRequestModel } from '@kmsoft/ebf-common/src/client-agent'
import { AppContext, EnumDialogResult, KDialog, KDialogClosingEvent, VNodeProps, eventEmitter } from '@kmsoft/upf-core'
import { resolveComponent } from 'vue'
import * as Handlers from '.'
import { KPersonalWorkspace, KPersonalWorkspaceViewModel } from '../../../../../../../ebf-folder'
import { DocClientSrv } from '../../../../../client-srv'
import { KDocEditPanel, KDocStructureManage } from '../../../../../controls'
import { integrateConfig } from '../config/integrateConfig'
import { DpMsgClientSrv } from '../DpMsgClientSrv'
import { DpMsgXml } from '../DpMsgXml'
import { EnumExApp } from '../EnumExApp'
import { Application, IOTypeEnum } from '../Parameter'

export class DpMsgHandler {
  private static loginRequests = new Map<
    string,
    {
      request: AgentRequestModel
      afterLogin: (token: string) => Promise<void>
    }
  >()

  private static registrationPromise: Promise<void> | null = null
  private static registrationResolve: (() => void) | null = null
  private static pendingToken: string | null = null

  /**消息处理注册 */
  public static async register() {
    /**根据配置注册消息处理事件 */
    const integrateAppList = integrateConfig.getAppApi()
    for (const integrateApp of integrateAppList) {
      for (const integrateMethod of integrateApp.methods) {
        if (integrateMethod.handler == this.name) {
          AgentManager.modelHandlerMaps.set(
            `${integrateApp.id}.${integrateMethod.id}`,
            (DpMsgHandler as any)[integrateMethod.name]
          )
        } else {
          AgentManager.modelHandlerMaps.set(
            `${integrateApp.id}.${integrateMethod.id}`,
            (Handlers as any)[integrateMethod.handler][integrateMethod.name]
          )
        }
      }
    }
  }

  public static registerLoginRequest(
    handlerId: string,
    request: AgentRequestModel,
    afterLogin: (token: string) => Promise<void>
  ) {
    // 如果有待处理的token，直接执行afterLogin
    if (this.pendingToken) {
      const token = this.pendingToken
      this.pendingToken = null
      afterLogin(token)
      return
    }

    if (!this.registrationPromise) {
      this.registrationPromise = new Promise(resolve => {
        this.registrationResolve = resolve
      })
    }

    this.loginRequests.set(handlerId, {
      request,
      afterLogin
    })

    if (this.registrationResolve) {
      this.registrationResolve()
    }
  }

  public static async handleAfterLogin(token: string) {
    if (!this.registrationPromise) {
      this.pendingToken = token
      return
    }

    await this.registrationPromise

    const handlers = Array.from(this.loginRequests.values())
    if (handlers.length > 0) {
      const lastHandler = handlers[handlers.length - 1]
      await lastHandler.afterLogin(token)
    }

    // 清理状态
    this.registrationPromise = null
    this.registrationResolve = null
  }
}
