import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-744694a0"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["onClick"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_data_grid_template_column = _resolveComponent("k-data-grid-template-column")!
  const _component_k_data_grid_text_box_column = _resolveComponent("k-data-grid-text-box-column")!
  const _component_k_tag = _resolveComponent("k-tag")!
  const _component_k_data_grid = _resolveComponent("k-data-grid")!

  return (_openBlock(), _createBlock(_component_k_data_grid, {
    dataSource: _ctx.$vm.gridData,
    ref: "refDataGrid"
  }, {
    columns: _withCtx(() => [
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "编码",
        dataPropertyName: "number",
        align: "center",
        width: "150"
      }, {
        default: _withCtx(({ row }) => [
          _createElementVNode("span", null, [
            _createElementVNode("a", {
              href: "javascript:void(0)",
              onClick: ($event: any) => (_ctx.$vm.openObjTab(row))
            }, _toDisplayString(row.number), 9, _hoisted_1)
          ])
        ]),
        _: 1
      }),
      _createVNode(_component_k_data_grid_text_box_column, {
        headerText: "名称",
        name: "name",
        dataPropertyName: "name",
        align: "center",
        width: "150"
      }),
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "阶段",
        dataPropertyName: "phase",
        align: "center",
        width: "80"
      }, {
        default: _withCtx(({ cellValue }) => [
          cellValue
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.EnumPhaseState[cellValue]), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_3, "--"))
        ]),
        _: 1
      }),
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "实体类型",
        dataPropertyName: "rdmExtensionType",
        align: "center",
        width: "150"
      }, {
        default: _withCtx(({cellValue, row}) => [
          _createTextVNode(_toDisplayString(_ctx.clsCodeMap.get(cellValue) + _ctx.$vm.getView(row)), 1)
        ]),
        _: 1
      }),
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "版本",
        dataPropertyName: "version",
        align: "center",
        width: "80"
      }, {
        default: _withCtx(({ row }) => [
          (row.version || row.iteration)
            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(row.version) + "." + _toDisplayString(row.iteration), 1))
            : (_openBlock(), _createElementBlock("span", _hoisted_5, "--"))
        ]),
        _: 1
      }),
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "状态",
        dataPropertyName: "lifecycleStateCode",
        align: "center",
        width: "auto"
      }, {
        default: _withCtx(({ cellValue }) => [
          cellValue
            ? (_openBlock(), _createBlock(_component_k_tag, {
                key: 0,
                color: _ctx.EnumLifecycleStateColor[cellValue]
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.EnumLifecycleState[cellValue]), 1)
                ]),
                _: 2
              }, 1032, ["color"]))
            : (_openBlock(), _createElementBlock("span", _hoisted_6, "--"))
        ]),
        _: 1
      }),
      _createVNode(_component_k_data_grid_text_box_column, {
        headerText: "检入标记",
        dataPropertyName: "checkInState",
        align: "center",
        width: "auto"
      }),
      _createVNode(_component_k_data_grid_text_box_column, {
        headerText: "创建时间",
        dataPropertyName: "createTime",
        align: "center",
        width: "auto"
      }),
      _createVNode(_component_k_data_grid_template_column, {
        headerText: "创建者",
        dataPropertyName: "creator",
        align: "center",
        width: "auto"
      }, {
        default: _withCtx(({ cellValue}) => [
          _createTextVNode(_toDisplayString(cellValue.split(' ')[0]), 1)
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["dataSource"]))
}