import { EnumDialogResult, IPageResult } from '@kmsoft/upf-core'
import { EnumObjCopyMode } from './enums'
import { IClsOption, ObjectCreateDialogSaveBeforeEvent, ObjectCreateFormSaveResult } from '../../../controls'

/**
 * 操作成功回调
 */
export type SuccessCallBack = (response?: any) => void

/** 业务对象参数 */
export type ObjBusinessParam = {
  /** 对象类Code */
  modelCode: string
  /**分组 */
  modelGroup?: string
  /** 对象Id */
  id: string
  /** 版本组Id */
  masterId?: string
  /**迭代id */
  iteratorId?: string
  /**分支id*/
  branchId?: string
  /**扩展类型 */
  extensionType?: string
  /**视图 */
  partView?: string
  /** 生命周期状态 */
  lifecycleStateCode?: string
  /**kiaguid权限码*/
  kiaguid?: string
}

/** 业务对象 */
export type ObjBusinessBase = ObjBusinessParam & {
  /** 属性 */
  [key: string]: any
  // properties: Record<string, any>
  /** 附属表格 */
  attachedTables?: Record<string, Array<Record<string, any>>>
  /** 附属文件 */
  attachedFiles?: Record<string, Array<Record<string, any>>>
}

/**
 * 属性面板数据
 * @description 目前仅考虑单个业务对象的场景
 */
export type ObjBusinessResult = ObjBusinessBase

/** 业务对象表格属性 */
export type ObjBusinessTableProperty = {
  /** 表格 */
  objDataTable: Array<Record<string, any>>
  /** 对象Id */
  objID: string
  /** 属性名 */
  propertyName: string
  /** 表格Id */
  tableId: string
  /** 表格名 */
  tableName: string
}

/** 对象（加入流程后）流程相关信息 */
export type ObjParamInFlow = {
  /** 流程步骤名称 */
  // flowStepName: string
  /** 流程实例状态 */
  // flowObjStatus: FlowObjStatus
}

/** 对象包装器 */
export type ObjBusinessWrapper = {
  /** 对象名称类型 */
  name: string
  /** 业务对象 */
  objBusiness: ObjBusinessBase
  /** 对象（加入流程后）流程相关信息 */
  paramInFlow?: ObjParamInFlow
}

/** 业务对象保存参数 */
export type SaveObjBusinessParams = Partial<ObjBusinessBase> & {
  /** 对象属性 */
  // properties: Record<string, any>
  /** 附属表格 */
  attachedTables?: Record<
    string,
    | Array<Record<string, any>>
    | {
        deleted: Array<string>
        inserted: Array<Record<string, any>>
        modified: Array<Record<string, any>>
      }
  >
  /** 附属文件 */
  attachedFiles?: Record<
    string,
    | Array<Record<string, any>>
    | {
        deleted: Array<string>
        inserted: Array<Record<string, any>>
        modified: Array<Record<string, any>>
      }
  >
}

/** 业务对象保存参数 */
export type CreateObjBusinessParams = SaveObjBusinessParams & {
  /** 创建方式 */
  objCopyMode?: EnumObjCopyMode
}

export type IObjectPageResult = IPageResult & {
  applyPurviewRowNumber?: number
  noPuviewRowNumber?: number
  pageCount?: number
}

/** 业务对象保存结果 */
export type ObjBusinessSaveResult = {
  success: boolean
  objBusiness: Array<ObjBusinessBase>
}

/** 对象批量更新参数 */
export type UpdateObjBusinessParam = {
  objParam: ObjBusinessParam
  allProperties: Record<string, any>
  properties: Record<string, any>
}
/**
 * 虚拟业务对象参数
 */
export type VirtualObjBusinessParam = ObjBusinessParam & {
  /** 父对象Id */
  parentObjID?: string
  /** 子对象Id */
  childObjID?: string
}

/** 创建对象结果 */
export type CreateObjectResult = {
  /** 是否成功 */
  success: boolean
  /** 关闭按钮值 */
  dialogResult: EnumDialogResult | string
  /** 业务对象 */
  objBusiness?: ObjBusinessBase
}

/** 表格数据更新模型 */
export type TableUpdateProperties = {
  /** 删除的行id  */
  deletedRows?: Array<string>
  /** 修改过的行 */
  modifiedRows?: Array<Record<string, any>>
  /** 新增的行 */
  insertedRows?: Array<Record<string, any>>
}

/** 附属表格更新值 */
export type AttachedTableModifiedValue = TableUpdateProperties & {
  /** 对象类id */
  modelCode: string
  /** 表id */
  tableId: string
}

/**对象标识 */
export type ObjectIdentity = {
  modelCode?: string
  group?: string
  id?: string
}

/**多版本对象标识 */
export type MVVersionObjectIdentity = {
  modelCode?: string
  id?: string
  group?: string
  masterId?: string
  iteratorId?: string
}

/**对象关联标识。*/
export type ObjectRelation = ObjectIdentity & {
  /**关联 Id。*/
  relationId?: string
  /**关联对象类编码。*/
  relationClsCode?: string
  [x: string]: any
}

/**对象类关系分组。*/
export type ObjectRelationGroup = {
  origin: string
  destination: string
  displayColumn: string
  name: string
}

/**对象类关系定义。*/
export type ObjectRelationDefination = {
  /**定义分组。*/
  group: string
  /**定义 Id。*/
  id: string
  /**定义编码。*/
  code: string
  /**定义对象类编码。*/
  modelCode: string
  /**定义显示。*/
  name: string
  /**关联对象类 Id。*/
  relationClsId: string
  /**关联对象类编码。*/
  relationClsCode: string
  /**源列前缀，对于正向关联，为 source，对于反向关联，为 target。*/
  originColumnPrefix: string
  /**源对象类 Id。*/
  originClsId: string
  /**源对象类编码。*/
  originClsCode: string
  /**源对象是否精确匹配。*/
  originAccurate: number
  /**目标列前缀，对于正向关联，为 target，对于反向关联，为 source。*/
  destinationColumnPrefix: string
  /**目标对象类 Id。*/
  destinationClsId: string
  /**目标对象类编码。*/
  destinationClsCode: string
  /**目标对象是否精确匹配。*/
  destinationAccurate: number
}

/**打开对象创建面板参数 */
export type ObjectCreateDynamicPanelParams = {
  clsOptions?: Array<IClsOption>
  folderId?: string
  showObjClsCodes?: Array<string>
  defaultSelectClsCode?: string
  /** 关闭前事件 */
  onClosingBefore?: (event: ObjectCreateDialogSaveBeforeEvent) => void
  /** 确认回调 */
  onConfirm?: (result: ObjectCreateFormSaveResult) => void
  /** 应用回调 */
  onApply?: (result: ObjectCreateFormSaveResult) => void
  /** 关闭回调 */
  onClosed?: () => void
}

/**模型编码和模型分组的映射 */
export const ObjectGroupMapping = new Map<string, Array<string>>([
  [
    'part',
    [
      'Part',
      'StandardPart',
      'Standardparts',
      'Electroniccomponents',
      'Material',
      'FinishedGoods',
      'Componentsandparts',
      'Structuralsemifinishedproducts',
      'Electronicsemifinishedproducts',
      'Electroniccomponents'
    ]
  ],
  ['doc', ['Document', 'Distribution']],
  ['baseline', ['ManagedBaseline']],
  ['change', ['ChangeActivity', 'ChangeIssue', 'ChangeRequest', 'ChangeOrder', 'PromoteActivity']],
  [
    'requirement',
    [
      'CustomerNeeds',
      'CustomerNeedsSection',
      'ProductNeeds',
      'ProductNeedsSection',
      'ValidationMetrics',
      'VerificationPlan',
      'RequirementChangeRequest'
    ]
  ]
])

/**
 * 对象类map
 */
export let clsCodeMap = new Map([
  ['Part', 'Part'],

  ['Document', '文档'],
  ['OrdinaryDocument', '普通文档'],
  ['ManagedBaseline', '受管理的基线'],
  ['Projectapprovaldoc', '项目立项文件'],
  ['ProductManualDocument', '产品说明书'],
  ['RequirementSpecification', '需求规格说明书'],
  ['ReviewReportDocument', '评审报告'],
  ['TestReportDocument', '测试报告'],
  ['StandardDocument', '标准文件'],
  ['ManualDocument', '操作手册'],
  ['SelectionGuideDocument', '选型手册'],
  ['MarketingReportDocument', '营销报告'],
  ['CISDocument', '元器件文档'],
  ['WorkinstructionTemplate', '工艺作业指导书模板'],
  ['ElectronicDocument', '电子文档'],

  ['Standardparts', '标准件'],
  ['Electroniccomponents', '电子元器件'],
  ['Componentsandparts', '零部件'],
  ['Structuralsemifinishedproducts', '结构半成品'],
  ['Electronicsemifinishedproducts', '电子半成品'],
  ['FinishedGoods', '成品'],
  ['Material', '材料'],

  ['UniversalParts', '通用件'],
  ['ChangeActivity', '变更活动'],
  ['ChangeOrder', '变更单'],
  ['ChangeRequest', '变更请求'],
  ['ChangeIssue', '变更问题报告'],
  ['Distribution', '电子发放单'],
  ['PromoteActivity', '转阶段活动'],
  ['PMS_PLAN', '计划'],
  ['PMS_PROJECT', '项目'],
  ['PMS_ISSUE', '问题'],
  ['PMS_RISK', '风险'],
  ['CustomerNeeds', '原始需求'],
  ['ProductNeeds', '产品需求'],
  ['RequirementChangeRequest', '需求变更过请求'],
  ['CustomerNeedsSection', '原始需求项'],
  ['ProductNeedsSection', '产品需求项'],
  ['ValidationMetrics', '验证指标'],
  ['VerificationPlan', '验证计划'],
  ['RequirementChangeRequest', '需求变更请求'],
  ['PlanActivity', '计划活动'],
  ['Chanpin', 'CNC产品说明书'],
  ['Cpsms', '产品说明书'],
  ['Shizhibaogao', '试制报告'],
  ['Ceshibaogao', '测试报告'],
  ['Simulation', '仿真测试报告'],
  ['Yijieduan', '一阶段仿真报告'],
  ['SubDocumentTest111', '子类型属性验证'],
  ['Qmlione', '二层计划单qmli'],
  ['Qmlitwo2', '三层计划单qmli2'],
  ['Qmlitwo', '三层计划单qmli'],
  ['Qmthree2', '四层计划单qmli2'],
  ['Qmthree', '四层计划单qmli'],
  ['Qmthree4', '四层计划单qmli4'],
  ['Qmthree3', '四层计划单qmli3'],
  ['DocumentSub17', '文档子类型17'],
  ['DocumentSub13', '文档子类型13'],
  ['TestLCFDocument', '生命周期模板继承'],
  ['ProjectDocument', '四层计划单qmli3'],
  ['ProjectDocument2', '项目卡片'],
  ['ProjectDocument22', '项目卡片22'],
  ['WorkInstruction', '工艺作业指导书'],
  ['OrdinaryDocument', '普通文档'],
  ['WorkInstructionTemplate', '工艺作业指导书模板'],
  ['ApifoxDocsub1726802039', 'Apifox文档子类型1726802039'],
  ['ApifoxDocsub1727053179', 'Apifox文档子类型1727053179'],
  ['Ceshiwendang001', '测试文档001'],
  ['Ceshiwendang002', '测试文档002'],
  ['Xxsc', '选型手册'],
  ['Zhuanliangchan', '转量产交付件'],
  ['SubDocumentTest', '文档子类型验证'],
  ['Bear2', '熊二']
])

export function updateClsCodeMap(map: Map<string, string>) {
  clsCodeMap = map
}

/**
 * 对象是否有版本map
 */
export const clsCodeVersionMap = new Map([
  ['Part', true],
  ['Document', true],
  ['Standardparts', true],
  ['Electroniccomponents', true],
  ['Material', true],
  ['FinishedGoods', true],
  ['Componentsandparts', true],
  ['Structuralsemifinishedproducts', true],
  ['Electronicsemifinishedproducts', true],
  ['OrdinaryDocument', true],
  ['UniversalParts', true],
  ['ManagedBaseline', false],
  ['ChangeActivity', false],
  ['ChangeOrder', false],
  ['ChangeRequest', false],
  ['ChangeIssue', false],
  ['Distribution', false],
  ['PMS_PLAN', false],
  ['PMS_PROJECT', false],
  ['PMS_ISSUE', false],
  ['PMS_RISK', false]
])

export const partClsCodeMap = new Map([
  ['Part', 'Part'],
  ['Standardparts', '标准件'],
  ['Electroniccomponents', '电子元器件'],
  ['Componentsandparts', '零部件'],
  ['Structuralsemifinishedproducts', '结构半成品'],
  ['Electronicsemifinishedproducts', '电子半成品'],
  ['FinishedGoods', '成品'],
  ['Material', '材料']
])
