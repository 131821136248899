import { createVNode } from 'vue'
import { KPanel, VNodeProps, defineView } from '@kmsoft/upf-core'
import { KObjectPropertyEditPanelBasePropOptions, KObjectPropertyEditPanelBaseEventEmits } from './interface'
import KObjectPropertyEditPanelBaseViewModel from './KObjectPropertyEditPanelBaseViewModel'
import {
  KObjectPropertyPanel,
  KObjectPropertyPanelEventEmitsType,
  KObjectPropertyPanelPropType
} from '../../../object-property-panel'
import { EnumLayoutSchemaType } from '../../../../client-srv'
import { KObjectToolStrip, KObjectToolStripEventEmitsType, KObjectToolStripPropType } from '../../../object-tool-strip'
import '../../style/index.less'

export default defineView({
  name: 'KObjectPropertyEditPanelBase',
  props: KObjectPropertyEditPanelBasePropOptions,
  emits: KObjectPropertyEditPanelBaseEventEmits,
  viewModel: KObjectPropertyEditPanelBaseViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    return () => {
      /** 渲染面板 */
      const renderPanel = () => {
        /** 面板插槽 */
        const panelSlots = {
          header: () => {
            /** 工具栏参数 */
            const tooltripProps: VNodeProps<KObjectToolStripPropType, KObjectToolStripEventEmitsType> = {
              ref: vm.refObjectToolStrip,
              items: vm.toolStripItems.value,
              kiaguid: props.objParam.kiaguid,
              modelCode: props.objParam.modelCode,
              onItemClicked: vm.onToolStripItemClicked,
              onItemChange: vm.onToolStripItemChange
            }
            return createVNode(KObjectToolStrip, tooltripProps)
          },
          default: () => {
            /** 属性面板参数 */
            const objectPropertyPanelProps: VNodeProps<KObjectPropertyPanelPropType, KObjectPropertyPanelEventEmitsType> = {
              ref: vm.refObjPropertyPanel,
              schemaType: props.schemaType as any,
              modelCode: props.objParam.modelCode,
              modelGroup: props.objParam.modelGroup,
              extendedAttributes: props.extendedAttributes,
              extendedPosition: props.extendedPosition,
              loadData: vm.onLoadData,
              readonly: vm.readonly.value,
              onChange: vm.onFormChange,
              onLoaded: () => {
                vm.emit('loaded')
              }
            }
            return <div class="k-object-property-edit-panel">{createVNode(KObjectPropertyPanel, objectPropertyPanelProps)}</div>
          }
        }

        return createVNode(KPanel, { class: 'k-object-property-tab' }, panelSlots)
      }

      return renderPanel()
    }
  }
})
