import { EnumAttributeType, EnumControlElementType, EnumDataType } from '@kmsoft/upf-core'
import { BOOLEAN_CANDIDATE } from '@kmsoft/ebf-view-engine'
import { controlOptions, DEFAULT_LABEL_POSITION_CANDIDATE } from '../../type'

/**控件属性 */
const PROPS = [
  {
    name: 'title',
    title: '标题',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'name',
    title: '名称(唯一标识)',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'control',
    title: '组件类型',
    control: EnumControlElementType.COMBOBOX,
    options: controlOptions,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'labelPosition',
    title: '标签位置',
    control: 'KSelect',
    attributeType: EnumAttributeType.PROPS,
    options: DEFAULT_LABEL_POSITION_CANDIDATE,
    defaultValue: 'left'
  },
  {
    name: 'dataSource',
    title: '数据源',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'field',
    title: '字段',
    control: EnumControlElementType.INPUT,
    defaultValue: '',
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'visible',
    title: '显示',
    control: EnumControlElementType.CHECKBOX,
    attributeType: EnumAttributeType.BASE,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'readonly',
    title: '是否只读',
    control: EnumControlElementType.CHECKBOX,
    defaultValue: 0,
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'disabled',
    title: '是否禁用',
    control: EnumControlElementType.CHECKBOX,
    defaultValue: 0,
    attributeType: EnumAttributeType.BASE,
    disabled: false
  },
  {
    name: 'nullable',
    title: '是否可为空',
    control: EnumControlElementType.CHECKBOX,
    attributeType: EnumAttributeType.BASE,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'rowSpan',
    title: '行跨度',
    control: EnumControlElementType.INPUT,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'colSpan',
    title: '列跨度',
    control: EnumControlElementType.INPUT,
    attributeType: EnumAttributeType.LAYOUT,
    defaultValue: 1,
    disabled: false
  },
  {
    name: 'entireRow',
    title: '占整行',
    control: EnumControlElementType.COMBOBOX,
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    disabled: false
  },
  {
    name: 'fill',
    title: '占满空间',
    control: EnumControlElementType.COMBOBOX,
    attributeType: EnumAttributeType.LAYOUT,
    options: BOOLEAN_CANDIDATE,
    defaultValue: false,
    disabled: false
  }
]

const EVENT: any[] = []

export default { PROPS, EVENT }
