import { BaseViewModel, SimpleViewModel, ViewModelOptions, UploadChangeParam, request, KNotification } from '@kmsoft/upf-core'
import { FileInter, KObjectFileUploadEmitsType, KObjectFileUploadPropType, fileSuffixIconMapping } from './interface'
import { computed, ref } from 'vue'
import { Api, EnumRequestCode } from '../../client-srv'
import lodash from 'lodash'

/** KObjectFileUpload */
export default class KObjectFileUploadViewModel extends SimpleViewModel<KObjectFileUploadEmitsType, KObjectFileUploadPropType> {
  /** 编辑时的原始值 **/
  fileListOrigin = ref<FileInter[]>([])

  /** 文件列表 **/
  fileList = ref<FileInter[]>([])

  uploadLoading = ref<Boolean>(false)

  uploadDisabled = computed(() => {
    return this.props.disabled || this.uploadLoading.value || this.props.readonly
  })

  getIcon = computed(() => {
    return (item: FileInter) => {
      const findType = Object.keys(fileSuffixIconMapping).find(key =>
        fileSuffixIconMapping[key].fileSuffixList.includes(item?.fileSuffix as string)
      )

      if (findType) {
        return fileSuffixIconMapping[findType].icon
      } else {
        return fileSuffixIconMapping.other.icon
      }
    }
  })

  constructor(options: ViewModelOptions<KObjectFileUploadPropType>) {
    super(options)
  }

  viewDidMount() {}

  async beforeUpload(file: File) {
    // 设计模式下不触发
    if (!this.props.isDesigner) {
      if (file.size <= 0) {
        KNotification.warn({
          message: '系统提示',
          description: '上传文件为空文件'
        })
        return false
      }
      if (file.size > 104857600) {
        KNotification.warn({
          message: '系统提示',
          description: '上传文件最大为100M'
        })
        return false
      }
      this.uploadFile(file)
      return false
    }
  }

  validate() {
    const modelName = this.props.modelName || ''
    const attributeName = this.props.attributeName || ''
    if (modelName == '') {
      KNotification.error({ title: '上传错误', content: '上传组件modelName不能为空' })
      return false
    }
    if (attributeName == '') {
      KNotification.error({ title: '上传错误', content: '上传组件attributeName不能为空' })
      return false
    }

    return true
  }

  getFileSuffix(fileName: string) {
    const fileSplit = fileName.split('.')
    //文件后缀
    let fileSuffix = ''
    if (fileSplit && fileSplit.length > 1) {
      fileSuffix = fileSplit[fileSplit.length - 1]
    }
    return fileSuffix.trim().toLowerCase()
  }

  /**
   * 上传文件
   * @param file
   */
  uploadFile(file: File) {
    if (this.validate() && !this.uploadLoading.value) {
      this.uploadLoading.value = true

      const formData = new FormData()
      formData.append('modelName', this.props.modelName)
      formData.append('attributeName', this.props.attributeName)
      formData.append('files', file)

      // @ts-ignore
      request
        .post(Api.uploadProxyUrl + '/upload', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((data: any) => {
          this.uploadLoading.value = false
          if (data.result && data.result == 'SUCCESS') {
            this.fileList.value.push({
              id: data.data[0],
              name: file.name,
              fileSize: file.size,
              fileSuffix: this.getFileSuffix(file.name)
            })

            KNotification.success({
              title: '系统提示',
              content: `【${file.name}】上传成功`,
              duration: 2
            })
          } else {
            KNotification.error({
              title: `【${file.name}】上传失败`,
              content: data.errors[0]?.message
            })
          }
        })
        .catch(_ => (this.uploadLoading.value = false))
    }
  }

  removeData(index: number) {
    this.fileList.value.splice(index, 1)
  }

  download(file: FileInter) {
    if (this.validate()) {
      // @ts-ignore
      request
        .post(
          Api.uploadProxyUrl + '/download',
          {
            fileIds: file.id,
            modelName: this.props.modelName
          },
          {
            responseType: 'blob'
          }
        )
        .then((data: any) => {
          const url = window.URL.createObjectURL(new Blob([data.data]))
          const link = document.createElement('a')
          link.style.display = 'none'
          link.href = url
          link.setAttribute('download', file.name as string) //指定下载后的文件名，防跳转
          document.body.appendChild(link)
          link.click()
        })
    }
  }

  public setValue(newValue: any[], setChanged?: boolean) {
    const dataList = lodash.cloneDeep(newValue || []) as any[]
    // 如果值是数据ID,通过远程获取文件信息
    if (this.props.isRemoteGetFile) {
      this.fileList.value = dataList.reduce((arr, id: string) => {
        arr.push({ id: id })
        return arr
      }, [])
      this.fileListOrigin.value = lodash.cloneDeep(this.fileList.value)
      this.fileList.value.forEach((file: FileInter) => this.getRemoteFile(file))
    } else {
      // 如果传的是文件对象，直接赋值
      this.fileList.value = dataList.reduce((arr, file: any) => {
        arr.push({
          id: file.id,
          name: file.name,
          fileSize: file.size,
          fileSuffix: this.getFileSuffix(file.name)
        })
        return arr
      }, [])

      this.fileListOrigin.value = lodash.cloneDeep(this.fileList.value)
    }
  }

  getRemoteFile(file: FileInter) {
    //@ts-ignore
    request.get(Api.uploadProxyUrl + '/getInfo/' + file.id).then((data: any) => {
      Object.assign(file, {
        name: data.data[0].name,
        fileSize: data.data[0].fileSize,
        fileSuffix: this.getFileSuffix(data.data[0].name)
      })
    })
  }

  isModified(): boolean {
    const originIds = this.fileListOrigin.value.map((file: FileInter) => file.id) || ([] as string[])
    const currentIds = this.fileList.value.map((file: FileInter) => file.id) || ([] as string[])
    return !(originIds.length === currentIds.length && originIds.every(id => currentIds.includes(id)))
  }

  getModifiedValue() {
    return this.getValue()
  }

  public getValue() {
    return this.fileList.value.map((file: FileInter) => file.id) || []
  }
}
