import { EnumDialogResult, KDialog, KDialogClosingEvent, KNotification } from '@kmsoft/upf-core'
import { Api, EnumRequestCode, KObjectSelectorViewModel, ObjectClientSrv, EnumLifecycleState } from '@kmsoft/ebf-common'
import KIssueReportCreate from '../../pages/issue-report/issue-report-create'
import { KIssueReportCreateViewModel } from '../../pages/issue-report/issue-report-create'
import KChangeRequestCreate from '../../pages/change-request/change-request-create'
import { KChangeRequestCreateViewModel } from '../../pages/change-request/change-request-create'
import { KChangeOrderCreateViewModel, KChangeOrderCreate } from '../../pages/change-order/change-order-create'
import { KChangeActivityCreateViewModel, KChangeActivityCreate } from '../../pages/change-activity/change-activity-create'
import { ChangeRequestDataInter } from '../../pages/change-order/change-order-create/interface'

export class ChangeManageClientSrv {
  /**
   * 创建测试问题报告
   * @param parts 文件夹关联对象清单
   * @param folderId 文件夹ID
   * @returns 结果PROMISE对象
   */
  static createChangeIssue(parts: Array<any>, container: any, folderId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (parts && parts.length > 0) {
        const isValid = this.validateObjectStates(parts, '已发布', '受影响对象必需为【已发布】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      const formValue = {
        plmChangeIssueAffectedViews: parts || [],
        folder: {
          id: folderId
        }
      }
      KDialog.show({
        title: '新建变更问题报告',
        size: { width: 1000, height: 600 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: container,
        content: KIssueReportCreate,
        props: {
          formValue: formValue
        },
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(null)
            return
          }

          const formViewModel = event.viewModel as KIssueReportCreateViewModel
          const result = await formViewModel.save()

          if (result && result.code == EnumRequestCode.SUCCESS && result.success) {
            resolve(result)
          } else {
            event.cancel = true
          }
        }
      })
    })
  }

  /**
   * 创建变更请求
   * @param parts 文件夹关联对象清单
   * @param folderId 文件夹ID
   * @returns 结果PROMISE对象
   */
  static createChangeRequest(parts: Array<any>, container: any, folderId?: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (parts && parts.length > 0) {
        const isValid = this.validateObjectStates(parts, '已发布', '受影响对象必需为【已发布】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      const formValue = {
        plmChangeIssueAffectedViews: parts || [],
        folder: {
          id: folderId
        }
      }
      KDialog.show({
        title: '新建变更请求',
        size: { width: 1000, height: 600 },
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: container,
        content: KChangeRequestCreate,
        props: {
          formValue: formValue
        },
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(null)
            return
          }

          const formViewModel = event.viewModel as KChangeRequestCreateViewModel
          const result = await formViewModel.save()

          if (result && result.code == EnumRequestCode.SUCCESS && result.success) {
            resolve(result)
          } else {
            event.cancel = true
          }
        }
      })
    })
  }

  /**
   *  创建变更单
   * @param affectedObjData 受影响对象
   * @param folderId 文件夹ID
   * @param changeRequestData 请求单数据
   */
  static async createChangeOrder(
    affectedObjData: Array<any>,
    container: any,
    folderId?: string,
    changeRequestData?: ChangeRequestDataInter
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //过滤受影响对象必需为已发布的
      if (affectedObjData && affectedObjData.length > 0) {
        const isValid = this.validateObjectStates(affectedObjData, '已发布', '受影响对象必需为【已发布】')
        if (!isValid) {
          resolve(null)
          return
        }
      }
      const props: any = {
        formValue: {
          folder: {
            id: folderId
          }
        },
        affectedObjData: affectedObjData || []
      }

      if (changeRequestData) {
        props!.changeRequestData = changeRequestData
      }

      KDialog.show({
        title: '创建变更单',
        size: { width: 1000, height: 800 },
        props,
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: container,
        content: KChangeOrderCreate,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(null)
            return
          }

          const formViewModel = event.viewModel as KChangeOrderCreateViewModel
          const validateResult = await formViewModel.validate()

          if (!validateResult) {
            event.cancel = true
            return
          }

          const result = await formViewModel.save()
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '新建成功'
            })
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '新建失败',
              details: result.detail
            })
            event.cancel = true
          }
        }
      })
    })
  }

  /**  基于改前改后对象创建变更活动 **/
  static createChangeActivity(
    title: string,
    changeBeforeData: any[] | null,
    changeAfterData: any[] | null,
    container: any,
    folderId?: string,
    changeOrderId?: string
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      //过滤改前对象必需为已发布的
      if (changeBeforeData && changeBeforeData.length > 0) {
        const isValid = this.validateObjectStates(changeBeforeData, '已发布', '改前对象状态必需为【已发布】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      //过滤改后对象必需为工作中的
      if (changeAfterData && changeAfterData.length > 0) {
        const isValid = this.validateObjectStates(changeAfterData, '工作中', '改后对象状态必需为【工作中】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      const props: any = {
        formValue: {
          folder: {
            id: folderId
          }
        },
        changeBeforeData: changeBeforeData || [],
        changeAfterData: changeAfterData || []
      }

      if (changeOrderId) {
        props!.changeOrderId = changeOrderId
      }

      KDialog.show({
        title,
        size: { width: 1000, height: 800 },
        props,
        showApply: false,
        maximizeBox: false,
        minimizeBox: false,
        getContainer: container,
        content: KChangeActivityCreate,
        onClosing: async (event: KDialogClosingEvent) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(null)
            return
          }

          const formViewModel = event.viewModel as KChangeActivityCreateViewModel
          const validateResult = await formViewModel.validate()

          if (!validateResult) {
            event.cancel = true
            return
          }

          const result = await formViewModel.save()
          if (result && result.code == EnumRequestCode.SUCCESS) {
            KNotification.success({
              title: '系统提示',
              content: '新建成功'
            })
            resolve(result)
          } else {
            KNotification.error({
              title: '操作失败',
              content: result.message || '新建失败',
              details: result.detail
            })
            event.cancel = true
          }
        }
      })
    })
  }

  /** 添加至变更活动 **/
  static addToChangeActivity(isUpdateBefore: boolean, objectData: any[], container: any): Promise<any> {
    return new Promise((resolve, reject) => {
      //校验改前对象必需为已发布的
      if (isUpdateBefore) {
        const isValid = this.validateObjectStates(objectData, '已发布', '改前对象状态必需为【已发布】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      //校验改后对象必需为工作中的
      if (!isUpdateBefore) {
        const isValid = this.validateObjectStates(objectData, '工作中', '改后对象状态必需为【工作中】')
        if (!isValid) {
          resolve(null)
          return
        }
      }

      ObjectClientSrv.openObjectSelectDialog({
        showApply: false,
        objectClassManageQueryParam: {
          showObjClsCodes: ['ChangeActivity'],
          hideObjClsCodes: ['PromoteActivity']
        },
        objectSearchQueryParam: {
          showObjClsCodes: ['ChangeActivity', ''],
          hideObjClsCodes: ['PromoteActivity']
        },
        getContainer: container,
        onClosing: async event => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
            resolve(null)
            return
          }
          // 获取当前弹出组件实例的ViewModel
          const selectorViewModel = event.viewModel as KObjectSelectorViewModel
          // 根据viewModel获取到当前选中的数据
          const selectedRows = selectorViewModel.getSelectedRows()

          const row = selectedRows.length == 0 ? null : selectedRows[0]
          if (row && EnumLifecycleState[row.lifecycleStateCode] == '已发布') {
            KNotification.info({ message: '不能添加至已发布的变更活动' })
            event.cancel = true
            return
          }

          if (row) {
            let data: Record<string, any>
            if (isUpdateBefore) {
              data = objectData.map(item => {
                return {
                  branchId: item.targetBranchId || item?.branch?.id,
                  masterId: item.targetMasterId || item?.master?.id,
                  target: {
                    id: item.targetId || item.id,
                    className: item.targetClass || item.className,
                    rdmExtensionType: item.targetExtensionType || item.rdmExtensionType
                  }
                }
              })
            } else {
              data = objectData.map(item => {
                return {
                  masterId: item.targetMasterId || item?.master?.id,
                  target: {
                    id: item.targetBranchId || item?.branch?.id,
                    rdmExtensionType: item.targetBranchType || item?.branch?.rdmExtensionType
                  }
                }
              })
            }
            const params: { data: Array<any> } = { data: [{ id: row.id }] }
            if (isUpdateBefore) {
              params.data[0]!.changeBeforeLinkViews = data
            } else {
              params.data[0]!.changeAfterLinkViews = data
            }
            Api.post('change', 'ChangeActivity', 'addToActivity', params).then(res => {
              if (res && res.code == EnumRequestCode.SUCCESS) {
                KNotification.success({
                  title: '系统提示',
                  content: '添加成功'
                })
                resolve(res)
              } else {
                KNotification.error({
                  title: '系统提示',
                  content: res.message!
                })
                event.cancel = true
              }
            })
          }
        }
      })
    })
  }

  static validateObjectStates(objectData: any[], stateName: string, message: string): boolean {
    const b = objectData?.some(item => this.getLifecycleState(item) !== stateName)
    if (b) {
      KNotification.info({ message })
      return false
    }
    return true
  }

  static getLifecycleState(item: any) {
    //从文件夹进来的对象lifecycleState为字符串，其它地方的入口进来lifecycleState为对象
    const lifecycleState = item.lifecycleStateCode
    if (lifecycleState) {
      if (typeof lifecycleState == 'string') {
        return lifecycleState ? EnumLifecycleState[lifecycleState] : ''
      } else if (typeof lifecycleState == 'object') {
        return item?.lifecycleState?.description
      }
    } else {
      return item.state
    }
  }
}
