import { Agent, Api, EnumRequestCode, KObjectPropertyPanelViewModel, KSelectWrapperViewModel } from '@kmsoft/ebf-common'
import { EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import {
  BaseViewModel,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  request,
  SimpleViewModel,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref, watch } from 'vue'
import { DocClientSrv } from '../../../../src/client-srv'
import { DocProperty } from '../../../client-agent'
import { KDocFormProcess, KDocFormProcessViewModel } from '../doc-form-process'
import { DocDetailState, KDocFormEmitsType, KDocFormPropType } from './interface'

/** KDocForm */
export default class KDocFormViewModel extends BaseViewModel<KDocFormEmitsType, KDocFormPropType> {
  refContainer = ref<HTMLElement>()
  refObjectCreatePanel = ref<KObjectPropertyPanelViewModel>()
  newModelCode = ref<string>()
  //类型选项
  documentTypeOptions = ref<Array<Record<string, any>>>()
  formDataState = ref<DocDetailState>({
    documentType: EnumDocType.SW,
    number: '',
    name: '',
    version: 'A',
    lifecycleState: {
      name: '工作中'
    },
    description: '',
    primary: [],
    secondary: []
  })

  modelName = ref('Document')
  groupName = ref('doc')
  modelNumber = ref('DM00384994')

  primaryFile = ref([])
  secondaryFile = ref([])
  private simpleViewModel: SimpleViewModel
  oldDocumentType = ref()
  extendAttrs = ref([] as Record<string, any>[])
  folder = ref<string>()

  constructor(options: ViewModelOptions<KDocFormPropType>) {
    super(options)
    this.formDataState.value = { ...this.formDataState.value, ...options.props.formValue }
    this.folder.value = options.props.formValue['folder']
    watch(
      () => options.props.modelCode,
      () => {
        this.newModelCode.value = options.props.modelCode
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {
    this.newModelCode.value = this.props.modelCode
    this.initDocumentTypeOptions()
  }

  async initDocumentTypeOptions() {
    const result = await Api.post('doc', 'Document', 'getDocType', { data: [] })
    if (result && result.code == EnumRequestCode.SUCCESS) {
      const data = result.data
      this.documentTypeOptions.value = data
    }
  }

  async customRequest({ file }: { file: File }, attributeName: string) {
    const storageType = '0'
    const applicationId = '0955fb5adeb7489b90b17dc134fb105f'
    const req = new FormData()
    req.append('modelName', this.modelName.value)
    req.append('attributeName', attributeName)
    req.append('modelNumber', this.modelNumber.value)
    req.append('applicationId', applicationId)
    req.append('storageType', storageType)
    req.append('files', file)
    const result = (await request.post('/kmsaasFileApi/upload', req, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })) as any
    if (result && result.result == 'SUCCESS') {
      const data = result.data
      return data[0]
    }
  }

  /**
   * 调用文件上传附件
   * @param 文件
   */
  async uploadSecondaryFile({ file }: { file: File }) {
    const id = await this.customRequest({ file }, 'secondary')
    if (id) {
      this.formDataState.value?.secondary.push({ id: id })
    }
  }

  /**
   * 调用文件上传上传主文件
   * @param param0 文件
   */
  async uploadPrimaryFile({ file }: { file: File }) {
    const id = await this.customRequest({ file }, 'primary')
    if (id) {
      this.formDataState.value?.primary.push({ id: id })
    }
  }

  getValue() {
    let plmDocCreateObject = this.refObjectCreatePanel.value?.getValue() as any
    if (plmDocCreateObject?.extAttrsList?.length) {
      plmDocCreateObject.extAttrs = plmDocCreateObject.extAttrs
        ? plmDocCreateObject.extAttrs.concat(plmDocCreateObject?.extAttrsList)
        : plmDocCreateObject?.extAttrsList
    }
    delete plmDocCreateObject.extAttrsList
    plmDocCreateObject = { ...plmDocCreateObject, rdmExtensionType: plmDocCreateObject.modelCode } as any
    if (this.newModelCode.value && this.newModelCode.value != '') {
      plmDocCreateObject = { ...plmDocCreateObject, rdmExtensionType: this.newModelCode.value } as any
    }
    // 零部件类型

    return plmDocCreateObject
  }

  async save(asTemplate?: boolean) {
    const newDoc = this.getValue()
    if (this.props.isTemplate) {
      newDoc!.isTemplate = true
      newDoc!.enabledTemplate = true
    }
    // if (asTemplate) {
    //   newDoc!.isTemplate = true
    //   newDoc!.enabledTemplate = true
    // }
    const record = newDoc as Record<string, any>
    // const dialog = KDialog.info({ content: '正在创建文档，请稍后...', title: '提示', showOk: false })
    const dialog = KDialog.show({
      title: '正在创建',
      size: { width: 800, height: 600 },
      props: {
        id: newDoc.number,
        type: 'DOC_CREATE'
      },
      showConfirm: false,
      cancelText: '关闭',
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocFormProcess,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KDocFormProcessViewModel
        formViewModel.setClearTimer()
      }
    })
    if (record.secondary) {
      const secondary = record.secondary.map((element: string) => {
        return { id: element }
      })
      record.secondary = secondary
    }
    let folder = this.props.formValue.folder
    if (folder && typeof folder.id != 'string') {
      folder = { id: folder.id.doc }
    }
    if (!record.docList) {
      record.docList = [
        {
          nodeId: 0,
          isRoot: true,
          name: record.name,
          code: record.number,
          checkoutPath: record.fileName,
          secondary: record.secondary
        }
      ]
      record.folder = folder
    }
    if ((await Agent.AgentManager.initialize()) && !this.props.isTemplate) {
      const docList = record.docList as DocProperty[]
      const checkOutDocList = docList.filter(_ => _.checkoutPath)
      const files = []
      for (const item of checkOutDocList) {
        files.push(item.checkoutPath)
      }
      const result = await DocClientSrv.handleFileUploadWithLockCheck(files)
      if (!result) {
        dialog.close()
        return null
      }
      for (let i = 0; i < checkOutDocList.length; i++) {
        checkOutDocList[i].primary = [{ id: result.data[i] }]
      }
      for (const item of docList) {
        if (asTemplate) {
          item.isTemplate = true
          item.enabledTemplate = true
        }
      }
      //设置primary
      const rootNode = docList.find(item => item.isRoot)
      if (!rootNode) {
        KNotification.error({
          title: '系统提示',
          content: '文档不能为空'
        })
      }
      if (!this.validate()) {
        dialog.close()
        return null
      }
      record.primary = rootNode?.primary
      if (rootNode?.folder) {
        record.folder = rootNode?.folder
      }
    }
    if (record.fileId) {
      record.primary = [{ id: record.fileId }]
    }

    // if (record.documentType == EnumDocType.BREAKDOWN_ELEMENTS) {
    //   folder = undefined
    // }
    const req = {
      data: [{ ...record, lifecycleState: {} }]
    }
    const result = await Api.post('doc', 'Document', 'create', req)
    dialog.close()
    return result
  }

  /** 校验 */
  validate() {
    return this.refObjectCreatePanel.value?.validateFormValue()
  }

  loaded() {
    // 分类组件
    const _this = this
    this.simpleViewModel = this.getByRecursion('_#documentType', this) as KSelectWrapperViewModel | any
    this.simpleViewModel?.on('select' as any, async (changedEventArgs: any) => {
      // 使用 refObjectCreatePanel 的元素作为查询起点
      const element = this.refContainer.value!.querySelector('[data-name="segment#1726651186456"]') as HTMLElement
      if (element) {
        element.style.display = 'block'
        if (changedEventArgs == EnumDocType.STRUCTURE || changedEventArgs == EnumDocType.BREAKDOWN_ELEMENTS) {
          element.style.display = 'none'
        }
      }
      if (_this.oldDocumentType.value && changedEventArgs != _this.oldDocumentType.value) {
        //属性置空
        _this.refObjectCreatePanel.value?.setPropertyValue('name', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('number', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('primary', '')
        _this.refObjectCreatePanel.value?.setPropertyValue('secondary', [])
        //清空拓展属性
        const panelProps = _this.refObjectCreatePanel.value?.props as Record<string, any>
        const panelModelCode = panelProps['modelCode']
        const result = await Api.post('common', 'DataModelManagement', 'getAllAttribute', {
          data: [{ entityNameEn: panelModelCode, holderType: 'MODEL' }]
        })
        const data = (result?.data || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category)) as Record<string, any>[]
        _this.extendAttrs.value = data
        console.info('_this.extendAttrs.value', _this.extendAttrs.value)
        if (_this.extendAttrs.value) {
          for (const extendAttr of _this.extendAttrs.value) {
            _this.refObjectCreatePanel.value?.setPropertyValue(`extendedAttributes#${extendAttr?.code}`, '')
          }
        }
      }
      _this.oldDocumentType.value = changedEventArgs
    })
  }
}
