import {
  Agent,
  Api,
  ConfigClientSrv,
  EnumRequestCode,
  FileClientSrv,
  KObjectPropertyPanelViewModel,
  LoginClientSrv
} from '@kmsoft/ebf-common'
import {
  AppContext,
  BaseViewModel,
  EnumDialogResult,
  KDialog,
  KDialogClosingEvent,
  KNotification,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { ref } from 'vue'
import { AddFilePropertyReadOption, EnumAddMainFileType } from '../../interface'
import { DocFileInfo, KCreatePanelDocSelectorEmitsType, KCreatePanelDocSelectorPropType } from './interface'

import { EnumDocDependencyType, EnumDocType } from '@kmsoft/ebf-common/src/types/enums'
import { isArray } from 'lodash'
import * as AgentDdb from '../../../../client-agent'
import { DocProperty, extAttrs, StructProperty } from '../../../../client-agent'
import { DocClientSrv } from '../../../../client-srv'
import { KDocEchoListPanel, KDocEchoListPanelViewModel } from '../../../../controls/doc-echo-list-panel'
import { DocEchoGridDataInner, EnumOperateOptions, EnumOperateType } from '../../../../controls/doc-echo-list-panel/interface'
import { KDocFileProperty, KDocFilePropertyViewModel } from '../doc-file-property'
import { KDocTemplate, KDocTemplateViewModel } from '../doc-template'

/** KCreatePanelDocSelector */
export default class KCreatePanelDocSelectorViewModel extends BaseViewModel<
  KCreatePanelDocSelectorEmitsType,
  KCreatePanelDocSelectorPropType
> {
  /** 本地输入框值 */
  selectedDocValue = ref<DocFileInfo>()

  // 代理是否启动
  agentConnected = ref<Boolean>(false)

  // 文件提取属性值
  properties = ref<Record<string, string>>({})

  docEchoList = ref<Array<DocEchoGridDataInner>>()

  isShowDocEchoList = ref<Boolean>(false)

  isEditNumber = ref<Boolean>(false)

  fileReadOptions = ref<AddFilePropertyReadOption>()

  /** 是否创建模板 */
  isTemplate = false

  constructor(options: ViewModelOptions<KCreatePanelDocSelectorPropType>) {
    super(options)
    if (this.props.api) {
      const panelProps = this.props.api.getCurrentPanelProps()
      this.isTemplate = panelProps.attachParams.isTemplate
    }
    this.selectedDocValue.value = options.props.value
  }

  async viewDidMount() {
    this.agentConnected.value = await Agent.AgentManager.initialize()
  }

  onClear() {
    this.selectedDocValue.value = undefined
  }

  /**选择文件引用属性 */
  async selectDocFileProperty(): Promise<AddFilePropertyReadOption | undefined> {
    let cascadeDisabled = false
    let isCascade = false
    let propertiesVisible = true
    let cascadeVisible = true
    let propertiesDisabled = false
    const softType = this.props.api.getPropertyValue('documentType')
    if (softType) {
      switch (softType) {
        case EnumDocType.CAD:
          isCascade = true
          cascadeDisabled = true
          break
        case EnumDocType.WORD:
        case EnumDocType.EXCEL:
        case EnumDocType.SW:
          isCascade = false
          cascadeDisabled = true
          break
        case EnumDocType.CADENCE:
        case EnumDocType.CADENCE_PCB:
          cascadeVisible = false
          propertiesVisible = true
          propertiesDisabled = true
          break
        default:
          break
      }
    }
    if (
      softType == EnumDocDependencyType.SIGNATURE ||
      softType == EnumDocType.BREAKDOWN_ELEMENTS ||
      softType == EnumDocDependencyType.CADENCE_GERBER ||
      softType == EnumDocDependencyType.CADENCE_COORDINATE ||
      softType == EnumDocDependencyType.CADENCE_NET_LIST ||
      softType == EnumDocDependencyType.CADENCE_ODB_PLUS ||
      softType == EnumDocType.OTHER ||
      softType == EnumDocType.STRUCTURE
    ) {
      return Promise.resolve(undefined)
    }
    return new Promise(resolve => {
      KDialog.show({
        title: '信息提取选项',
        movable: true,
        size: { width: 360, height: 200 },
        showApply: false,
        props: {
          isCascade: isCascade,
          cascadeDisabled: cascadeDisabled,
          propertiesVisible: propertiesVisible,
          propertiesDisabled: propertiesDisabled,
          cascadeVisible: cascadeVisible
        },
        content: KDocFileProperty,

        onClosing: async (event: KDialogClosingEvent<KDocFilePropertyViewModel>) => {
          if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) return undefined

          const viewModel = event.viewModel as KDocFilePropertyViewModel

          const values = viewModel.getValue()

          this.emit('options', values)

          return resolve(values)
        }
      })
    })
  }

  async agentDialog(): Promise<string | undefined> {
    const filter = this.getFileFilter()
    const dialog = new Agent.OpenFileDialog()
    dialog.Title = '选择文件'
    dialog.Filter = filter
    dialog.Multiselect = false
    if ((await dialog.ShowDialog()) === Agent.DialogResult.OK) {
      return dialog.FileName
    }
    return undefined
  }

  private getFileFilter(): string {
    let filter = '所有文件 (*.*)|*.*|文本文件 (*.txt)|*.txt|图片文件 (*.png;*.jpg;*.jpeg;*.bmp)|*.png;*.jpg;*.jpeg;*.bmp'
    const softType = this.props.api.getPropertyValue('documentType')
    if (softType) {
      switch (softType) {
        case EnumDocType.SW:
          filter = 'SOLIDWORKS文件 (*.SLDASM;*.SLDPRT)|*.SLDASM;*.SLDPRT'
          break
        case EnumDocType.CAD:
          filter = 'DWG文件 (*.dwg)|*.dwg'
          break
        case EnumDocType.WORD:
          filter = 'Word文件 (*.docx;*.doc)|*.docx;*.doc'
          break
        case EnumDocType.EXCEL:
          filter = 'Excel文件 (*.xlsx;*.xls)|*.xlsx;*.xls'
          break
        case EnumDocType.CADENCE:
          filter = '原理图文件 (*.dsn)|*.dsn'
          break
        case EnumDocType.CADENCE_PCB:
          filter = 'PCB图文件 (*.brd)|*.brd'
          break
        default:
          break
      }
    }
    return filter
  }

  // 提取文件属性
  async getFileProperty(fileName: string, options: AddFilePropertyReadOption) {
    // const baseUrl = ConfigClientSrv.baseApiUrl
    // const docFile = await Agent.FileInfo.create(fileName)
    // const length = await docFile.Length()
    //const argIntegrationConfig = await docConfig.fetchIntegrationConfigByDocId({ object: { modelCode: this.props.modelCode } })
    //TODO 从服务端获取配置
    const documentType = this.props.api.getPropertyValue('documentType')
    if (!documentType) {
      KDialog.info({
        title: '提示',
        content: '请选择一个文档类型！'
      })
    }

    // KLoading.show({ desc: '正在读取属性集成配置...' })

    const argIntegrationConfig = await DocClientSrv.getIntegrationConfig(documentType)
    if (argIntegrationConfig && argIntegrationConfig.data) {
      const dialog = KDialog.info({ content: '正在提取文件信息，请稍后...', title: '提示', showOk: false })
      const fileProperty = await AgentDdb.DdbClientSrv.GetFileProperty(
        [fileName],
        [],
        options.isCascade!,
        [],
        [],
        argIntegrationConfig.data,
        LoginClientSrv.getUserIdentity()?.token!,
        {
          downloadUrl: FileClientSrv.getApiUrl('upload'),
          checkMd5Url: '',
          copyUrl: '',
          uploadUrl: FileClientSrv.getApiUrl('upload')
        },
        {
          location: '',
          chunkSize: ''
        }
      )
      if (!fileProperty || !fileProperty.docList || fileProperty.docList.length == 0) {
        dialog.destroy()
        KNotification.error({
          title: '操作失败',
          content: '提取属性失败'
        })
        return Promise.resolve(null)
      }
      //找到根节点
      const root = fileProperty.docList.find(a => a.isRoot == true)!
      // const properties = root?.properties
      // if (properties) {
      //   //如果需要从properties里获取对应属性信息
      //   //this.properties.value = properties
      //   }
      //设置属性 code和name是固定属性
      this.props.api.setPropertyValue('number', root.code)
      this.props.api.setPropertyValue('name', root.name)

      const panelVM = this.props.api.getCurrentPanelViewModel() as KObjectPropertyPanelViewModel
      const panelInstance = panelVM.getControlInstance()
      const folder = panelInstance.$attrs['folder']
      // 获取路径
      const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
      if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
        folder.fullPath = folderPathResult.data
      }
      for (const doc of fileProperty.docList) {
        doc.folder = folder
        if (options.isStruct) {
          doc.part = {
            number: doc.code,
            name: doc.name,
            folder: folder
          }
        }
      }
      //将结构后的文档列表和结构数据塞到表单里面
      this.props.api.setPropertyValue('docList', fileProperty.docList)
      this.props.api.setPropertyValue('docStructList', fileProperty.docStructList)
      const panelProps = this.props.api.getCurrentPanelProps() as Record<string, any>
      const panelModelCode = panelProps['modelCode']
      const result = await Api.post('common', 'DataModelManagement', 'getAllAttribute', {
        data: [{ entityNameEn: panelModelCode, holderType: 'MODEL' }]
      })
      // 接口调用失败清除分类属性
      if (!result?.success) {
        dialog.destroy()
        KNotification.error({
          title: '操作失败',
          content: result.message || '查询失败',
          details: result.detail
        })
        return Promise.resolve(null)
      }
      const data = (result?.data || []).filter((arrFil: any) => ['EXTEND'].includes(arrFil?.category)) as Record<string, any>[]
      const rootProperties = root.properties
      if (rootProperties) {
        //根节点扩展属性回显到面板上
        for (const extendAttr of data) {
          if (rootProperties[extendAttr.displayName]) {
            this.props.api.setPropertyValue(`extendedAttributes#${extendAttr?.code}`, rootProperties[extendAttr.displayName])
            break
          }
        }
      }
      // 所有节点扩展属性赋值到docList
      for (const doc of fileProperty.docList) {
        const extAttrs = [] as extAttrs[]
        if (doc.properties) {
          for (const extendAttr of data) {
            if (doc.properties[extendAttr.displayName]) {
              extAttrs.push({
                name: extendAttr?.code,
                value: doc.properties[extendAttr.displayName]
              })
            }
          }
        }
        if (extAttrs && extAttrs.length > 0) {
          doc.extAttrs = extAttrs
        }
      }

      this.selectedDocValue.value = {
        fileInfo: root?.file as any,
        properties: root?.properties,
        fileProperty: fileProperty,
        name: root?.name as string
      }
      this.emit('update:value', this.selectedDocValue.value)
      dialog.destroy()
      return Promise.resolve(fileProperty.docList)
    }
  }

  // 匹配附属文件
  async getAttachmentFile(fileName: string, documentType: string): Promise<DocProperty[] | undefined> {
    const argIntegrationConfig = await DocClientSrv.getAttachmentFileMatchConfig(documentType)
    if (argIntegrationConfig && argIntegrationConfig.data) {
      const dialog = KDialog.info({ content: '正在查找附属文件，请稍后...', title: '提示', showOk: false })
      const docList = await AgentDdb.DdbClientSrv.FindAttachFileBySameNameRule(
        [{ checkoutPath: fileName }],
        argIntegrationConfig.data,
        AppContext.current.getIdentity()?.token!,
        {
          downloadUrl: '',
          checkMd5Url: '',
          copyUrl: '',
          uploadUrl: ConfigClientSrv.getFileApiBaseUrl + '/upload'
        }
      )
      dialog.destroy()
      return Promise.resolve(docList)
    }
  }

  getIndexMap(currentNodeIds: string[], docStructList: StructProperty[], nodeId2IndexMap: Record<string, string>) {
    const childStructList = [] as StructProperty[]
    for (const docStruct of docStructList) {
      if (currentNodeIds.includes(docStruct.parentId)) {
        childStructList.push(docStruct)
      }
    }
    if (childStructList.length > 0) {
      for (const currentNodeId of currentNodeIds) {
        const structList = childStructList.filter(_ => _.parentId == currentNodeId)
        const index = nodeId2IndexMap[currentNodeId]
        let start = 1
        for (const childNodeStruct of structList) {
          nodeId2IndexMap[childNodeStruct.childId] = index + '.' + start
          start++
        }
      }
      this.getIndexMap(
        childStructList.map(_ => _.childId),
        docStructList,
        nodeId2IndexMap
      )
    }
  }

  async onShowDocEchoList(docList?: DocProperty[]) {
    if (docList) {
      this.docEchoList.value = []
      let index = 0
      const documentType = this.props.api.getPropertyValue('documentType')
      const rdmExtensionType = this.props.api.getPropertyValue('rdmExtensionType')
      for (const docProperty of docList) {
        const docEchoGridDataInner = {
          id: 'doc' + ++index,
          index: index.toString(),
          type: 'doc',
          rdmExtensionType: rdmExtensionType || 'Document',
          number: docProperty.code,
          name: docProperty.name,
          folder: docProperty.folder,
          version: docProperty.version || 'A',
          iteration: '1',
          documentType: documentType,
          operate: docProperty.operate,
          operateOption: docProperty.operateOption,
          docProperty: docProperty
        } as DocEchoGridDataInner
        this.docEchoList.value.push(docEchoGridDataInner)
      }
      if (this.fileReadOptions.value?.isStruct) {
        for (const docProperty of docList) {
          const docEchoGridDataInner = {
            id: 'part' + ++index,
            index: index.toString(),
            type: 'part',
            rdmExtensionType: docProperty.part?.rdmExtensionType || 'Part',
            number: docProperty.part!.number,
            name: docProperty.part!.name,
            folder: docProperty.part!.folder,
            version: docProperty.version || 'A',
            iteration: '1',
            extAttrs: docProperty.part?.extAttrs,
            operate: docProperty.part!.operate,
            operateOption: docProperty.part!.operateOption,
            nodeId: docProperty.nodeId
          } as DocEchoGridDataInner
          this.docEchoList.value.push(docEchoGridDataInner)
        }
      }
    }
    KDialog.show({
      title: '创建',
      size: { width: 900, height: 600 },
      props: {
        dataSource: this.docEchoList.value,
        operateType: EnumOperateType.Create,
        operateOptions: EnumOperateOptions.CREATE,
        isEditNumber: this.isEditNumber.value
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocEchoListPanel,
      onClosing: async (event: KDialogClosingEvent) => {
        const docEchoListPanelViewModel = event.viewModel as KDocEchoListPanelViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        if (!docEchoListPanelViewModel.validate()) {
          KNotification.warn({
            message: '系统提示',
            description: '请补全数据'
          })
          event.cancel = true
          return
        }
        this.docEchoList.value = docEchoListPanelViewModel.getData() as DocEchoGridDataInner[]
        const operateData = docEchoListPanelViewModel.getOperateData() as DocEchoGridDataInner[]
        const operateDocData = operateData.filter(_ => _.type == 'doc')
        const operatePartData = operateData.filter(_ => _.type == 'part')
        const docList = [] as DocProperty[]
        for (const operateDoc of operateDocData) {
          const docProperty = operateDoc.docProperty
          docProperty.code = operateDoc.number
          docProperty.name = operateDoc.name
          docProperty.folder = operateDoc.folder
          docProperty.version = operateDoc.version
          docProperty.operate = operateDoc.operate
          if (this.fileReadOptions.value?.isStruct) {
            const operatePart = operatePartData.find(_ => _.nodeId == docProperty.nodeId)
            if (operatePart) {
              docProperty.part = {
                ...docProperty.part,
                number: operatePart.number,
                name: operatePart.name,
                rdmExtensionType: operatePart.rdmExtensionType,
                folder: operatePart.folder,
                operate: operatePart.operate
              }
            } else {
              docProperty.part = undefined
            }
          }
          docList.push(docProperty)
        }
        //找到根节点
        const root = docList.find(a => a.isRoot == true)
        this.props.api.setPropertyValue('number', root?.code)
        this.props.api.setPropertyValue('name', root?.name)
        this.props.api.setPropertyValue('docList', docList)
      }
    })
  }

  sortByChapter(arr: string[]): string[] {
    return arr.sort((a, b) => {
      const partsA = a.split('.').map(Number)
      const partsB = b.split('.').map(Number)

      let i = 0
      while (i < partsA.length && i < partsB.length) {
        if (partsA[i] !== partsB[i]) {
          return partsA[i] - partsB[i]
        }
        i++
      }

      return partsA.length - partsB.length
    })
  }

  async openSelectFileDialog() {
    const _this = this
    let docList = [] as DocProperty[]
    if ((await Agent.AgentManager.initialize()) && !this.isTemplate) {
      const fileName = await this.agentDialog()
      if (!fileName) return
      // 代理上传文件
      const panelVM = this.props.api.getCurrentPanelViewModel() as KObjectPropertyPanelViewModel
      const panelInstance = panelVM.getControlInstance()

      this.fileReadOptions.value = await this.selectDocFileProperty()
      this.props.api.setPropertyValue('createPart', this.fileReadOptions.value?.isStruct)
      // 勾选提取文件属性
      if (this.fileReadOptions.value && this.fileReadOptions.value.isProperties == true) {
        docList = (await this.getFileProperty(fileName, this.fileReadOptions.value)) as DocProperty[]
        this.isEditNumber.value = false
        if (docList) KNotification.success('提取属性成功')
      } else {
        // const docuemntType = this.props.api.getPropertyValue('documentType')
        // const attachFileList: CheckInAttachFileDto[] = []
        // if (docuemntType == EnumDocType.CADENCE || docuemntType == EnumDocType.CADENCE_PCB) {
        //   // 原理图和PCB文档需要提取附属文件
        //   const docPropertyList = await this.getAttachmentFile(fileName, docuemntType)
        //   if (docPropertyList) {
        //     attachFileList.push(...docPropertyList[0].docAttachFileList)
        //   }
        // }
        docList!.push({
          nodeId: '0',
          id: 0,
          isRoot: true,
          code: this.props.api.getPropertyValue('number') || this.getShortName(fileName),
          name: this.props.api.getPropertyValue('name') || this.getShortName(fileName),
          primary: [],
          docAttachFileList: [],
          checkoutPath: fileName
        })
      }
      // 获取文件夹路径
      const folder = panelInstance.$attrs['folder']
      const docFolder = {} as any
      const partFolder = {} as any
      if (folder.id) {
        //CAD工作区文件夹特殊处理
        if (folder.id.doc && folder.id.part) {
          docFolder.id = folder.id.doc
          partFolder.id = folder.id.part
          const docFolderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id.doc] })
          if (docFolderPathResult && docFolderPathResult.code == EnumRequestCode.SUCCESS) {
            docFolder.fullPath = docFolderPathResult.data
          }
          const partFolderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id.part] })
          if (partFolderPathResult && partFolderPathResult.code == EnumRequestCode.SUCCESS) {
            partFolder.fullPath = partFolderPathResult.data
          }
        } else {
          docFolder.id = folder.id
          partFolder.id = folder.id
          const folderPathResult = await Api.post('folder', 'Folder', 'getFolderPath', { data: [folder.id] })
          if (folderPathResult && folderPathResult.code == EnumRequestCode.SUCCESS) {
            docFolder.fullPath = folderPathResult.data
            partFolder.fullPath = folderPathResult.data
          }
        }
      }
      for (const doc of docList) {
        doc.folder = docFolder
        if (this.fileReadOptions.value?.isStruct) {
          doc.part = {
            number: doc.code,
            name: doc.name,
            folder: partFolder
          }
        }
      }
      this.emit('update:value', fileName)
      this.props.api.setPropertyValue('fileName', fileName)
      _this.selectedDocValue.value = {
        name: fileName.split('\\').pop()
      } as DocFileInfo
      //将结构后的文档列表和结构数据塞到表单里面
      this.props.api.setPropertyValue('docList', docList)
      const result = await Api.post('doc', 'Document', 'checkDocument', {
        data: [{ createPart: this.fileReadOptions.value?.isStruct, docList: docList }]
      })
      if (!result || result.code != EnumRequestCode.SUCCESS || !result.data) {
        KNotification.warn({
          message: '校验失败',
          description: result.message || '获取预览文档失败'
        })
        return null
      }
      const previewDocList = result.data
      this.onShowDocEchoList(previewDocList)
      this.isShowDocEchoList.value = true
    } else {
      return new Promise((resolve, reject) => {
        // 动态创建文件输入框
        const fileInput = document.createElement('input')
        fileInput.type = 'file'
        // 监听文件选择事件
        fileInput.addEventListener('change', () => {
          const files = fileInput.files
          if (files && files.length > 0) {
            if (files[0].size <= 0) {
              KNotification.warn({
                message: '系统提示',
                description: '上传文件为空文件'
              })
              return
            }
            if (files[0].size > 104857600) {
              KNotification.warn({
                message: '系统提示',
                description: '上传文件最大为100M'
              })
              return
            }
            // 显示上传提示
            const dialog = KDialog.info({
              title: '提示',
              content: '正在上传文件...',
              showOk: false
            })

            // 普通上传文件
            DocClientSrv.uploadPrimaryFile(files[0])
              .then(fileId => {
                if (!fileId) {
                  KDialog.error('上传文件失败')
                  reject('上传文件失败')
                  return
                }
                //TODO 不注释有回显问题，注释掉没有值设置
                _this.props.api.setPropertyValue('fileId', fileId)
                _this.selectedDocValue.value = {
                  name: files[0].name
                } as DocFileInfo

                // 关闭上传提示
                dialog.destroy()
              })
              .catch(error => {
                // 关闭上传提示
                dialog.destroy()
                reject(error)
              })
          } else {
            reject('未选择文件')
          }
        })

        // 触发文件选择对话框
        fileInput.click()
      })
    }
  }

  onMenuClick({ key }: any) {
    const documentType = this.props.api.getPropertyValue('documentType')
    if (!documentType) {
      KDialog.info({
        title: '提示',
        content: '请选择一个文档类型！'
      })
      return
    }
    switch (key) {
      case EnumAddMainFileType.LocalFile: {
        this.openSelectFileDialog()
        break
      }
      case EnumAddMainFileType.DocObj:
      case EnumAddMainFileType.Template: {
        this.openDocTemplateDialog()
        break
      }

      default:
        break
    }
  }

  async openDocTemplateDialog() {
    KDialog.show({
      title: '模板选择',
      size: { width: 500, height: 400 },
      props: {
        documentType: this.props.api.getPropertyValue('documentType')
      },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KDocTemplate,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KDocTemplateViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        const selectedRows = formViewModel.getSelectedRows()
        const row = selectedRows.length == 0 ? null : selectedRows[0]
        if (row) {
          this.props.api.setPropertyValue('templateDocId', row.id)
          this.selectedDocValue.value! = row as DocFileInfo
        }
      }
    })
  }

  /**获取表单的值 */
  getValue() {
    if (this.selectedDocValue.value?.fileInfo) {
      return [this.selectedDocValue.value?.fileInfo]
    }
    return null
  }

  /**setValue */
  setValue(value: any, setChanged: boolean) {
    if (value) {
      if (isArray(value)) {
        this.selectedDocValue.value = value[0]
      } else {
        this.selectedDocValue.value = value
      }
    }
  }

  getShortName(fileName: string) {
    const fileNameSplits = fileName.split('\\')
    return fileNameSplits[fileNameSplits.length - 1].split('.')[0]
  }
}
