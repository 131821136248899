import { BaseViewModel, SimpleViewModel, ViewModelOptions, eventEmitter, KNotification } from '@kmsoft/upf-core'
import { KCategorySelectorEmitsType, KCategorySelectorPropType, refTreeData, selectChangeEventArgs } from './interface'
import { ref, watch, computed } from 'vue'
import { Api, ClassMetaClientSrv } from '@kmsoft/ebf-common'
import SelectViewModel from '@kmsoft/upf-core/src/web-controls/select/KSelectViewModel'
import { get } from 'lodash'

/** KCategorySelector-manage */
export default class KCategorySelectorViewModel extends SimpleViewModel<KCategorySelectorEmitsType, KCategorySelectorPropType> {
  treeData = ref<Array<refTreeData | any> | any>()
  labelName = ref<any>('')
  categoryAttrsField: string = 'Part'

  findValue(arr: any, value: any) {
    let result = [] as any
    ;(arr || []).forEach((item: any) => {
      if (get(item, 'value', '') === value) {
        result.push(get(item, 'label', ''))
      } else if (Array.isArray(item.children) && item?.children?.length) {
        result = result.concat(this.findValue(item.children, value))
      }
    })
    return result
  }

  constructor(options: ViewModelOptions<KCategorySelectorPropType>) {
    super(options)
    if (this.props.api) {
      const vm = this.props.api.getCurrentPanelProps()
      this.categoryAttrsField = vm.modelCode == 'Part' ? 'Classification' : 'catAttr'
    }
    watch(
      () => this.props.code,
      newVal => {
        if (newVal) {
          this.loadData()
        }
      },
      {
        immediate: true,
        deep: true
      }
    )

    this.labelName = computed(() => {
      if (!this.readonly) return ''
      const oldValue = this.stateValue.value
      if (!this.treeData.value?.length) return ''
      const labelValue = this.findValue(this.treeData.value, oldValue)
      return get(labelValue, '[0]', '')
    })
  }

  viewDidMount() {}

  // 分类查询下拉
  async loadData() {
    const designerCode = await ClassMetaClientSrv.getDesignerEntityCode(this.props.code)
    //TODO:先写死，让流程继续，后续更改
    const result = (await Api.post('common', 'ClassificationNode', 'find', { data: [''] })) as any
    if (!result?.success) {
      KNotification.error({
        title: '操作失败',
        content: result.message || '查询失败',
        details: result.detail
      })
      return
    }
    this.treeData.value = this.refresh(result?.data)
  }

  // 树下拉值处理
  refresh(data: any) {
    const result = (data || []).map((arr: any) => {
      if (arr?.children?.length) {
        arr.children = this.refresh(arr?.children)
      }
      const text = get(arr, `${this.props.textField}`, '')
      arr.label = this.props.refreshLable(text, arr)
      if (arr?.label === 'your leaf') {
        arr.selectable = false
      }
      return {
        label: arr?.label,
        value: get(arr, `${this.props.valueField}`, ''),
        children: arr.children,
        selectable: arr?.selectable,
        disabled: !arr.instantiable || arr.disableFlag
      }
    })
    return result
  }

  // 选择分类点击事件
  async selectChange(value: any, node: any, extra: any) {
    this.emit('select', {
      getValue: () => {
        return { value, node, extra }
      }
    })
  }

  // 清除
  async clear() {
    this.stateValue.value = ''
    this.emit('clear', true)
  }

  setValue(value: any, setChanged: boolean) {
    const clsAttrs = (value || []).filter((arrFil: any) => {
      return arrFil.type === 'CATEGORY'
    })
    this.stateValue.value = get(clsAttrs, '[0].value.id', '')
    if (setChanged) {
      this.originalValue.value = get(clsAttrs, '[0].value.id', '')
    }
    this.emit('update:value', this.stateValue.value)
  }
  getValue() {
    if (!this.stateValue.value) return null
    return this.stateValue.value
  }

  isModified(): boolean {
    return this.stateValue.value != this.originalValue.value
  }

  public getModifiedValue() {
    return this.getValue()
  }
}
