import {
  EnumClassTemplate,
  EnumSupportObjectNumMode,
  EnumToolStripItemKeys,
  ObjectToolStripItem,
  ToolStripHelper,
  toolStripItemCopy,
  toolStripItemCut,
  toolStripItemDelete,
  toolStripItemDivider,
  toolStripItemExportExcel,
  toolStripItemReport,
  toolStripItemFind,
  toolStripItemNew,
  toolStripItemOpen,
  toolStripItemRefresh,
  toolStripItemSort
} from '@kmsoft/ebf-common'
import { EnumToolStripCompType } from '@kmsoft/upf-core'
import lodash from 'lodash'
import { EnumPartToolStripItemKeys, toolStripItemReplaceVersion } from '../../toolstrip'

export const getToolStrip = () => {
  const commonToolStrip = [ToolStripHelper.getObjectLifeToolStrip(), ToolStripHelper.getObjectOperationToolStrip()]
  return [...getPartObjectOperationToolStrip(), ...getPartStructureToolStrip()]
}

export const getPartObjectOperationToolStrip = (): ObjectToolStripItem[] => {
  return [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SHOW_CHECKBOX,
      title: '显示复选框',
      icon: 'check-square',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_HIDE_CHECKBOX,
      title: '隐藏复选框',
      icon: 'close-square',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_OPEN_OBJ,
      title: '打开对象',
      icon: 'select',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'ctrl+enter',
      supportedObjNumMode: EnumSupportObjectNumMode.Single
      // name: EnumToolStripItemKeys.TOOL_STRIP_GROUP_OBJ,
      // title: '对象操作',
      // icon: 'DropObjLife',
      // visible: true,
      // compType: EnumToolStripCompType.CONTEXT_MENU
      // unSupportedObjClsTempletID: [EnumClassTemplate.ORG, EnumClassTemplate.USER],
      // items: [
      //   {
      //     ...toolStripItemOpen,
      //     title: '打开对象'
      //     // name: EnumPartToolStripItemKeys.TOOL_STRIP_OPEN_PART_OBJ
      //   }
      //   // {
      //   //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON,
      //   //   icon: 'tag',
      //   //   visible: true,
      //   //   compType: EnumToolStripCompType.BUTTON,
      //   //   title: '创建大版本',
      //   //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
      //   //   unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      //   // },
      //   // {
      //   //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEWVERISON_OF_MINOR,
      //   //   icon: 'tag',
      //   //   visible: true,
      //   //   compType: EnumToolStripCompType.BUTTON,
      //   //   title: '创建小版本',
      //   //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
      //   //   unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      //   // },
      //   // {
      //   //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS,
      //   //   title: '另存',
      //   //   icon: 'save',
      //   //   visible: true,
      //   //   compType: EnumToolStripCompType.BUTTON,
      //   //   shortcutKey: 'alt+s',
      //   //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
      //   //   unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      //   // },
      //   // {
      //   //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH,
      //   //   title: '发布',
      //   //   icon: 'sound',
      //   //   visible: true,
      //   //   compType: EnumToolStripCompType.BUTTON,
      //   //   shortcutKey: 'alt+p',
      //   //   supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple,
      //   //   unSupportedObjClsTempletID: [EnumClassTemplate.FLOWOBJ, EnumClassTemplate.ORG, EnumClassTemplate.USER]
      //   // }
      // ]
    }
  ]
}

export const getPartStructureToolStrip = (): Array<ObjectToolStripItem> => {
  /** 主文档操作 */
  // const mainDocOperation = lodash.cloneDeep({
  //   name: EnumPartToolStripItemKeys.TOOL_STRIP_MAIN_DOC_OPERATION,
  //   title: '主文档操作',
  //   icon: 'TaskMultiPlanEdit',
  //
  //   visible: false,
  //   compType: EnumToolStripCompType.BUTTON,
  //   supportedObjNumMode: EnumSupportObjectNumMode.Single,
  //   items: [
  //     toolStripItemOpen,
  //     toolStripItemViewMainObj,
  //     toolStripItemDivider,
  //     ...getDocGeneralObjectOperationToolStripItems(),
  //     toolStripItemDivider,
  //     toolStripItemSendTo,
  //     toolStripItemSendToDirectory,
  //     toolStripItemChangeObjcls
  //   ]
  // } as ObjectToolStripItem)

  // generateMainDocToolStrip(mainDocOperation.items!)

  /** 暂且设置为打开主文档对象 */
  const mainDocOperation = lodash.cloneDeep({
    name: EnumPartToolStripItemKeys.TOOL_STRIP_MAIN_DOC_OPERATION,
    title: '主文档操作',
    icon: 'file',
    visible: false,
    compType: EnumToolStripCompType.BUTTON,
    supportedObjNumMode: EnumSupportObjectNumMode.Single
  } as ObjectToolStripItem)

  return [
    // mainDocOperation,
    toolStripItemDivider(),
    {
      ...toolStripItemReplaceVersion,
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_VERSION,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_LAST_VERSION,
          title: '替换最新版本',
          icon: 'plus',

          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_LAST_PUBLISH_VERSION,
          title: '替换最新发布版本',
          icon: 'plus',

          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_VERSION_LIST,
          title: '替换版本列表',
          icon: 'plus',
          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MENU_CHANGE_STATE,
      title: '转为',
      icon: 'retweet',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      items: [
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHOOSE_AND_CHECK,
          title: '可选默认选用',
          icon: 'plus',

          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHOOSE_NOT_CHECK,
          title: '可选默认不选用',
          icon: 'plus',

          visible: true,
          compType: EnumToolStripCompType.BUTTON
        },
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_MUST_CHOOSE,
          title: '必选',
          icon: 'plus',

          visible: true,
          compType: EnumToolStripCompType.BUTTON
        }
      ]
    },
    toolStripItemDivider(),
    {
      ...toolStripItemFind,
      title: '结构树查找',
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_PART
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_REMARK_NODE_ADD_TO_GRID,
      title: '汇总到列表',
      icon: 'shrink',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SEARCH_RELATED_OBJECTS,
      title: '查找关联对象',
      icon: 'search',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_PART_VERSION_VIEW,
      title: '创建视图版本',
      icon: 'plus',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    toolStripItemDivider(),
    {
      ...toolStripItemNew,
      title: '新建子零部件',
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_NEW
    },

    {
      ...toolStripItemNew,
      title: '添加子零部件',
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_FIND
    },
    // {
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_CHILD_PART_SELECT_TEMPLATE,
    //   title: '从模板添加子零件',
    //   icon: 'Part_FromCategory',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // },
    toolStripItemDivider(),
    {
      ...toolStripItemNew,
      title: '新建兄零部件',
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_NEW
    },
    {
      ...toolStripItemFind,
      title: '添加兄零部件',
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_INSERT_OLD_BRO_PART_NEW
    },
    // {
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_OLD_BRO_PART_SELECT_TEMPLATE,
    //   title: '从模板添加兄零部件',
    //   icon: 'Part_FromCategory',
    //   visible: true,
    //   compType: EnumToolStripCompType.BUTTON
    // },
    toolStripItemDivider(),
    {
      ...toolStripItemCopy,
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_COPY_PART,
      unSupportedEnvironment: undefined,
      shortcutKeyIgnoreVisible: true
    },
    {
      ...toolStripItemCut,
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CUT_PART,
      shortcutKeyIgnoreVisible: true
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_PASTE_PART,
      title: '粘贴',
      icon: 'reconciliation',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'ctrl+v',
      shortcutKeyIgnoreVisible: true
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_BASELINE,
      title: '创建基线',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_CREATE_PROMOTE,
      title: '创建转阶段活动',
      icon: 'plus',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_MATERIAL,
      title: '批量维护物料属性',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_BATCH_UPDATE_BOM,
      title: 'BOM属性批量维护',
      icon: 'edit',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON_GROUP,
      title: 'BOM比较',
      icon: 'sort-descending',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      items: [
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_BOM_COMPARISON,
          title: 'BOM比较',
          icon: 'sort-descending',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          supportedObjNumMode: EnumSupportObjectNumMode.Single
        },
        {
          name: EnumPartToolStripItemKeys.TOOL_STRIP_BOM_DOC_COMPARISON,
          title: 'BOM与文档比较',
          icon: 'sort-descending',
          visible: true,
          compType: EnumToolStripCompType.BUTTON,
          supportedObjNumMode: EnumSupportObjectNumMode.Single
        }
      ]
    },
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_PART,
      title: '移除零部件',
      icon: 'logout',
      visible: true,
      compType: EnumToolStripCompType.BUTTON
    },
    {
      ...toolStripItemDelete,
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_PART,
      title: '删除零部件',
      unSupportedEnvironment: []
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_GROUP_REPLACE_PART,
      title: '更换零部件',
      icon: 'file-sync',
      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      items: [
        {
          ...toolStripItemNew,
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_PART_NEW
        },
        {
          ...toolStripItemFind,
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_REPLACE_PART_FIND
        }
      ]
    },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_SWAP_BORROW,
      title: '添加替换件',
      icon: 'Part_AddSwap',

      visible: true,
      compType: EnumToolStripCompType.CONTEXT_MENU,
      supportedObjNumMode: EnumSupportObjectNumMode.Single,
      items: [
        {
          ...toolStripItemNew,
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_SWAP_BORROW_NEW
        },
        {
          ...toolStripItemFind,
          name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_ADD_SWAP_BORROW_FIND
        }
      ]
    },
    toolStripItemDivider(),
    // {
    //   ...toolStripItemSort,
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_SORT_PART
    // },
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_PART_POSITION,
      title: '调整位置顺序…',
      icon: 'sort-descending',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      supportedObjNumMode: EnumSupportObjectNumMode.Single
    },
    toolStripItemDivider(),
    // {
    //   ...toolStripItemFind,
    //   icon: 'search',
    //   name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND
    // },
    // {
    //   ...toolStripItemFind,
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_ITEM_FIND_REL_OBJ,
    //   title: '查找相关对象…'
    // },
    // {
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_REMARK_NODE_ADD_TO_GRID,
    //   title: '归集到列表',
    //   icon: 'shrink',
    //   visible: false,
    //   compType: EnumToolStripCompType.BUTTON,
    //   supportedObjNumMode: EnumSupportObjectNumMode.Single_Multiple
    // },
    // toolStripItemExportExcel,
    toolStripItemReport,
    toolStripItemDivider(),
    {
      name: EnumPartToolStripItemKeys.TOOL_STRIP_EXCEL_EXPORT,
      title: '导出',
      icon: 'download',
      visible: false,
      compType: EnumToolStripCompType.BUTTON
    },
    toolStripItemDivider(),
    toolStripItemRefresh
    // {
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_HIGHT_LIGHT,
    //   title: '高亮',
    //   icon: 'AddToGrid',
    //   visible: false,
    //   compType: EnumToolStripCompType.BUTTON
    // },
    // {
    //   name: EnumPartToolStripItemKeys.TOOL_STRIP_MODEL_VISIBLE,
    //   title: '隐藏模型',
    //   icon: 'AddToGrid',
    //   visible: false,
    //   compType: EnumToolStripCompType.BUTTON
    // }
  ]
}
