import {
  BaseViewModel,
  IKDataGridCellParams,
  KDataGridCellEditableParams,
  KDataGridCellEditingStoppedEvent,
  KDataGridComboBoxColumn,
  KDataGridTemplateColumn,
  KDataGridViewModel,
  KNotification,
  SelectOption,
  ViewModelOptions,
  MemoryCacheFactory,
  utils
} from '@kmsoft/upf-core'
import { EnumOperateOptions, EnumOperateType, KDocEchoListPanelEmitsType, KDocEchoListPanelPropType } from './interface'
import { createTextVNode, createVNode, nextTick, readonly, ref, watch } from 'vue'
import { Api, EnumRequestCode, KCodeGenerator, KFolderSelector, PartViewMemoryCache } from '@kmsoft/ebf-common'
import { CodeGenerateParams } from '@kmsoft/ebf-common/src/controls/code-generator/interface'
import { KSelect } from '@kmsoft/upf-core'
import { partClsCodeMap, clsCodeMap } from '@kmsoft/ebf-common'

/** KDocEchoListPanel */
export default class KDocEchoListPanelViewModel extends BaseViewModel<KDocEchoListPanelEmitsType, KDocEchoListPanelPropType> {
  refDataGrid = ref<KDataGridViewModel>()
  allOptions = ref<Array<SelectOption>>([])
  constructor(options: ViewModelOptions<KDocEchoListPanelPropType>) {
    super(options)
    watch(
      () => options.props.operateOptions,
      newVal => {
        this.allOptions.value = newVal!
      },
      {
        immediate: true
      }
    )
  }

  viewDidMount() {}

  getOperateData() {
    this.refDataGrid.value?.endEdit()
    const rows = this.refDataGrid.value?.getValue() as any
    return rows.filter((_: { operate: string }) => _.operate != 'Ignore')
  }

  getData() {
    this.refDataGrid.value?.endEdit()
    return this.refDataGrid.value?.getValue() as any
  }

  cellTemplateSelector(params: IKDataGridCellParams) {
    return createTextVNode(params.cellValue)
  }
  cellEditingTemplateSelector(params: IKDataGridCellParams) {
    const version = params.row['version']
    const iteration = params.row['iteration']
    const modelCode = params.row['type'] == 'part' ? 'Part' : 'Document'
    const rdmExtensionType = params.row['rdmExtensionType']
    const documentType = params.row['documentType']
    const codeGenerateParams: CodeGenerateParams = { version, iteration, modelCode, rdmExtensionType }
    if (modelCode == 'Part') {
      codeGenerateParams.extAttrs = [{ name: 'Classification', type: 'CATEGORY', value: '' }]
      if (params.row['extAttrs']) {
        codeGenerateParams.extAttrs = params.row['extAttrs']?.map((attr: any) => {
          if (attr.name === 'Classification' && utils.isObject(attr.value) && attr.value?.id) {
            return {
              ...attr,
              value: attr.value.id
            }
          }
          return attr
        })
      }
    }
    if (modelCode == 'Document') {
      codeGenerateParams.documentType = documentType
    }
    const props = {
      params: codeGenerateParams,
      modelCode
    }
    return createVNode(KCodeGenerator, props)
  }

  folderCellTemplateSelector(params: IKDataGridCellParams) {
    const folder = params.cellValue
    return createTextVNode(folder.fullPath)
  }

  folderCellEditingTemplateSelector(params: IKDataGridCellParams) {
    const folder = params.cellValue
    const props = { value: folder as any, allData: true }
    return createVNode(KFolderSelector, props)
  }

  getCellEditable(params: KDataGridCellEditableParams) {
    const row = params.row
    const column = params.column.dataPropertyName
    if (this.props.operateType == EnumOperateType.CheckIn) {
      if (row['rdmExtensionType'] == 'doc' && row['docProperty'].isRoot && column == 'operate') {
        return false
      }
      if (row['rdmExtensionType'] == 'doc' && column == 'number') {
        return false
      }
    }
    if (this.props.operateType == EnumOperateType.Create) {
      if (row['rdmExtensionType'] == 'doc' && row['docProperty'].isRoot && column == 'operate') {
        return false
      }
      if (row['rdmExtensionType'] == 'doc' && column == 'number') {
        return this.props.isEditNumber
      }
    }

    if (this.props.operateType == EnumOperateType.SaveAs) {
      if (column == 'number' || column == 'name' || column == 'operate') {
        if (row['index'] == 1 && column == 'operate') {
          return false
        }
        return true
      }
    }
    if (this.props.operateType == EnumOperateType.Revise) {
      if (row['index'] == 1 && column == 'operate') {
        return false
      }
      if (column == 'operate') {
        return true
      }
    }
    // 零部件类型可以选择
    if (column == 'rdmExtensionType' || column == 'number') {
      if (row['operate'] == 'Add' && row['type'] == 'part') {
        return true
      } else {
        return false
      }
    }
    return undefined
  }

  rdmExtensionTypeCellTemplateSelector(params: IKDataGridCellParams) {
    const res = clsCodeMap.get(params.cellValue) || '文档'
    return createTextVNode(res)
  }

  rdmExtensionTypeCellEditingTemplateSelector(params: IKDataGridCellParams) {
    const options = new Array<SelectOption>()
    partClsCodeMap.forEach((value, key) => {
      options.push({
        label: value,
        value: key
      })
    })
    return createVNode(KSelect, { options: options })
  }

  operationCellTemplateSelector(params: IKDataGridCellParams) {
    const res = this.allOptions.value.find(x => x.value == params.cellValue)!
    return createTextVNode(res.label)
  }

  operationCellEditingTemplateSelector(params: IKDataGridCellParams) {
    const operateOption = params.row['operateOption'] as string[]
    let options = this.allOptions.value
    if (operateOption) {
      options = this.allOptions.value.filter(item => operateOption.includes(item.value as string))
    }
    return createVNode(KSelect, { options: options })
  }

  async cellEditingStopped(event: KDataGridCellEditingStoppedEvent) {
    if (this.props.operateType == EnumOperateType.CheckIn || this.props.operateType == EnumOperateType.Create) {
      if (event.valueChanged && event.column.dataPropertyName == 'number' && event.row['rdmExtensionType'] == 'Part') {
        const nodeId = event.row['nodeId']
        const rows = this.refDataGrid.value?.getValue() as any
        const docData = rows.find((item: any) => item.docProperty.nodeId == nodeId)
        const docProperty = docData.docProperty
        docProperty.code = docData.number
        docProperty.name = docData.name
        docProperty.part = {
          number: event.row.number,
          name: event.row.name
        }
        const checkResult = await Api.post('doc', 'Document', 'checkDocument', {
          data: [{ createPart: true, docList: [docProperty] }]
        })
        if (!checkResult || checkResult.code != EnumRequestCode.SUCCESS || !checkResult.data) {
          KNotification.warn({
            message: '校验失败',
            description: checkResult.message || '校验编码失败'
          })
          return null
        }
        const operate = checkResult.data[0].operate
        const operateOption = checkResult.data[0].operateOption
        //event.row.operate = 'Update'
        //event.row.operateOption = operateOption
        this.refDataGrid.value?.refreshRow({ id: event.row['id'], operate: operate, operateOption: operateOption })
      }
    }
  }

  validate() {
    const dataGridRowData = this.refDataGrid.value?.getValue()
    for (const data of dataGridRowData!) {
      if (!data.number || !data.name || !data.folder.id) {
        return false
      }
    }
    return true
  }

  getView(row: any) {
    if (row.partView?.description) {
      return '(' + row.partView?.description + ')'
    } else if (row.targetViewId) {
      const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
      const partView = cacheInstance.getAll()
      if (row.targetViewId && partView.length > 0) {
        const view = partView.filter(item => item.id == row.targetViewId)[0]?.description
        return '(' + view + ')'
      }
    }
    return ''
  }
}
