
import {
  KDataGridColumn,
  defineView,
  KDataGridTextButtonColumn,
  KDataGridTextBoxColumn,
  KDataGridComboBoxColumn
} from '@kmsoft/upf-core'
import { KImportItemPropOptions, KImportItemEventEmits } from './interface'
import KImportItemViewModel from './KImportItemViewModel'

export default defineView({
  name: 'KImportItem',
  props: KImportItemPropOptions,
  emits: KImportItemEventEmits,
  viewModel: KImportItemViewModel,
  setup(props, { emit, slots, attrs, vm }) {
    const formatColumns = () => {
      const columnsDef: Array<KDataGridColumn> = []
      let fieldColumn = {} as any
      for (const field of props.fields) {
        if (field.id == 'propSource') {
          fieldColumn = new KDataGridComboBoxColumn()
          fieldColumn.headerText = field.title
          fieldColumn.id = field.id
          fieldColumn.dataPropertyName = field.id
          fieldColumn.options = [
            { label: '基础属性', value: 'BASE' },
            { label: '扩展属性', value: 'EXTEND' },
            { label: '分类属性', value: 'CATEGORY' }
          ]
        } else {
          fieldColumn = new KDataGridTextBoxColumn()
          fieldColumn.headerText = field.title
          fieldColumn.id = field.id
          fieldColumn.dataPropertyName = field.id
        }
        columnsDef.push(fieldColumn)
      }

      return columnsDef
    }
    return () => {
      const gridProps = {
        ref: vm.refDataGrid,
        rowKey: props.rowKey,
        columns: formatColumns(),
        dataSource: vm.dataSource.value,
        isMultipleSelection: props.isMultipleSelection
      }
      return (
        <div class="k-order-item-import-panel">
          <div style="display: flex; margin-bottom: 10px;">
            <k-input style="width: 200px;" allowClear="false" onPressEnter={vm.queryData} value={vm.inputValue}></k-input>
            <k-button onClick={vm.queryData}>查询</k-button>
          </div>
          <k-data-grid style="height: calc(100% - 40px)" {...gridProps}></k-data-grid>
        </div>
      )
    }
  }
})
