import { EnumAttributeType, EnumControlElementType, EnumDataType } from '@kmsoft/upf-core'
import { BOOLEAN_CANDIDATE } from '@kmsoft/ebf-view-engine'
import { getCandidates } from '../interface'
import { DEFAULT_LABEL_POSITION_CANDIDATE } from '../../type'
/* 组件的基本属性 */
const comConfig = [
  {
    name: 'autoIndex',
    title: '自动生成序号',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowKey',
    title: '行关键字',
    control: 'KInput',
    defaultValue: 'id',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'isMultipleSelection',
    title: '是否可多选',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowDrag',
    title: '是否可拖拽',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'rowDragMutiRow',
    title: '是否可多行拖拽',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'pagination',
    title: '是否开启分页',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'showStripe',
    title: '是否开启奇偶行',
    control: 'KSelect',
    options: getCandidates([true, false]),
    defaultValue: true,
    dataType: EnumDataType.BOOL,
    attributeType: EnumAttributeType.PROPS
  },
  {
    name: 'stripeBackgroundColor',
    title: '偶行背景色',
    control: 'KInput',
    defaultValue: '',
    dataType: EnumDataType.STRING,
    attributeType: EnumAttributeType.PROPS
  }
]

/**控件属性 */
const PROPS = [
  ...comConfig,
  ...[
    {
      name: 'title',
      title: '标题',
      control: EnumControlElementType.INPUT,
      defaultValue: '',
      attributeType: EnumAttributeType.BASE,
      disabled: false
    },
    {
      name: 'name',
      title: '名称(唯一标识)',
      control: EnumControlElementType.INPUT,
      defaultValue: '',
      attributeType: EnumAttributeType.BASE,
      disabled: false
    },
    {
      name: 'control',
      title: '组件类型',
      control: EnumControlElementType.COMBOBOX,
      options: [],
      defaultValue: '',
      attributeType: EnumAttributeType.BASE,
      disabled: false
    },
    {
      name: 'dataSource',
      title: '数据源',
      control: EnumControlElementType.INPUT,
      defaultValue: '',
      attributeType: EnumAttributeType.BASE,
      disabled: false
    },
    {
      name: 'field',
      title: '字段',
      control: EnumControlElementType.INPUT,
      defaultValue: '',
      attributeType: EnumAttributeType.BASE,
      disabled: false
    },
    {
      name: 'labelPosition',
      title: '标签位置',
      control: 'KSelect',
      attributeType: EnumAttributeType.PROPS,
      options: DEFAULT_LABEL_POSITION_CANDIDATE,
      defaultValue: 'hidden',
      dataType: EnumDataType.STRING
    },
    {
      name: 'visible',
      title: '显示',
      control: EnumControlElementType.CHECKBOX,
      attributeType: EnumAttributeType.BASE,
      defaultValue: 1,
      disabled: false
    },
    {
      name: 'rowSpan',
      title: '行跨度',
      control: EnumControlElementType.INPUT,
      attributeType: EnumAttributeType.LAYOUT,
      defaultValue: 3,
      disabled: false
    },
    {
      name: 'colSpan',
      title: '列跨度',
      control: EnumControlElementType.INPUT,
      attributeType: EnumAttributeType.LAYOUT,
      defaultValue: 1,
      disabled: false
    },
    {
      name: 'entireRow',
      title: '占整行',
      control: EnumControlElementType.COMBOBOX,
      attributeType: EnumAttributeType.LAYOUT,
      options: BOOLEAN_CANDIDATE,
      defaultValue: true,
      disabled: false
    },
    {
      name: 'fill',
      title: '占满空间',
      control: EnumControlElementType.COMBOBOX,
      attributeType: EnumAttributeType.LAYOUT,
      options: BOOLEAN_CANDIDATE,
      defaultValue: false,
      disabled: false
    }
  ]
]

const EVENT = [
  { name: 'viewDidMount', title: '数据加载完成', describe: '数据加载完成 的说明信息...' },
  { name: 'selectionChanged', title: '行选中', describe: '行选中 的说明信息...' },
  { name: 'firstDataRendered', title: '首次数据渲染完成后', describe: '' },
  { name: 'rowDataUpdated', title: '行更新完成', describe: '' },
  { name: 'paginationChanged', title: '分页改变', describe: '' },
  { name: 'rowClick', title: '行点击', describe: '' },
  { name: 'rowDoubleClick', title: '行双击', describe: '' },
  { name: 'rowSelected', title: '当行选中或取消选中', describe: '' },
  { name: 'rowChanged', title: '行切换事件', describe: '' },
  { name: 'rowValueChanged', title: '结束编辑后', describe: '' },
  { name: 'rowDragEnter', title: '行拖动开始', describe: '' },
  { name: 'rowDragEnd', title: '行拖动结束', describe: '' },
  { name: 'cellValueChanged', title: '单元格值改变', describe: '' },
  { name: 'cellClick', title: '单元格单击', describe: '' },
  { name: 'cellDblClick', title: '单元格双击', describe: '' },
  { name: 'cellFocused', title: '单元格获取焦点', describe: '' },
  { name: 'cellKeyDown', title: '单元格键盘按钮按下', describe: '' },
  { name: 'cellKeyPress', title: '单元格键盘按钮按下', describe: '' },
  { name: 'cellMouseOver', title: '鼠标移入单元格', describe: '' },
  { name: 'cellMouseDown', title: '鼠标点击单元格', describe: '' },
  { name: 'cellMouseOut', title: '鼠标移出单元格', describe: '' },
  { name: 'cellContextMenu', title: '单元格右键事件', describe: '' },
  { name: 'cellEditingStarted', title: '单元开始编辑', describe: '' },
  { name: 'cellEditingStopped', title: '单元格结束编辑', describe: '' },
  { name: 'rowGroupOpened', title: '分组展开收起', describe: '' }
]

export default { PROPS, EVENT }
