import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, mergeProps as _mergeProps, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-7a5679b0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = { class: "tree" }
const _hoisted_3 = { class: "action-button" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_k_input = _resolveComponent("k-input")!
  const _component_k_icon = _resolveComponent("k-icon")!
  const _component_k_button = _resolveComponent("k-button")!
  const _component_k_tooltip = _resolveComponent("k-tooltip")!
  const _component_k_object_class_tree = _resolveComponent("k-object-class-tree")!
  const _component_k_popover = _resolveComponent("k-popover")!
  const _component_k_input_group = _resolveComponent("k-input-group")!

  return (_openBlock(), _createBlock(_component_k_input_group, {
    class: _normalizeClass(['k-object-class-tree-selector', _ctx.$attrs.class]),
    style: _normalizeStyle(_ctx.$attrs.style),
    compact: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_k_input, {
        ref: "refClassInput",
        class: "object-class-name",
        prefix: _ctx.showPrefix ? '对象类：' : '',
        placeholder: _ctx.placeholder,
        readonly: _ctx.$vm.readonly,
        disabled: ""
      }, null, 8, ["prefix", "placeholder", "readonly"]),
      (_ctx.showClear && !_ctx.$vm.disabled && !_ctx.$vm.readonly)
        ? (_openBlock(), _createBlock(_component_k_button, {
            key: 0,
            class: "object-class-clear",
            disabled: _ctx.$vm.disabled,
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$vm.onClear()))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_k_icon, { type: "clear" })
            ]),
            _: 1
          }, 8, ["disabled"]))
        : _createCommentVNode("", true),
      (!_ctx.$vm.disabled && !_ctx.$vm.readonly)
        ? (_openBlock(), _createBlock(_component_k_popover, {
            key: 1,
            title: "选择对象类",
            trigger: "click",
            placement: "bottomRight",
            open: _ctx.$vm.expand,
            "onUpdate:open": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.$vm.expand) = $event)),
            "arrow-point-at-center": ""
          }, {
            content: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createElementVNode("div", _hoisted_2, [
                  (!_ctx.$vm.disabled)
                    ? (_openBlock(), _createBlock(_component_k_object_class_tree, _mergeProps({
                        key: 0,
                        ref: "refKTreeViewObjClass",
                        isCreateObj: _ctx.isCreateObj,
                        onAfterSelect: _cache[1] || (_cache[1] = (node)=>_ctx.$vm.onAfterSelect(node)),
                        onAfterCheck: _cache[2] || (_cache[2] = (node)=>_ctx.$vm.onAfterCheck(node)),
                        onReady: _ctx.$vm.onTreeReady
                      }, _ctx.objectClassTreeProps), null, 16, ["isCreateObj", "onReady"]))
                    : _createCommentVNode("", true)
                ]),
                _createElementVNode("div", _hoisted_3, [
                  _createVNode(_component_k_button, {
                    type: "primary",
                    disabled: _ctx.$vm.isDisableConfirm,
                    block: "",
                    onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$vm.onConfirm()))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode("确认")
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ])
              ])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_k_tooltip, { title: "选择对象类" }, {
                default: _withCtx(() => [
                  _createVNode(_component_k_button, {
                    class: "object-class-action",
                    disabled: _ctx.$vm.disabled
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_k_icon, { type: "apartment" })
                    ]),
                    _: 1
                  }, 8, ["disabled"])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["open"]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["class", "style"]))
}