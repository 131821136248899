// import { EnumObjClassTreeNodeType, RegionKeyData } from '../../service'
import { ExtractPropTypes } from 'vue'
import { ClassFeature, EnumModelCategoryType, EnumObjClassTreeNodeType } from '../../client-srv'
import {
  KTreeNode,
  KTreeViewEventEmits,
  KTreeViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'
import { show } from '../rule-definer'

//#region
/** 枚举 */
export enum TreeObjClassEnvironment {
  /** 关联描述关系定义界面 */
  RelationEnvironment,
  /** 高级查询界面 对象类树 */
  AdvancedQueryEnvironment,
  /** 添加评审对象界面 */
  AddAuditObjectEnvironment,
  /** 对象分类树 */
  ObjectClassTreeEnvironment,
  /** 目录网格列(配置动态目录时) */
  DirectoryDataGridViewColumnEnvironment,
  /** 其它环境 */
  OtherEnvironment
}
//#endregion

/** 对象类的元数据定义属性 */
export class ObjClassPropertyBase {
  //#region 节点属性

  /**节点类型 */
  nodeType: EnumObjClassTreeNodeType = EnumObjClassTreeNodeType.RootObjClass

  /**判断当前是否为对象节点，含大类和子类 */
  IsObjClass(): boolean {
    return this.isObjClassNode(this.nodeType)
  }

  isObjClassNode(argNodeType: EnumObjClassTreeNodeType): boolean {
    return argNodeType == EnumObjClassTreeNodeType.RootObjClass || argNodeType == EnumObjClassTreeNodeType.SubObjClass
  }

  /**节点id */
  code: string = ''
  /**节点名称 */
  name: string = ''
  /**是否有子 */
  children: boolean = false
  /**序号 */
  index: number = 0
}

/** 对象类属性 */
export class ObjClassProperty extends ObjClassPropertyBase {
  constructor() {
    super()
    this.nodeType = EnumObjClassTreeNodeType.RootObjClass
  }
  /**对象类模板 */
  classTemplate!: string
  /**父对象类 */
  parentClassCode!: string
  /**对象大类编码*/
  rootClassCode: string = ''
  /**对象类编码全路径 */
  fullCodePath?: string
  /**简码 */
  simpleCode?: string
  /**是否为大类 */
  isRootClass(): boolean {
    return this.code === this.rootClassCode
  }
  /**是否含子 */
  isChildren(): boolean {
    return false
  }
}

/** KObjectClassTree 事件 **/
export const KObjectClassTreeEventEmits = {
  ...KTreeViewEventEmits
}

/** KObjectClassTreeBase 参数 **/
export const KObjectClassTreeBasePropOptions = {
  /**默认选中节点的code */
  defaultSelectClsCode: VuePropTypes.string().def(),
  /** 对象类类型 */
  classTemplates: VuePropTypes.array<string>().def([]),
  /** 设置要显示的对象id集合,包含子节点 */
  showObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 设置要隐藏的对象类id 可追加列表 使得多个环境变量可共同干预 */
  hideObjClsCodes: VuePropTypes.array<string>().def([]),
  /** 业务对象类型 */
  modelCategory: VuePropTypes.createType<EnumModelCategoryType>().def(EnumModelCategoryType.BUSINESS_MODEL),
  /** 根据特征过滤 */
  feature: VuePropTypes.createType<Partial<ClassFeature>>().def(),
  /** 是否自动包裹根节点，若开启该属性，设置根节点数据源时，总是在最外层包裹一个全局节点，节点关键字为__root__ */
  autoWrapRoot: VuePropTypes.bool().def(true),
  /**
   * 默认展开的层级
   * @description 从`0`开始，如 `0`为展开`第一层`，1 为展开`第二层`
   */
  defaultExpandedLevel: VuePropTypes.number().def(0),
  /**
   * 自动选择第一个节点
   * @description 与 autoSelectLastNode 互斥 , 将会在首次和后续操作中引用设置
   */
  autoSelectFirstNode: VuePropTypes.bool().def(false),
  /**
   * 新增节点时，自动选中最后一个添加的节点
   * @description 与 autoSelectFirstNode 互斥 , 将会在首次和后续操作中引用设置
   */
  autoSelectLastNode: VuePropTypes.bool().def(false),
  /**默认显示业务对象 */
  showBusinessObject: VuePropTypes.bool().def(true),
  /** 默认不显示全部 */
  showAllOrNot: VuePropTypes.bool().def(false),
  showSearch: VuePropTypes.bool().def(false),
  /** 是否是创建对象 */
  isCreateObj: VuePropTypes.bool().def(false)
}

/** KObjectClassTree 参数 **/
export const KObjectClassTreePropOptions = {
  ...KTreeViewPropOptions,
  ...KObjectClassTreeBasePropOptions,
  /**
   * 树运行环境
   * @default TreeObjClassEnvironment.ObjectClassTreeEnvironment
   */
  environment: VuePropTypes.createType<TreeObjClassEnvironment>().def(TreeObjClassEnvironment.ObjectClassTreeEnvironment)
}

/** KObjectClassTree 参数类型 **/
export type KObjectClassTreeBasePropType = ExtractPropTypes<typeof KObjectClassTreeBasePropOptions>

/** KObjectClassTree 参数类型 **/
export type KObjectClassTreePropType = ViewPropsTypeExtract<typeof KObjectClassTreePropOptions>

/** KObjectClassTree 事件协议*/
export type KObjectClassTreeEventEmitsTypes = ViewEmitsTypeExtract<typeof KObjectClassTreeEventEmits>
