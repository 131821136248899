import {
  Api,
  EnumLifecycleState,
  EnumQueryConditionOperator,
  EnumRequestCode,
  EnumToolStripItemKeys,
  FolderTypeObjTypeMap,
  IClsOption,
  KObjectClassGrid,
  KObjectDynamicCreatePanel,
  KObjectToolStripViewModel,
  ObjectClientSrv,
  ObjectToolStripItem,
  PartViewMemoryCache,
  ToolStripHelper,
  processCellCallback
} from '@kmsoft/ebf-common'
import KConfigForm, { KConfigFormViewModel } from '@kmsoft/ebf-common/src/controls/config-form'
import {
  KFolderSelectorForm,
  KFolderSelectorFormViewModel
} from '@kmsoft/ebf-common/src/controls/folder-selector/form/k-folder-selector-form'
import { documentTypeMap } from '@kmsoft/ebf-common/src/types/enums'
import {
  BaseViewModel,
  EnumDialogResult,
  IKTreeNode,
  IQuery,
  KDataGridRowSelectedEvent,
  KDataGridViewModel,
  KDialog,
  KDialogClosingEvent,
  KIcon,
  KModal,
  KNotification,
  MemoryCacheFactory,
  SelectOption,
  ToolStripItemClickedEventArgs,
  TreeViewSelectEventArgs,
  ViewModelOptions,
  request,
  utils
} from '@kmsoft/upf-core'
import _ from 'lodash'
import { createVNode, ref, watch } from 'vue'
import { DocManageClientSrv } from '../../../../ebf-doc/src/client-srv/DocManageClientSrv'
import { KPartImport } from '../../../../ebf-part/src/pages/part-import'
import KCollectRelatedPart from '../../../../ebf-workflow/src/pages/object-add-workflow/collect-related-object/form/collect-related-part'
import { FolderClientSrv } from '../../client-srv'
import KCadRelationDoc from '../../pages/workspace/cad-workspace/cad-relation-doc'
import { KFolderTreeViewModel } from './folder-tree'
import { CopyOrCutEnum } from './folder-tree/interface'
import { KFolderManageEmitsType, KFolderManagePropType, WorkingStateDesc, WorkingStateEnum } from './interface'

/** KFolderManage */
export default class KFolderManageViewModel extends BaseViewModel<KFolderManageEmitsType, KFolderManagePropType> {
  refContainer = ref<HTMLElement>()
  /** 文件夹列表网格 */
  refFolderObjGrid = ref<KDataGridViewModel>()
  /** 选中的树节点 */
  selectNode = ref<IKTreeNode>()
  refFolderTree = ref<KFolderTreeViewModel>()

  /** 工具栏 */
  refToolStrip = ref<KObjectToolStripViewModel>()
  /** 工具栏数据 */
  toolStripItems = ref<Array<ObjectToolStripItem>>(_.cloneDeep(ToolStripHelper.getFolderGeneralObjectOperationToolStripItems()))
  /**复制还是粘贴中 */
  inCopyOrCut: CopyOrCutEnum = CopyOrCutEnum.NOTHING
  /** 零部件单行展示按钮 */
  partSingleVisible = ref(true)
  /**最新版本/最新发布版本条件 */
  queryType = ref<String>('LATEST')

  /**列表查询参数 */
  searchParam = ref<Record<string, any>>({
    queryType: 'LATEST',
    lifecycleState: ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma']
  })
  searchParamUpdateFlag = ref<boolean>(false)

  /**生命周期候选值 */
  lifecycleStateOptions = ref<Array<SelectOption>>(EnumLifecycleState._list)
  /**工作状态候选值 */
  workingStateOptions = ref<Array<SelectOption>>(WorkingStateEnum)

  constructor(options: ViewModelOptions<KFolderManagePropType>) {
    super(options)
    watch(
      () => this.selectNode.value,
      () => {
        this.queryType.value = 'LATEST'
        this.refreshList()
      }
    ),
      watch(
        () => this.queryType.value,
        () => {
          if (this.searchParamUpdateFlag.value) {
            this.searchParam.value = { ...this.searchParam.value, queryType: this.queryType.value }
            this.searchParamUpdateFlag.value = false
          } else {
            this.searchParam.value = {
              lifecycleState: ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma'],
              queryType: this.queryType.value
            }
            this.refFolderObjGrid.value?.refresh()
          }
        }
      )
  }

  // 树节点会首次加载会选中两次，增加防抖
  refreshList = _.debounce(() => {
    this.refFolderObjGrid.value?.refresh()
  }, 300)

  viewDidMount() {
    this.initToolStrip()
  }

  /**
   * 初始化工具栏
   */
  initToolStrip() {
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_OBJ_GROUP_OPERATION, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_GROUP_FILE_OPERATION, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHANGE_MANAGED, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_GROUP_WORK_FLOW, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_OBJ_LINK, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDPART, false)
    this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDDOC, false)
    if (this.props.showCADOperation) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD, false)
    }
  }
  /**
   * 加载网格数据
   */
  async loadData(query: IQuery) {
    if (this.selectNode.value == null) {
      return {
        rows: [],
        total: 0
      }
    }
    const param = {
      data: [
        {
          folderId: this.selectNode.value?.id,
          pageVO: {
            maxPageSize: 1000,
            curPage: query.page?.pageIndex,
            pageSize: query.page?.pageSize,
            totalRows: 0,
            totalPages: 0,
            startIndex: 0,
            endIndex: 0
          },
          ...this.searchParam.value
        }
      ]
    }
    const result = await Api.post('folder', 'Folder', 'listFolderLink', param)
    if (result && result.code == EnumRequestCode.SUCCESS && result.data && result.data.data) {
      return {
        rows: result.data.data,
        total: result.data.page.totalRows
      }
    } else {
      return {
        rows: [],
        total: 0
      }
    }
  }
  /**
   * 改变工具栏
   */
  async changeToolItem(event: KDataGridRowSelectedEvent<any>) {
    const selectRows = this.refFolderObjGrid.value?.getSelectedRows()
    if (!selectRows || selectRows.length == 0) {
      this.initToolStrip()
      return
    }
    const newModelCode = selectRows.map(item => item.targetClass)
    const param = { modelCode: newModelCode, environment: 100, selectedDatas: selectRows, isCad: this.props.showCADOperation }

    await this.refToolStrip.value?.updateState(param)
    if (
      newModelCode.every(item => item == 'ProductNeeds') &&
      !this.props.showCADOperation &&
      (selectRows[selectRows.length - 1].lifecycleStateCode == 'Released' ||
        selectRows[selectRows.length - 1].lifecycleStateCode == 'Realizing')
    ) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, true)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE, false)
    }
    if (!this.props.showCADOperation && ['InWork', 'Yiquma'].includes(selectRows[selectRows.length - 1].lifecycleStateCode)) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH, true)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH, false)
    }
    if (!this.props.showCADOperation && selectRows[selectRows.length - 1].lifecycleStateCode == 'Released') {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE, true)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE, false)
    }
    if (newModelCode.length == 1 && ['Part', 'Document'].includes(newModelCode[0]) && this.props.showCADOperation) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDPART, true)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDDOC, true)
    } else {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDPART, false)
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDDOC, false)
    }
    if (this.props.showCADOperation || this.props.isPersonalWorkspace) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD, false)
    }

    //结构化文档和分解要素不能点击结构管理
    const hasBreakdownElements = selectRows.some(
      row => row.documentType === 'BreakdownElements' || row.documentType === 'Structure'
    )
    if (hasBreakdownElements) {
      this.refToolStrip.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE, false)
    }
  }
  /**
   * 集合比较
   */
  compareArray(arr1: Array<string>, arr2: Array<string>) {
    if (arr1.length !== arr2.length) {
      return false
    }
    const sortedArr1 = arr1.slice().sort()
    const sortedArr2 = arr2.slice().sort()
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false
      }
    }
    return true
  }
  /**
   * 工具栏点击事件
   */
  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    const rows = this.refFolderObjGrid.value!.getSelectedRows()
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_NEW:
        this.create()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BATCH_CREATE_DOCUMENTS:
        this.batchCreateDocuments()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD:
        this.add()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_TO_CAD:
        FolderClientSrv.addToCADWorkspace(rows, this.refContainer.value)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVEAS:
        FolderClientSrv.saveAs(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE:
        FolderClientSrv.batchDelete(this.selectNode.value, rows).then(res => {
          if (res) {
            this.refFolderObjGrid.value?.clearSelectedRows()
            this.refFolderObjGrid.value?.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DELETE_BRANCH:
        FolderClientSrv.batchDelete(this.selectNode.value, rows, true).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PUBLISH:
        FolderClientSrv.publish(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_OBSOLETE:
        FolderClientSrv.batchObsolete(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_COPY:
        this.copy()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CUT:
        this.cut()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_PASTE:
        this.paste()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REMOVE_OBJ_LINK:
        this.batchRemoveObjLink(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_MOVE:
        this.batchMove()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_STRUC_MANAGE:
        FolderClientSrv.structManage(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_OUT:
        FolderClientSrv.checkOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_UN_CHECK_OUT:
        FolderClientSrv.unCheckOut(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CHECK_IN:
        FolderClientSrv.checkIn(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REVISE:
        FolderClientSrv.revise(rows).then(res => {
          if (res) {
            this.refFolderObjGrid.value?.clearSelectedRows()
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refresh(true)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FIND:
        this.search()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD:
        this.downloadTemplate()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_IMPORT:
        this.partImport()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BROWSE_DOC:
        FolderClientSrv.browse(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_DOWNLOAD_DOC:
        FolderClientSrv.downloadDoc(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_FILE:
        FolderClientSrv.editFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE:
        FolderClientSrv.fileReplaceFromLocalFile(rows).then(res => {
          if (res) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_FILE_REPLACE_NEW:
        FolderClientSrv.fileReplaceNew(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_FILE_REPLACE_QUERY:
        FolderClientSrv.fileReplaceQuery(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_HISTORY_VERSION:
        FolderClientSrv.historyVersion(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_REMARK:
        FolderClientSrv.remark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK:
        FolderClientSrv.viewRemark(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_VIEW_REMARK_HISTORY:
        FolderClientSrv.viewRemarkHistory(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ISSUE:
        FolderClientSrv.createChangeIssue(rows, this.refContainer.value, this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_REQUEST:
        FolderClientSrv.createChangeRequest(rows, this.refContainer.value, this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_CHANGE_ORDER:
        FolderClientSrv.createChangeOrder(rows, this.refContainer.value, this.selectNode.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_CREATE:
        FolderClientSrv.afterUpdateCreate(rows, this.refContainer.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_AFTER_UPDATE_ADD_ACTIVITY:
        FolderClientSrv.afterUpdateAddActivity(rows, this.refContainer.value).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_CREATE:
        FolderClientSrv.beforeUpdateCreate(rows, this.refContainer.value).then(res => {
          this.refFolderObjGrid.value?.clearSelectedRows()
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEFORE_UPDATE_ADD_ACTIVITY:
        FolderClientSrv.beforeUpdateAddActivity(rows, this.refContainer.value).then(res => {
          this.refFolderObjGrid.value?.clearSelectedRows()
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CONVERT:
        FolderClientSrv.convert(rows).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_ADD_WORK_FLOW:
        FolderClientSrv.addToWorkflow(rows).then(res => {
          this.refresh()
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_VIEW_WORK_FLOW:
        FolderClientSrv.viewWorkFlow(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_USING_STATISTICS:
        FolderClientSrv.usingStatistics(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BASELINE_COMPARE:
        FolderClientSrv.baselineCompare(rows)
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_CREATE_REQUIREMENT_CHANGE:
        FolderClientSrv.createRequirementChange(rows, this.selectNode.value?.id!).then((res: any) => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refresh()
          }
        })
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDDOC:
        this.relatedDoc()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_RELATEDPART:
        this.relatedPart()
        break
      default:
        break
    }
  }

  /**
   * 零部件导入
   */
  partImport() {
    const folderId = this.selectNode.value?.id
    KDialog.show({
      title: '零部件导入',
      size: { width: 500, height: 300 },
      props: { folderId: folderId },
      showApply: false,
      showCancel: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KPartImport,
      onClosing: async (event: KDialogClosingEvent) => {
        this.refFolderObjGrid.value?.refresh()
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
      }
    })
  }

  /**
   * 下载Excel模板
   */
  async downloadTemplate() {
    // 获取模板配置
    const fetchByCode = await Api.post('sys', 'ConfigSysQueryService', 'fetchByCode', {
      data: ['SYS_PART_IMPORT_TEMPLATE']
    })
    if (!(fetchByCode && fetchByCode.success)) {
      KNotification.warn({
        message: '系统提示',
        description: '无法获取零部件导入模板配置'
      })
      return
    }
    // 下载模板
    const param = {
      modelName: 'Part',
      fileIds: fetchByCode.data
    }
    const result = await request.post('/kmsaasFileApi/download', param, {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'blob'
    })
    if (result?.status == EnumRequestCode.SUCCESS && result.data) {
      const bolb = new Blob([result.data as any], { type: 'application/octet-stream,charset=UTF-8' })
      const url = URL.createObjectURL(bolb)
      const link = document.createElement('a')
      link.href = url
      link.download = '零部件导入模板.xlsx'
      link.click()
    } else {
      KNotification.error({
        title: '操作失败',
        content: '零部件导入模板下载失败'
      })
    }
  }
  /**
   * 树选中后事件
   * @param event 树选中后事件
   */
  afterSelect(event: TreeViewSelectEventArgs<any>) {
    this.selectNode.value = event.node
    this.refFolderObjGrid.value?.clearSelectedRows()
    this.searchParam.value = {
      queryType: 'LATEST',
      lifecycleState: ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma']
    }
  }

  /**
   * 创建对象
   */
  create() {
    const clsOptions = [] as Array<IClsOption>
    const node = this.selectNode.value
    let defaultSelectClsCode = ''
    let showObjClsCodes = [] as Array<string>
    let showObjClsSelector = true
    if (node && node.rootNodeType) {
      if (FolderTypeObjTypeMap.get(node.rootNodeType)) {
        showObjClsCodes = [FolderTypeObjTypeMap.get(node.rootNodeType)!]
        defaultSelectClsCode = FolderTypeObjTypeMap.get(node.rootNodeType)!
        showObjClsSelector = false
      } else {
        showObjClsCodes = ['Part']
        defaultSelectClsCode = 'Part'
      }
    }
    KDialog.show({
      title: '创建对象',
      content: KObjectDynamicCreatePanel,
      props: {
        clsOptions: clsOptions,
        folderId: this.props.showCADOperation ? this.props.cadFolderId : this.selectNode.value?.id,
        showObjClsCodes: this.props.showCADOperation ? ['Part', 'Document'] : showObjClsCodes,
        hideObjClsCodes: [
          'CustomerNeedsSection',
          'ProductNeedsSection',
          'RequirementChangeRequest',
          'ValidationMetrics',
          'VerificationPlan'
        ],
        defaultSelectClsCode: defaultSelectClsCode,
        showObjClsSelector: showObjClsSelector
      },
      size: { width: 1200, height: document.documentElement.clientHeight - 100 },
      getContainer: this.refContainer.value,
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewInstance as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const validateResult = await formViewModel.validate()
        if (!validateResult) {
          event.cancel = true
          return
        }
        const result = await formViewModel.save()
        if (result && result.code == EnumRequestCode.SUCCESS) {
          if (this.props.showCADOperation) {
            let resData = [result.data]
            if (utils.isArray(result.data)) {
              resData = result.data
            }
            const res = await this.cadDataAddFolder(resData)
            if (!res || res.code != EnumRequestCode.SUCCESS) {
              KNotification.error({
                title: '添加至CAD工作区失败',
                content: result.message || '添加至CAD工作区失败',
                details: result.detail
              })
            }
          }
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('对象创建成功')
        } else {
          if (result) {
            KNotification.error({
              title: '操作失败',
              content: result.message || '创建对象失败',
              details: result.detail
            })
          }
          event.cancel = true
          return
        }
      }
    })
  }

  cadDataAddFolder(cadData: any) {
    const objList = cadData.map((row: any) => {
      if (row.branch && row.branch.id) {
        return {
          id: row.branch.id,
          clazz: row.branch.rdmExtensionType
        }
      } else {
        return {
          id: row.id,
          clazz: row.rdmExtensionType
        }
      }
    })
    const params = {
      data: [
        {
          folderId: this.selectNode.value?.id,
          objList: objList
        }
      ]
    }
    return Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
  }

  /**
   * 批量创建文档
   */
  batchCreateDocuments() {
    DocManageClientSrv.batchCreateDocuments(this.selectNode.value?.id, () => {
      this.refFolderObjGrid.value?.refresh()
    })
  }
  /**
   * 添加对象
   */
  add() {
    ObjectClientSrv.openObjectSelectDialog({
      showApply: false,
      isMultipleSelection: true,
      size: { width: 1300, height: 850 },
      objectClassManageQueryParam: this.props.showCADOperation ? { showObjClsCodes: ['Part', 'Document'] } : undefined,
      objectSearchQueryParam: this.props.showCADOperation ? { showObjClsCodes: ['Part', 'Document'] } : undefined,
      getContainer: this.refContainer.value,
      onClosing: async (event: KDialogClosingEvent) => {
        if (!event.viewInstance) {
          return
        }

        if (event.dialogResult == EnumDialogResult.Close || event.dialogResult == EnumDialogResult.Cancel) {
          return
        }

        const objParams = event.viewInstance.getSelectedObjParams()

        if (objParams.length <= 0) {
          event.cancel = true
          return
        }
        const modelGroup = await ObjectClientSrv.getModelGroupByCode(objParams[0]!.modelCode)
        const objIds = objParams.map((obj: { id: any }) => obj.id)
        // 获取对象信息
        const query = {
          modelCode: objParams[0]!.modelCode,
          modelGroup: modelGroup,
          filter: {
            conditions: [
              {
                conditionName: 'rdmExtensionType',
                operator: EnumQueryConditionOperator.EQUAL,
                conditionValues: [objParams[0]!.extensionType]
              },
              {
                conditionName: 'id',
                operator: EnumQueryConditionOperator.IN,
                conditionValues: objIds
              }
            ],
            joiner: 'and'
          },
          page: {
            pageIndex: 1,
            pageSize: 1000
          }
        }
        const objs = await ObjectClientSrv.listObjects(query)
        if (objs.rows.length == 0) {
          KNotification.warn({
            message: '系统提示',
            description: '未查询到对象信息'
          })
          event.cancel = true
          return
        }
        // 根据对象中是否有branch对象判断是否是版本对象，若为版本对象则取branch.id
        const objList = objs.rows.map(obj => {
          if (obj.branch) {
            return {
              id: obj.branch.id,
              clazz: obj.branch.rdmExtensionType,
              className: obj.branch.rdmExtensionType
            }
          } else {
            return {
              id: obj.id,
              clazz: obj.rdmExtensionType,
              className: obj.rdmExtensionType
            }
          }
        })
        const params = {
          data: [
            {
              folderId: this.selectNode.value?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '对象添加成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 相关文档
   */
  relatedDoc() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows()
    KDialog.show({
      title: '相关文档',
      size: { width: 1200, height: 800 },
      content: KCadRelationDoc,
      props: { parts: rows },
      onClosing: async (event: KDialogClosingEvent) => {
        if (event.dialogResult == EnumDialogResult.Cancel || event.dialogResult == EnumDialogResult.Close) {
          return
        }
        /** 当前组件 */
        const formViewModel = event.viewModel as any

        /** 获取表单值 */
        const formValue: any = formViewModel.getSelectedRows()
        if (formValue.length <= 0) {
          event.cancel = true
          return
        }
        const objList = formValue.map((obj: any) => {
          if (obj.target.branch) {
            return {
              id: obj.target.branch.id,
              clazz: obj.target.branch.rdmExtensionType,
              className: obj.target.branch.rdmExtensionType
            }
          } else {
            return {
              id: obj.target.id,
              clazz: obj.target.rdmExtensionType,
              className: obj.target.rdmExtensionType
            }
          }
        })
        const params = {
          data: [
            {
              folderId: this.selectNode.value?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '相关文档添加成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 相关零部件
   */
  relatedPart() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows()
    if (rows[0].targetClass == 'Document') {
      this.docCollectRelatedPart(rows)
    } else {
      this.partCollectRelatedPart(rows)
    }
  }

  /**
   * 文档收集零部件
   */
  async docCollectRelatedPart(rows: any) {
    const docs = rows.map((row: any) => {
      return {
        id: row.targetId
      }
    })
    let dataSource = [] as any[]
    const reqParam = {
      data: [docs]
    }
    const result = await Api.post('part', 'PartDescribeLink', 'listPartByDocumentIds', reqParam)
    if (result && result.code == EnumRequestCode.SUCCESS) {
      dataSource = result.data
    } else {
      KNotification.error({
        title: '查询失败',
        content: result.message,
        details: result.detail
      })
    }
    KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      getContainer: this.refContainer.value,
      content: KObjectClassGrid,
      props: {
        modelCode: 'Part',
        modelGroup: 'part',
        selectFirstRow: false,
        loadData: async () => {
          return Promise.resolve(dataSource)
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const formValue = selectorViewModel.getSelectedRows()
        if (formValue.length <= 0) {
          event.cancel = true
          return
        }
        const objList = formValue.map((obj: any) => {
          if (obj.branch) {
            return {
              id: obj.branch.id,
              clazz: obj.branch.rdmExtensionType,
              className: obj.branch.rdmExtensionType
            }
          } else {
            return {
              id: obj.id,
              clazz: obj.rdmExtensionType,
              className: obj.rdmExtensionType
            }
          }
        })
        const params = {
          data: [
            {
              folderId: this.selectNode.value?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '相关零部件添加成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 零部件收集相关零部件
   */
  async partCollectRelatedPart(rows: any) {
    const that = KDialog.show({
      title: '相关零部件',
      size: { width: 1200, height: 800 },
      showFooter: false,
      floatFooter: true,
      showApply: false,
      maximizeBox: false,
      minimizeBox: false,
      content: KCollectRelatedPart,
      props: {
        objParam: {
          id: rows[0].targetId,
          rdmExtensionType: rows[0].targetExtensionType
        },
        onCancel: function() {
          that.close({ dialogResult: EnumDialogResult.Cancel })
        },
        onConfirm: function() {
          that.close({ dialogResult: EnumDialogResult.Confirm })
        }
      },
      onClosing: async (event: KDialogClosingEvent) => {
        const selectorViewModel = event.viewModel as any
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return

        // 根据viewModel获取到当前选中的数据
        const formValue = selectorViewModel.getSelectedRows()
        if (formValue.length <= 0) {
          event.cancel = true
          return
        }
        const objList = formValue.map((obj: any) => {
          if (obj.branch) {
            return {
              id: obj.branch.id,
              clazz: obj.branch.rdmExtensionType,
              className: obj.branch.rdmExtensionType
            }
          } else {
            return {
              id: obj.id,
              clazz: obj.rdmExtensionType,
              className: obj.rdmExtensionType
            }
          }
        })
        const params = {
          data: [
            {
              folderId: this.selectNode.value?.id,
              objList: objList
            }
          ]
        }
        const result = await Api.post('folder', 'Folder', 'batchCreateFolderObjLink', params)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success({
            title: '系统提示',
            content: '相关零部件添加成功'
          })
          this.refresh()
          return
        } else {
          KNotification.error({
            title: '系统提示',
            content: result.message!
          })
          event.cancel = true
          return
        }
      }
    })
  }

  /**
   * 批量删除对象
   */
  batchDelete() {
    KModal.delete({
      title: '删除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行删除操作，请确认是否删除',
      onOk: async () => {
        const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
        if (!rows || rows.length == 0) return
        const deleteRows = rows.map((row: any) => {
          return {
            folderedLinkId: row.id,
            folderId: row.targetFolderId,
            objId: row.targetId,
            className: row.targetClass,
            branchId: row.targetBranchId
          }
        })
        const param = {
          data: [this.selectNode.value?.id, [...deleteRows]]
        }
        const result = await Api.post('folder', 'Folder', 'deleteFolderObj', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象删除成功')

          // 移除选中的行
          this.refFolderObjGrid.value?.removeSelectedRows()
        } else {
          KNotification.error({
            title: '对象删除失败',
            content: result.message!
          })
        }
      }
    })
  }
  /**
   * 复制对象
   */
  copy() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    this.inCopyOrCut = CopyOrCutEnum.IN_COPY
    this.setCache('copyRows', rows)
    this.setCache('sourceFolderId', this.selectNode.value?.id)
  }
  /**
   * 剪切对象
   */
  cut() {
    const rows = this.refFolderObjGrid.value!.getSelectedRows() as any
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一条数据'
      })
      return
    }
    this.inCopyOrCut = CopyOrCutEnum.IN_CUT
    this.setCache('copyRows', rows)
    this.setCache('sourceFolderId', this.selectNode.value?.id)
  }
  /**
   * 粘贴对象
   */
  paste() {
    const rows = this.getCache('copyRows')
    const fromNodeId = this.getCache('sourceFolderId') as string
    if (!rows) {
      KNotification.warn({
        message: '系统提示',
        description: '请先复制或剪切一个对象'
      })
      return
    }
    const currentNodeId = this.selectNode.value?.id
    if (fromNodeId == currentNodeId) {
      KNotification.warn({
        message: '系统提示',
        description: '请勿粘贴到相同目录'
      })
      return
    }

    const rowsData = rows.map((row: any) => {
      return {
        oldSourceId: fromNodeId,
        oldFolderId: row.targetFolderId,
        objId: row.targetId,
        className: row.targetClass,
        branchId: row.targetBranchId
      }
    })
    const param = {
      data: [currentNodeId, rowsData]
    }
    //如果是剪切操作，需要删除fromNode的数据
    if (this.inCopyOrCut == CopyOrCutEnum.IN_CUT) {
      Api.post('folder', 'Folder', 'cutFolderObjLink', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('剪切成功')
        } else {
          KNotification.warn({
            message: '剪切失败',
            description: res.message
          })
          return
        }
      })
    } else if (this.inCopyOrCut == CopyOrCutEnum.IN_COPY) {
      debugger
      Api.post('folder', 'Folder', 'copyFolderObjLink', param).then(res => {
        if (res && res.code == EnumRequestCode.SUCCESS) {
          this.refFolderObjGrid.value?.refresh()
          KNotification.success('复制成功')
        } else {
          KNotification.warn({
            message: '复制失败',
            description: res.message
          })
          return
        }
      })
    }
    this.clearCache('sourceFolderId')
    this.clearCache('copyRows')
    this.inCopyOrCut = CopyOrCutEnum.NOTHING
  }

  /**
   * 移除对象链接
   */
  batchRemoveObjLink(rows: any) {
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    const deleteRows = rows.map((row: any) => {
      return {
        folderedLinkId: row.id,
        folderId: row.targetFolderId,
        objId: row.targetId,
        objNumber: row.number,
        className: row.targetClass,
        branchId: row.targetBranchId
      }
    })
    const param = {
      data: [this.selectNode.value?.id, [...deleteRows]]
    }
    KModal.delete({
      title: '移除提示',
      icon: createVNode(KIcon, { type: 'common_batch_delete_cover' }),
      content: '您正在进行移除操作，请确认是否移除',
      onOk: async () => {
        const result = await Api.post('folder', 'Folder', 'deleteFolderObjLink', param)
        if (result && result.code == EnumRequestCode.SUCCESS) {
          KNotification.success('对象关系移除成功')
          // 移除选中的行
          this.refFolderObjGrid.value?.removeSelectedRows()
        } else {
          KNotification.error({
            title: '对象关系移除失败',
            content: result.message!
          })
        }
      }
    })
  }

  /**
   * 移动对象
   */
  batchMove() {
    const rows = this.refFolderObjGrid.value?.getSelectedRows()
    if (!rows || rows.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请至少选择一个对象'
      })
      return
    }
    const rowsData = rows.map((row: any) => {
      return {
        oldSourceId: this.selectNode.value?.id,
        oldFolderId: row.targetFolderId,
        objId: row.targetId,
        className: row.targetClass,
        branchId: row.targetBranchId
      }
    })

    KDialog.show({
      title: '搜索',
      content: KFolderSelectorForm,
      size: { width: 640, height: 520 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KFolderSelectorFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const fromNodeId = this.selectNode.value?.id as string
        const toNodeId = formViewModel.folderId.value
        if (fromNodeId == toNodeId) {
          KNotification.warn({
            message: '系统提示',
            description: '请勿移动到相同目录'
          })
          return
        }
        const param = {
          data: [toNodeId, rowsData]
        }
        Api.post('folder', 'Folder', 'cutFolderObjLink', param).then(res => {
          if (res && res.code == EnumRequestCode.SUCCESS) {
            this.refFolderObjGrid.value?.refresh()
            KNotification.success('移动成功')
          } else {
            KNotification.warn({
              message: '移动失败',
              description: res.message
            })
            return
          }
        })
      }
    })
  }

  /**
   * 刷新对象
   */
  refresh(noClear?: boolean) {
    this.searchParam.value = {
      queryType: 'LATEST',
      lifecycleState: ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma']
    }
    this.queryType.value = 'LATEST'
    if (!noClear) {
      this.refFolderObjGrid.value?.clearSelectedRows()
    }
    this.refFolderObjGrid.value?.refresh()
  }
  /**
   * 修改对象
   */
  async update(row: any) {
    const param = {
      id: row?.targetId,
      modelCode: row!.targetClass,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetClass)
    }
    ObjectClientSrv.openObj(param)
  }
  /**
   * 删除对象
   * @param id 对象id
   */
  async deleteBranch(row: any) {
    FolderClientSrv.batchDelete(this.selectNode.value, [row], true).then(res => {
      if (res) {
        this.refFolderObjGrid.value?.clearSelectedRows()
        this.refFolderObjGrid.value?.refresh()
      }
    })
  }
  /**
   * 搜索对象
   */
  search() {
    KDialog.show({
      title: '搜索',
      content: KConfigForm,
      props: {
        formList: [
          {
            label: '名称',
            props: 'name',
            name: 'commonSearchName',
            attrs: {
              placeholder: '请输入名称'
            }
          },
          {
            label: '编码',
            props: 'number',
            name: 'commonSearchId',
            attrs: {
              placeholder: '请输入编码'
            }
          },
          {
            label: '创建时间',
            props: 'createTime',
            type: 'k-date-time',
            name: 'commonSearchCreateTime',
            attrs: {
              type: 'range'
            }
          },
          {
            label: '修改时间',
            props: 'lastUpdateTime',
            type: 'k-date-time',
            name: 'commonSearchLastUpdateTime',
            attrs: {
              type: 'range'
            }
          },
          {
            label: '版本',
            props: 'queryType',
            type: 'k-select',
            name: 'commonSearchPhase',
            attrs: {
              options: [
                {
                  label: '全部版本',
                  value: 'ALL'
                },
                {
                  label: '最新版本',
                  value: 'LATEST'
                },
                {
                  label: '最新发布版本',
                  value: 'LATEST_RELEASED'
                }
              ]
            }
          },
          {
            label: '生命周期状态',
            props: 'lifecycleState',
            type: 'k-select',
            name: 'commonSearchPhase',
            attrs: {
              placeholder: '请选择生命周期状态',
              mode: 'multiple',
              options: [
                {
                  label: '工作中',
                  value: 'InWork'
                },
                {
                  label: '审核中',
                  value: 'UnderReview'
                },
                {
                  label: '已发布',
                  value: 'Released'
                },
                {
                  label: '实现中',
                  value: 'Realizing'
                },
                {
                  label: '已关闭',
                  value: 'Closed'
                },
                {
                  label: '已废弃',
                  value: 'Obsoleted'
                },
                {
                  label: '已取码',
                  value: 'Yiquma'
                }
              ]
            }
          }
        ],
        labelCol: 6,
        getValue: (result: any) => {
          return result
        },
        formValue: { queryType: 'ALL', lifecycleState: ['InWork', 'UnderReview', 'Released', 'Realizing', 'Closed', 'Yiquma'] }
      },
      size: { width: 800, height: 500 },
      onClosing: async (event: KDialogClosingEvent) => {
        const formViewModel = event.viewModel as KConfigFormViewModel
        if (event.dialogResult == EnumDialogResult.Cancel) return
        if (event.dialogResult == EnumDialogResult.Close) return
        const value = formViewModel.formValue.value
        this.searchParam.value = value
        this.queryType.value = this.searchParam.value.queryType
        this.searchParamUpdateFlag.value = true
        this.refFolderObjGrid.value?.refresh()
      }
    })
  }

  /**
   * 打开新标签页展示对象
   */
  async openObjTab(row: any) {
    const param = {
      id: row?.targetId,
      modelCode: row!.targetExtensionType,
      modelGroup: await ObjectClientSrv.getModelGroupByCode(row!.targetExtensionType)
    }
    ObjectClientSrv.openObj(param)
  }
  exportSelectAsExcel() {
    const data = this.refFolderObjGrid.value?.getSelectedRows()
    if (data && data.length == 0) {
      KNotification.warn({
        message: '系统提示',
        description: '请先选择要导出的数据'
      })
      return
    }
    const params = {
      onlySelected: true,
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }
  exportAsExcel() {
    const params = {
      columnKeys: this.refFolderObjGrid.value
        ?.getColumnDefs()
        .filter(x => x.id !== 'action')
        .map((x: any) => x.id),
      processCellCallback: (params: any) => {
        const self = this
        return processCellCallback(params, self.refFolderObjGrid.value)
      }
    }
    this.refFolderObjGrid.value?.exportDataAsExcel(params)
  }

  getProjectStatus(row: any) {
    if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    } else if (row.workingState === 'INWORK' || row.workingState === 'CHECKED_OUT') {
      return 'lock-out'
    }
  }

  getIconClass(row: any) {
    if (row.workingState === 'INWORK') {
      return 'unlock-self'
    } else if (row.workingState === 'CHECKED_IN') {
      return 'lock-in'
    }
    return 'unlock-out'
  }

  getStatusOption(row: any) {
    return WorkingStateDesc.find(it => it.value === row.workingState)?.label
  }

  getView(row: any) {
    const cacheInstance = MemoryCacheFactory.get<PartViewMemoryCache>(PartViewMemoryCache.cacheKey)
    const partView = cacheInstance.getAll()
    if (row.targetViewId && row.targetClass == 'Part' && partView.length > 0) {
      const view = partView.filter(item => item.id == row.targetViewId)[0]?.description
      return '(' + view + ')'
    }
    if (row.documentType && row.targetClass == 'Document') {
      return `(${documentTypeMap[row.documentType as keyof typeof documentTypeMap] || row.documentType})`
    }
    return ''
  }
}
