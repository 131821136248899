/** 文档类型 */
export enum EnumDocType {
  /**
   * UG数模
   */
  UG = 'UgSolidedge',
  /**
   * SW数模
   */
  SW = 'Solidworks',
  /**
   * autoCAD
   */
  CAD = 'Autocad2d',
  /**
   * office
   */
  OFFICE = 'Office',
  /**
   * WPS
   */
  WPS = 'Wps',

  /**
   * word
   */
  WORD = 'Word',

  /**
   * excel
   */
  EXCEL = 'Excel',

  /**
   * 结构化文档
   */
  STRUCTURE = 'Structure',

  /**
   * 结构化文档分解要素
   */
  BREAKDOWN_ELEMENTS = 'BreakdownElements',

  /**
   * CADENCE原理图
   */
  CADENCE = 'Cadence',

  /**
   * CADENCE_PCB图
   */
  CADENCE_PCB = 'CadencePcb',

  /**
   * 其他
   */
  OTHER = 'Other'
}

/** 文档类型 */
export enum EnumDocDependencyType {
  /**
   * 签字文档
   */
  SIGNATURE = 'Signature',
  /**
   * 打印文档
   */
  PRINT = 'Print',
  /**
   * 浏览文档
   */
  BROWSE = 'Browse',

  /**
   * SOLIDWORKS工程图
   */
  SOLIDWORKS_2D = 'Solidworks2d',

  /**
   * Gerber文件
   */
  CADENCE_GERBER = 'Gerber',

  /**
   * 坐标文件
   */
  CADENCE_COORDINATE = 'Coordinate',

  /**
   * ODB++文件
   */
  CADENCE_ODB_PLUS = 'OdbPlus',

  /**
   * 网表文件
   */
  CADENCE_NET_LIST = 'NetList',

  /**
   * 符号库文件
   */
  SYMBOL_LIB = 'SymbolLib',

  /**
   * 封装库文件
   */
  WRAPPER_LIB = 'WrapperLib',
  /**
   * PPT
   */
  PPT = 'Ppt'
}

export const documentTypeMap: { [key in EnumDocType | EnumDocDependencyType]: string } = {
  [EnumDocType.UG]: 'UG数模',
  [EnumDocType.SW]: 'SW数模',
  [EnumDocType.CAD]: 'AutoCAD',
  [EnumDocType.OFFICE]: 'Office',
  [EnumDocType.WPS]: 'WPS',
  [EnumDocType.WORD]: 'Word',
  [EnumDocType.EXCEL]: 'Excel',
  [EnumDocType.STRUCTURE]: '结构化文档',
  [EnumDocType.BREAKDOWN_ELEMENTS]: '结构化文档分解要素',
  [EnumDocType.CADENCE]: 'CADENCE原理图',
  [EnumDocType.CADENCE_PCB]: 'CADENCE_PCB图',
  [EnumDocType.OTHER]: '其他',
  [EnumDocDependencyType.SIGNATURE]: '签字文档',
  [EnumDocDependencyType.PRINT]: '打印文档',
  [EnumDocDependencyType.BROWSE]: '浏览文档',
  [EnumDocDependencyType.SOLIDWORKS_2D]: 'SOLIDWORKS工程图',
  [EnumDocDependencyType.CADENCE_GERBER]: 'Gerber文件',
  [EnumDocDependencyType.CADENCE_COORDINATE]: '坐标文件',
  [EnumDocDependencyType.CADENCE_ODB_PLUS]: 'ODB++文件',
  [EnumDocDependencyType.CADENCE_NET_LIST]: '网表文件',
  [EnumDocDependencyType.SYMBOL_LIB]: '符号库文件',
  [EnumDocDependencyType.WRAPPER_LIB]: '封装库文件',
  [EnumDocDependencyType.PPT]: 'PPT'
}
