import {
  BaseViewPropOptions,
  BaseViewEventEmits,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KrequirementKanbanPropOptions = {
  ...BaseViewPropOptions
}

/** 参数类型 **/
export type KrequirementKanbanPropType = ViewPropsTypeExtract<typeof KrequirementKanbanPropOptions>

/** 事件 */
export const KrequirementKanbanEventEmits = {
  ...BaseViewEventEmits
}

/** 事件类型 **/
export type KrequirementKanbanEmitsType = ViewEmitsTypeExtract<typeof KrequirementKanbanEventEmits>
