import {
  BaseViewModel,
  EnumItemChangeCancelType,
  EnumToolStripCompType,
  KNotification,
  request,
  ToolStripItemChangeEventArgs,
  ToolStripItemClickedEventArgs,
  ViewModelOptions
} from '@kmsoft/upf-core'
import { KProductNeedsSectionEditPanelEmitsType, KProductNeedsSectionEditPanelPropType } from './interface'
import { ref, watch } from 'vue'
import {
  Api,
  EnumObjClassManageToolStripOptions,
  EnumRequestCode,
  EnumToolStripItemKeys,
  KObjectPropertyEditPanelViewModel,
  ObjBusinessParam
} from '@kmsoft/ebf-common'

/** KProductNeedsSectionEditPanel */
export default class KProductNeedsSectionEditPanelViewModel extends BaseViewModel<
  KProductNeedsSectionEditPanelEmitsType,
  KProductNeedsSectionEditPanelPropType
> {
  /** 属性面板工具栏 */
  toolStripOptions = ref<EnumObjClassManageToolStripOptions>(EnumObjClassManageToolStripOptions.ShowEdit)
  /** 属性 */
  refObjectProperty = ref<KObjectPropertyEditPanelViewModel>()
  /** 对象参数 */
  objParam = ref<ObjBusinessParam>()

  categorySelectorViewModel = ref<any>(null)

  originFormData = ref<any>(null)

  constructor(options: ViewModelOptions<KProductNeedsSectionEditPanelPropType>) {
    super(options)
    this.objParam.value = options.props.objParam
    watch(
      () => this.props.objParam,
      newValue => {
        if (newValue && newValue.id) {
          this.objParam.value = newValue
          this.loadData()
        }
      }
    )
  }

  viewDidMount() {}

  /** 加载数据 */
  async loadData() {
    const result = (await Api.post('requirement', 'ProductNeedsSection', 'get', { data: [this.props.objParam.id] })) as any

    if (result && result.code == EnumRequestCode.SUCCESS) {
      if (result.data.secondary) {
        const file = result.data.secondary[0]
        const fileId = file.id
        const params = {
          fileIds: fileId,
          modelName: result.data.rdmExtensionType,
          attributeName: 'secondary'
        }
        const downLoadResult = await request.post('/kmsaasFileApi/download', params, {
          headers: {
            'Content-Type': 'application/json'
          },
          responseType: 'blob'
        })
        if (downLoadResult?.status == EnumRequestCode.SUCCESS) {
          const data = downLoadResult.data
          const htmlText = await data.text()
          result.data.content = htmlText
        } else {
          KNotification.error({
            title: '解析失败',
            content: result.message || '需求项正文解析失败',
            details: result.detail
          })
        }
      }
      this.originFormData.value = result.data
      const lifecycleStateCode = this.originFormData.value.lifecycleStateCode
      if (lifecycleStateCode == 'Released') {
        //当前状态是已发布
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN, true)
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_END, false)
      } else if (lifecycleStateCode == 'Realizing') {
        //当前状态是实现中
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_END, true)
        this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN, false)
      }
      return result.data
    } else {
      KNotification.error({
        title: '获取失败',
        content: result.message || '获取产品需求项失败',
        details: result.detail
      })
      return
    }
  }

  toolItem = [
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN,
      title: '开始',
      icon: 'file-protect',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'ctrl+p'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_END,
      title: '完成',
      icon: 'file-protect',
      visible: false,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'ctrl+p'
    },
    {
      name: EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH,
      title: '刷新',
      icon: 'sync',
      visible: true,
      compType: EnumToolStripCompType.BUTTON,
      shortcutKey: 'f5'
    }
  ]

  async onToolStripItemClicked(event: ToolStripItemClickedEventArgs) {
    if (!this.refObjectProperty.value?.props.objParam.id) {
      KNotification.warn({
        message: '系统提示',
        description: '请选择一条数据'
      })
      return
    }
    console.log('onToolStripItemClicked', event.name)
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE:
        this.update()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_REFRESH:
        this.refObjectProperty.value?.refresh()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN:
        this.begin()
        break
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_END:
        this.end()
        break
      default:
        break
    }
  }

  async onToolStripItemChange(event: ToolStripItemChangeEventArgs) {
    switch (event.name) {
      case EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY:
        if (event.value) {
          this.refObjectProperty.value?.setReadOnly(false)
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, true)
        } else {
          this.doSave(false)
          event.itemCancelType = EnumItemChangeCancelType.CancelAll
          this.refObjectProperty.value?.setReadOnly(true)
          this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_SAVE, false)
        }
        break
      default:
        break
    }
  }

  /** 编辑保存 */
  async doSave(isSave: boolean = true) {
    if (await this.validate()) {
      const formData = this.refObjectProperty.value?.getValue()
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length == 0) {
        if (isSave)
          KNotification.warn({
            message: '系统提示',
            description: '没有修改数据'
          })
        return
      }

      const reqParam = { ...value, id: this.objParam.value?.id }

      if (reqParam?.content) {
        const fileName = formData?.title + '.txt'
        const htmlText = reqParam?.content
        const req = new FormData()
        req.append('modelName', 'ProductNeedsSection')
        req.append('attributeName', 'secondary')
        req.append('files', new File([htmlText], fileName))
        const result = (await request.post('/kmsaasFileApi/upload', req, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })) as any
        if (!result || result.result != 'SUCCESS') {
          KNotification.error({
            title: '系统错误',
            content: result.message
          })
          return
        }
        const fileId = result.data[0]
        reqParam.secondary = [{ id: fileId }]
        reqParam.content = this.extractTextFromHtml(htmlText)
      }

      console.log('doSave', reqParam)
      const result = await Api.post('requirement', 'ProductNeedsSection', 'update', { data: [reqParam] })
      if (result && result.code == EnumRequestCode.SUCCESS) {
        this.emit('retrieve', { command: 'save', data: this.objParam.value?.id })
        KNotification.success('操作成功！')
        return
      }
    }
  }

  async update() {
    if (await this.validate()) {
      const formData = this.refObjectProperty.value?.getValue()
      const value = this.refObjectProperty.value?.getModifiedValue()! as any
      if (Object.keys(value).length) {
        const objParam = this.refObjectProperty.value?.props.objParam
        const reqParam = { ...value, id: objParam?.id }

        if (reqParam?.content) {
          const fileName = formData?.title + '.txt'
          const htmlText = reqParam?.content
          const req = new FormData()
          req.append('modelName', 'ProductNeedsSection')
          req.append('attributeName', 'secondary')
          req.append('files', new File([htmlText], fileName))
          const result = (await request.post('/kmsaasFileApi/upload', req, {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          })) as any
          if (!result || result.result != 'SUCCESS') {
            KNotification.error({
              title: '系统错误',
              content: result.message
            })
            return
          }
          const fileId = result.data[0]
          reqParam.secondary = [{ id: fileId }]
          reqParam.content = this.extractTextFromHtml(htmlText)
        }

        const result = await Api.post('requirement', 'ProductNeedsSection', 'update', { data: [reqParam] })
        if (result && result.code == EnumRequestCode.SUCCESS) {
          this.refObjectProperty.value?.refresh()
          KNotification.success('操作成功！')
        } else {
          KNotification.error({
            title: '操作失败',
            content: result.message,
            details: result.detail
          })
        }
      } else {
        KNotification.warn({
          message: '系统提示',
          description: '没有修改数据'
        })
      }
    }
  }

  extractTextFromHtml (htmlText:string) {
    // 创建一个虚拟的 DOM 元素
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlText;
    return tempDiv.textContent || tempDiv.innerText || ''
  }

  /**
   * 需求开始
   */
  async begin() {
    const result = (await Api.post('requirement', 'Requirement', 'begin', {
      data: [{ id: this.props.objParam.id, clazz: this.props.objParam.modelCode }]
    })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.refObjectProperty.value?.refresh()
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN, false)
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_END, true)
      KNotification.success('操作成功！')
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '',
        details: result.detail
      })
      return
    }
  }

  /**
   * 需求完成
   */
  async end() {
    const result = (await Api.post('requirement', 'Requirement', 'end', {
      data: [{ id: this.props.objParam.id, clazz: this.props.objParam.modelCode }]
    })) as any
    if (result && result.code == EnumRequestCode.SUCCESS) {
      this.refObjectProperty.value?.refresh()
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_END, false)
      KNotification.success('操作成功！')
    } else {
      KNotification.error({
        title: '操作失败',
        content: result.message || '产品需求项已完成',
        details: result.detail
      })
      return
    }
  }

  /** 校验 */
  async validate() {
    try {
      return this.refObjectProperty.value?.validate()
    } catch (error) {
      console.log('验证错误', error)
      return false
    }
  }

  /**面板加载完成 */
  loaded() {
    const lifecycleStateCode = this.originFormData.value.lifecycleStateCode
    if (lifecycleStateCode == 'Released') {
      //当前状态是已发布
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_BEGIN, true)
    } else if (lifecycleStateCode == 'Realizing') {
      //当前状态是实现中
      this.refObjectProperty.value?.setItemVisible(EnumToolStripItemKeys.TOOL_STRIP_ITEM_END, true)
    }

    if (['UnderReview', 'Released', 'Realizing', 'Closed'].includes(lifecycleStateCode)) {
      this.refObjectProperty.value?.setItemDisabled(EnumToolStripItemKeys.TOOL_STRIP_ITEM_EDIT_PROPERTY, true)
    }
  }
}
