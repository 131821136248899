import {
  SimpleViewEventEmits,
  SimpleViewPropOptions,
  ViewEmitsTypeExtract,
  ViewPropsTypeExtract,
  VuePropTypes
} from '@kmsoft/upf-core'

/** 参数 **/
export const KFolderSelectorPropOptions = {
  ...SimpleViewPropOptions,
  /**
   * 显示的工作区类型
   */
  value: VuePropTypes.string().def(''),
  showWorkspaceType: VuePropTypes.createType<Array<FolderTypeEnum>>().def([]),
  allData: VuePropTypes.bool().def(false),
  /** 是否是设计模式 */
  isDesigner: VuePropTypes.bool().def(false)
}

/** 参数类型 **/
export type KFolderSelectorPropType = ViewPropsTypeExtract<typeof KFolderSelectorPropOptions>

/** 事件 */
export const KFolderSelectorEventEmits = {
  ...SimpleViewEventEmits
}

/** 事件类型 **/
export type KFolderSelectorEmitsType = ViewEmitsTypeExtract<typeof KFolderSelectorEventEmits>

/** 文件夹类型枚举 */
export enum FolderTypeEnum {
  /**个人工作区 */
  PERSONAL = 'PersonalWorkspace',
  /**公共工作区 */
  PUBLIC = 'PublicWorkspace',
  /**产品工作区 */
  PRODUCT = 'ProductLibrary',
  /**CAD工作区 */
  CAD = 'CADWorkspace',
  /** 项目工作区 */
  PROJECT = 'ProjectWorkspace',
  /**标准件 */
  STANDARD_PARTS = 'StandardPartLibrary',
  /**元器件 */
  ELECTRONIC_COMPONENTS = 'ElectronicComponentsLibrary',
  /** 通用件” */
  COMMON_PARTS = 'CommonPartLibrary',
  /** 立项文件*/
  PROJECT_INITIATION = 'ProjectInitiation',
  /** 参考资料*/
  PROJECT_REFERENCE = 'ProjectReference',
  /** 交付物*/
  PROJECT_DELIVERABLE = 'ProjectDeliverable',
  /** 通用指标库 */
  COMMON_VALIDATION_METRICS_LIBRARY = 'CommonMetricsLibrary'
}

export const FolderTypeObjTypeMap = new Map([
  //[FolderTypeEnum.COMMON_PARTS, 'Part'],
  [FolderTypeEnum.STANDARD_PARTS, 'Standardparts'],
  [FolderTypeEnum.ELECTRONIC_COMPONENTS, 'Electroniccomponents'],
  [FolderTypeEnum.COMMON_VALIDATION_METRICS_LIBRARY, 'ValidationMetrics']
])

/**
 * 枚举转换
 * @param input 文件夹类型
 * @returns 文件夹类型枚举
 */
export function convertToEnum(input: string): FolderTypeEnum {
  const directionMap: { [key: string]: FolderTypeEnum } = {
    PersonalWorkspace: FolderTypeEnum.PERSONAL,
    PublicWorkspace: FolderTypeEnum.PUBLIC,
    ProductLibrary: FolderTypeEnum.PRODUCT,
    StandardPartLibrary: FolderTypeEnum.STANDARD_PARTS,
    ElectronicComponentsLibrary: FolderTypeEnum.ELECTRONIC_COMPONENTS,
    CommonPartLibrary: FolderTypeEnum.COMMON_PARTS
  }

  return directionMap[input]
}
